import { useEffect, useMemo } from "react";
import { TicketMapController, zIndexes } from "./map_controller";
import { toLatLng } from "../../../util/coordinates";
import { ISOTimestamp } from "../../statistics/StatisticsProcessingPage";
import { TicketRecordedPosition } from "../../../generated/gql/graphql";
import { Ticket } from "../../ticket_v2/context";


export function usePhonePositionPolylineLayer(controller: TicketMapController) {
    const layer = useMemo(() => new PhonePolylineLayer(), []);

    const { map, ticket, positionLog } = controller;

    useEffect(() => {
        layer.update(map, ticket, positionLog);
    }, [map, ticket, positionLog]);


}

class PhonePolylineLayer {
    private readonly polylinePrePurchase = new google.maps.Polyline({
        strokeColor: "#AAA",
        zIndex: zIndexes.phonePositionPolyline - 1,
        strokeWeight: 3
    });
    private readonly polyline = new google.maps.Polyline({
        zIndex: zIndexes.phonePositionPolyline,
        strokeWeight: 3
    });
    private readonly polylineAfterEnd = new google.maps.Polyline({
        zIndex: zIndexes.phonePositionPolyline - 1,
        strokeColor: "#AAA",
        strokeWeight: 3
    });

    update(map: google.maps.Map | null, ticket: Ticket, positionLog: ReadonlyArray<TicketRecordedPosition>) {
        const [before, during, after] = splitPositionLog(positionLog, ticket.createdTime, ticket.endedTime);

        this.polylinePrePurchase.setPath(before.map(toLatLng));
        this.polyline.setPath(during.map(toLatLng));
        this.polylineAfterEnd.setPath(after.map(toLatLng));

        this.polylinePrePurchase.setMap(map);
        this.polyline.setMap(map);
        this.polylineAfterEnd.setMap(map);
    }
}

export function splitPositionLog<T extends {
    recordedTime: ISOTimestamp
}>(positionLog: ReadonlyArray<T>, start: string, end: string | null | undefined): [ReadonlyArray<T>, ReadonlyArray<T>, ReadonlyArray<T>] {
    let fromIndex = positionLog.findIndex((position) => position.recordedTime >= start);
    let toIndex = end ? positionLog.findIndex((position) => position.recordedTime >= end) : positionLog.length;
    if (fromIndex === -1)
        fromIndex = 0;
    if (toIndex === -1)
        toIndex = positionLog.length;

    const before = fromIndex > 0 ? positionLog.slice(0, fromIndex + 1) : [];
    const during = positionLog.slice(fromIndex, toIndex);
    const after = toIndex < positionLog.length ? positionLog.slice(toIndex - 1) : [];

    return [before, during, after];
}