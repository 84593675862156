import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import { isSuperUser } from "../util/active_user_context";
import CodeIcon from "@mui/icons-material/Code";
import ReactJson from "react-json-view";

function DiagnosticModal(props: { onClose: () => void; children: React.ReactNode }) {
    return (
        <Dialog open onClose={props.onClose} fullWidth={false} maxWidth={"xl"}>
            <DialogTitle>Diagnostics</DialogTitle>
            <DialogContent style={{ minWidth: "900px" }}>{props.children}</DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export function DiagnosticButton(props: { children: React.ReactNode }) {
    const [modal, setModal] = useState<React.ReactNode>();
    if (!isSuperUser()) return;

    return (
        <>
            <IconButton
                size={"small"}
                onClick={() => setModal(<DiagnosticModal onClose={() => setModal(null)} {...props} />)}
            >
                <CodeIcon />
            </IconButton>
            {modal}
        </>
    );
}

export function JsonDiagnosticButton(props: JsonDiagnosticPropertyProps) {
    return (
        <DiagnosticButton>
            <JsonDiagnosticProperty {...props} />
        </DiagnosticButton>
    );
}

type JsonDiagnosticPropertyProps = {
    name: string;
    value?: {};
    json?: string | null | undefined;
};

export function JsonDiagnosticProperty(props: JsonDiagnosticPropertyProps) {
    const src = props.json ? JSON.parse(props.json) : props.value;
    return (
        <Accordion defaultExpanded={true}>
            <AccordionSummary>{props.name}</AccordionSummary>
            <AccordionDetails>
                {src ? (
                    <div style={{ fontSize: "8px" }}>
                        <ReactJson
                            src={src}
                            collapsed={false}
                            theme={"apathy"}
                            collapseStringsAfterLength={150}
                            enableClipboard={(copy) => {
                                navigator.clipboard.writeText(JSON.stringify(copy.src, null, "\t"));
                            }}
                        />
                    </div>
                ) : (
                    "null"
                )}
            </AccordionDetails>
        </Accordion>
    );
    /*
    return (
        <ListItem>
            <ListItemText primary={props.name} secondary={<ReactJson src={props.value} />} />
        </ListItem>
    );
    
     */
}
