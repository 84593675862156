import { DateTime, Settings } from "luxon";
import { Valid } from "luxon/src/_util";

function configureLuxon() {
    Settings.defaultLocale = "nb";
    // Settings.defaultZone = "UTC";
}

export enum DateTimeFormat {
    DATE_MED = "dd.MM.yyyy",
    DATE_FULL = "cccc dd.MM.yyyy",
    DATETIME_MED = "dd.MM.yyyy HH:mm",
    DATETIME_LONG = "dd.MM.yyyy HH:mm:ss",
    DATETIME_FULL = "cccc dd.MM.yyyy HH:mm",
    TIME = "HH:mm:ss",
    TIME_SHORT = "HH:mm",
}

type IsoInstantString = number | string;
type OptionalIsoInstantString = IsoInstantString | null | undefined;

export function formatDateTimeMed(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    configureLuxon();
    return parseTime(timestamp).toFormat(DateTimeFormat.DATETIME_MED);
}

export function formatDateTimeFull(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    configureLuxon();
    return parseTime(timestamp).toFormat(DateTimeFormat.DATETIME_FULL);
}

export function formatDateTimeLong(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    configureLuxon();
    return parseTime(timestamp).toFormat(DateTimeFormat.DATETIME_LONG);
}

export function formatDateMed(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    configureLuxon();
    return parseTime(timestamp).toFormat(DateTimeFormat.DATE_MED);
}

export function formatDateMedTimeFull(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    return formatDateMed(timestamp) + " " + formatTime(timestamp);
}

export function formatAge(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    configureLuxon();

    const time = parseTime(timestamp);
    const hoursAgo = Math.abs(time.diffNow("hours").hours);
    return time.toRelativeCalendar({
        unit: hoursAgo > 72 ? "days" : "hours",
    });
}

export function formatDateFull(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    configureLuxon();
    return parseTime(timestamp).toFormat(DateTimeFormat.DATE_FULL);
}

export function parseTime(timestamp: DateTime | IsoInstantString): DateTime<Valid> {
    if (typeof timestamp === "string") return DateTime.fromISO(timestamp) as DateTime<Valid>;
    if (timestamp instanceof DateTime) return timestamp;

    return DateTime.fromSeconds(timestamp);
}

export function parseTimeOrNull(timestamp: OptionalIsoInstantString): DateTime | null {
    if (timestamp === undefined || timestamp === null) return null;
    return parseTime(timestamp);
}

export function formatTime(timestamp: OptionalIsoInstantString) {
    if (!timestamp) return null;

    configureLuxon();
    return parseTime(timestamp).toFormat(DateTimeFormat.TIME);
}

export function formatDuration(from: OptionalIsoInstantString, to: OptionalIsoInstantString): string | null {
    if (!to || !from) return "";
    return Math.floor(parseTime(to)!.diff(parseTime(from)).as("minutes")) + " min";
}

export function formatTimeRange(dateStart: number | string, dateEnd: number | string) {
    return formatTime(dateStart) + " – " + formatTime(dateEnd);
}
