import {
    CorrectLegsMutation,
    CorrectLegsMutationVariables,
    ProcessTicketMutation,
    ProcessTicketMutationVariables,
    TicketPageHeaderFragment,
    TicketTelemetryAvailability,
} from "../../generated/gql/graphql";
import React from "react";
import { Link } from "react-router-dom";
import {
    Alert,
    alpha,
    Button,
    Divider,
    Grid2,
    Link as MaterialLink,
    Menu,
    MenuItem,
    MenuProps,
    styled,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { TimestampText } from "../../widgets/date_time";
import { formatMoney } from "../NetsTransactionsListPage";
import { DateTimeFormat } from "../../util/date_time";
import { formatCustomerId, formatTicketId } from "../../util/formatting";
import { isLearningEnvironment, isSuperUser } from "../../util/active_user_context";
import gql from "graphql-tag";
import { SendReceiptEmailButton } from "./SendReceiptEmailButton";
import { JsonDiagnosticButton } from "../../widgets/diagnostics";
import { Duration } from "luxon";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import { blue } from "@mui/material/colors";
import { useTicketPageV2Context } from "./context";
import { useMutation } from "@apollo/react-hooks";
import MemoryIcon from "@mui/icons-material/Memory";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataObject } from "@mui/icons-material";

type TicketPageHeaderProps = {
    ticket: TicketPageHeaderFragment;
    hideProperties?: boolean;
};

export const TICKET_PAGE_HEADER_FRAGMENT = gql`
    fragment TicketPageHeader on Ticket {
        id
        createdTime
        endedTime
        endedReason

        discount
        price
        basePrice
        processedTime
        invoicedTime

        telemetryDataUploadConsentGiven
        telemetryDataUploadRequired

        automaticPurchase
        purchaseRequestPhoneTime
        statusPaymentText

        passengerTypeInfo {
            textDescription
            passengerTypeAddons
        }

        customer {
            id
            displayName
            phoneNumber
            phoneNumberFormatted
            deletionRequestedTime
        }

        clockDeviationEstimate {
            timeOffsetMillis
            minDeviationMillis
            maxDeviationMillis
        }
    }
`;

function PhoneTimeSynchronizationWarning({ ticket }: TicketPageHeaderProps) {
    const timeOffsetMillis = ticket.clockDeviationEstimate.timeOffsetMillis || 0;
    const diff = Duration.fromMillis(timeOffsetMillis);

    if (Math.abs(diff.as("seconds")) < 10) {
        return;
    }

    return (
        <Alert severity="error" style={{ marginBottom: "20px" }}>
            <Typography variant={"subtitle1"}>
                <b>Advarsel:</b> Mobilen sin innebygde klokke var stilt {formatDeviation(timeOffsetMillis)}.
            </Typography>
            <Typography variant={"body2"}>Dette medfører høyere sannsynlighet for analysefeil.</Typography>
            {isSuperUser() ? (
                <Typography variant={"body2"}>
                    Estimert avvik (minimum): {formatDeviation(ticket.clockDeviationEstimate.minDeviationMillis)}
                    <br />
                    Estimert avvik (maximum): {formatDeviation(ticket.clockDeviationEstimate.maxDeviationMillis)}
                </Typography>
            ) : null}
        </Alert>
    );
}

function formatDeviation(millis: number | null | undefined) {
    if (millis === null || millis === undefined) return "?";
    let string = Math.abs(millis / 1000).toFixed(2);

    if (millis > 0) {
        string += " sekunder for langt fram";
    } else {
        string += " sekunder for langt bak";
    }

    return string;
}

export function TicketPageHeader({ ticket, hideProperties }: TicketPageHeaderProps) {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                <h2>Billett: {formatTicketId(ticket.id)}</h2>
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                    <SendReceiptEmailButton ticketId={ticket.id} />
                    <ProcessTicketButton />
                    <TicketVerifiedButtons />
                    <DownloadReplayButton />
                    <JsonDiagnosticButton name={"ticket"} value={ticket} />
                </div>
            </div>

            {ticket.customer.deletionRequestedTime ? (
                <Alert severity="error" style={{ marginBottom: "20px" }}>
                    Kunden deaktiverte kontoen sin <TimestampText value={ticket.customer.deletionRequestedTime} />, og
                    denne siden vil derfor ha begrensede opplysninger om brukerens reise.
                </Alert>
            ) : null}

            <PhoneTimeSynchronizationWarning ticket={ticket} />

            {!hideProperties ? (
                <>
                    {ticket.automaticPurchase ? (
                        <Alert color={"error"} style={{ marginBottom: "20px" }}>
                            Billetten ble <b>automatisk startet</b> da brukeren trykket på "Start reise"-knappen.
                        </Alert>
                    ) : null}

                    <Grid2 container spacing={2} direction={"row"}>
                        <Grid2 size={{ xs: 6 }}>
                            <PropertyList>
                                <Property title={"Billettype"} value={ticket.passengerTypeInfo.textDescription} />

                                <Property
                                    hideInLearningEnvironment
                                    title={"Antall reisende"}
                                    value={ticket.passengerTypeInfo.passengerTypeAddons.length + 1}
                                />

                                <Property
                                    title={"App-ID"}
                                    value={
                                        <Link to={"/customer/" + ticket.customer.id}>
                                            {formatCustomerId(ticket.customer.id)}
                                        </Link>
                                    }
                                />

                                <Property
                                    title={"Mobilnummer"}
                                    value={
                                        ticket.customer.phoneNumber ? (
                                            <MaterialLink href={"tel:" + ticket.customer.phoneNumber}>
                                                {ticket.customer.phoneNumberFormatted}
                                            </MaterialLink>
                                        ) : (
                                            "-"
                                        )
                                    }
                                />

                                <Property
                                    hideInLearningEnvironment
                                    title={"Telemetri-data"}
                                    value={
                                        ticket.telemetryDataUploadConsentGiven
                                            ? "Bruker har samtykket til opplasting av telemetri-data"
                                            : "Mangler samtykke til opplasting av telemetri-data"
                                    }
                                />
                                {ticket.telemetryDataUploadRequired ? (
                                    <Typography>
                                        Opplasting av telemetri-data er påkrevd for denne billetten!.
                                    </Typography>
                                ) : null}
                            </PropertyList>
                        </Grid2>
                        <Grid2 size={{ xs: 6 }}>
                            <PropertyList>
                                <Property
                                    title={"Gyldig fra / Bestilt dato"}
                                    value={
                                        <TimestampText
                                            value={ticket.createdTime}
                                            format={DateTimeFormat.DATETIME_LONG}
                                        />
                                    }
                                />
                                <Property
                                    title={"Gyldig til"}
                                    value={
                                        <TimestampText value={ticket.endedTime} format={DateTimeFormat.DATETIME_LONG} />
                                    }
                                />
                                <Property title={"Avsluttingsgrunn"} value={ticket.endedReason} />

                                <Property hideInLearningEnvironment title={"Pris"} value={formatMoney(ticket.price)} />
                                <Property
                                    hideInLearningEnvironment
                                    title={"Urabattert pris"}
                                    value={formatMoney(ticket.basePrice)}
                                />
                                <Property hideInLearningEnvironment title={"Rabatt"} value={ticket.discount + "%"} />
                                <Property
                                    hideInLearningEnvironment
                                    title={"Betaling"}
                                    value={ticket.statusPaymentText}
                                />
                            </PropertyList>
                        </Grid2>
                    </Grid2>
                </>
            ) : null}
        </div>
    );
}

export function PropertyList(props: { children: React.ReactNode }) {
    return <div>{props.children}</div>;
}

export function Property(props: {
    vertical?: boolean;
    requiresSuperUser?: boolean;
    hideInLearningEnvironment?: boolean;
    title: React.ReactNode;
    value: React.ReactNode;
    titleWidth?: string;
}) {
    if (props.requiresSuperUser && !isSuperUser()) {
        return null;
    }
    if (props.hideInLearningEnvironment && isLearningEnvironment()) {
        return null;
    }

    return (
        <div style={{ display: "flex", marginBottom: "8px", flexDirection: props.vertical ? "column" : undefined }}>
            <div style={{ minWidth: props.titleWidth || "155px", paddingRight: "10px" }}>
                {props.title}
                {props.requiresSuperUser ? <DeveloperModeIcon fontSize={"small"} /> : null}
            </div>
            <div>{props.value}</div>
        </div>
    );
}

const CORRECT_LEGS_MUTATION = gql`
    mutation CorrectLegs($ticketId: Int!, $verified: Boolean!) {
        updateTicket(ticketId: $ticketId, verified: $verified) {
            id
        }
    }
`;

const PROCESS_TICKET_MUTATION = gql`
    mutation ProcessTicket($ticketId: Int!) {
        processTicket(ticketId: $ticketId)
    }
`;

function TicketVerifiedButtons() {
    const context = useTicketPageV2Context();
    const mutation = useMutation<CorrectLegsMutation, CorrectLegsMutationVariables>(CORRECT_LEGS_MUTATION);

    if (!isLearningEnvironment()) return;

    const verified = context.ticket.verified;

    function setVerified(verified: boolean) {
        mutation[0]({
            variables: { ticketId: context.ticket.id, verified: verified },
            refetchQueries: "all",
        });
    }

    return (
        <LoadingButton
            startIcon={verified ? <CheckIcon /> : null}
            loadingPosition={"start"}
            loading={mutation[1].loading}
            onClick={() => setVerified(!verified)}
            // loadingIndicator={<CircularProgress size={20} color={"warning"} />}
            color={"success"}
            variant={verified ? "contained" : "outlined"}
        >
            {verified ? "Verifisert" : "Merker verifisert"}
        </LoadingButton>
    );
}

function ProcessTicketButton() {
    const context = useTicketPageV2Context();
    const mutation = useMutation<ProcessTicketMutation, ProcessTicketMutationVariables>(PROCESS_TICKET_MUTATION, {
        variables: {
            ticketId: context.ticket.id,
        },
        refetchQueries: "all",
    });

    if (!isLearningEnvironment()) return;

    return (
        <LoadingButton
            variant={"contained"}
            color={"primary"}
            loadingPosition={"start"}
            loading={mutation[1].loading}
            startIcon={<MemoryIcon />}
            onClick={() => {
                mutation[0]();
            }}
            style={{ background: blue[500], color: "white" }}
            // loadingIndicator={<CircularProgress size={20} color={"warning"} />}
        >
            Analyser reise
        </LoadingButton>
    );
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: "rgb(55, 65, 81)",
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
        ...theme.applyStyles("dark", {
            color: theme.palette.grey[300],
        }),
    },
}));

function DownloadReplayButton() {
    const ticket = useTicketPageV2Context().ticket;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    if (!isSuperUser()) return;
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function buildItem(type: string, redacted: boolean) {
        return (
            <MenuItem
                href={"/api/admin/ticket/replay." + type + "?ticketId=" + ticket.id + "&redacted=" + redacted}
                target={"_blank"}
                onClick={handleClose}
                disableRipple
            >
                {"replay." + type + (redacted ? " (redacted)" : "")}
            </MenuItem>
        );
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disabled={ticket.telemetryAvailability === TicketTelemetryAvailability.Missing}
                disableElevation
                onClick={handleClick}
                startIcon={<DataObject />}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Eksporter
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {buildItem("json", false)}
                {buildItem("pb", false)}
                <Divider />
                {buildItem("json", true)}
                {buildItem("pb", true)}
            </StyledMenu>
        </div>
    );
}
