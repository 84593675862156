import { TicketRecordedPosition } from "../generated/gql/graphql";
import LatLngBounds = google.maps.LatLngBounds;

export type LatLng = [number, number] | LatLngObject | google.maps.LatLng | google.maps.LatLngLiteral;
export type LatLngObject = { readonly latitude: number; readonly longitude: number };

function latitude(pos: LatLng): number {
    if (Array.isArray(pos)) return pos[0];
    else if (pos instanceof google.maps.LatLng) return pos.lat();
    else if ("lat" in pos) return pos.lat;
    else return pos.latitude;
}

function longitude(pos: LatLng): number {
    if (Array.isArray(pos)) return pos[1];
    else if (pos instanceof google.maps.LatLng) return pos.lng();
    else if ("lng" in pos) return pos.lng;
    else return pos.longitude;
}

export function toLatLngObject(obj: LatLng): { latitude: number; longitude: number } {
    if ("latitude" in obj) return obj;

    return {
        latitude: latitude(obj),
        longitude: longitude(obj),
    };
}

export function calculateDistance(from: LatLng, to: LatLng): number {
    const fromLat = latitude(from);
    const fromLng = longitude(from);
    const toLat = latitude(to);
    const toLng = longitude(to);

    const p = 0.017453292519943295;
    const c = Math.cos;
    const d = 0.5 - c((toLat - fromLat) * p) / 2 + (c(fromLat * p) * c(toLat * p) * (1 - c((toLng - fromLng) * p))) / 2;
    return 12742 * Math.asin(Math.sqrt(d)) * 1000;
}

export function toLatLng(position: LatLng): google.maps.LatLngLiteral {
    return {
        lat: latitude(position),
        lng: longitude(position),
    };
}

export function createBounds(positionLog: ReadonlyArray<TicketRecordedPosition>): LatLngBounds | null {
    if (positionLog.length === 0) return null;
    let bounds: LatLngBounds = new LatLngBounds(toLatLng(positionLog[0]));

    positionLog.forEach((position) => {
        const pos = toLatLng(position);
        if (!bounds.contains(pos)) {
            bounds = bounds.extend(pos);
        }
    });

    return bounds;
}
