import { DateTime } from "luxon";

export type TimeGap = [DateTime, DateTime];

export function getTimeGaps(
    startTime: DateTime<true>,
    endTime: DateTime | null,
    timestamps: ReadonlyArray<DateTime<true>>,
    minDurationMillis: number = 20000,
): ReadonlyArray<TimeGap> {
    let gaps: TimeGap[] = [];

    let previousMillis = startTime.toMillis();
    let timestampMillis = timestamps.map((t) => t.toMillis()).filter((t) => t > previousMillis);

    if (endTime !== null) {
        let endedTimeMillis = endTime.toMillis();
        timestampMillis = timestampMillis.filter((t) => t <= endedTimeMillis);
        timestampMillis.push(endedTimeMillis);
    }

    for (let timestamp of timestamps) {
        let millis = timestamp.toMillis();
        let diff = millis - previousMillis;

        if (diff > minDurationMillis) {
            gaps.push([DateTime.fromMillis(previousMillis), timestamp]);
        }

        previousMillis = millis;
    }

    return gaps;
}
