import { TicketLegWithDescription } from "../../ticket_v2/context";
import React, { useEffect, useMemo } from "react";
import { getLegColor, TicketMapController, zIndexes } from "./map_controller";
import { createRoot, Root } from "react-dom/client";
import { StopTimeLiteFragment } from "../../../generated/gql/graphql";
import { IconButton, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { toLatLng } from "../../../util/coordinates";


export function useLegsMapLayer(controller: TicketMapController) {
    const layer = useMemo<LegsMapLayer | null>(() => {
        if (!controller.map) return null;
        return new LegsMapLayer(controller.map);
    }, [controller.map]);

    const legs = controller.legs;
    useEffect(() => {
        if (layer) {
            layer.legs = legs;
        }
    }, [layer, legs]);
}

class LegsMapLayer {

    map: google.maps.Map;
    _layers: ReadonlyArray<LegMapLayer> = [];

    constructor(map: google.maps.Map) {
        this.map = map;
    }

    _legs: ReadonlyArray<TicketLegWithDescription> = [];

    set legs(legs: ReadonlyArray<TicketLegWithDescription>) {
        if (isEqual(legs, this._legs)) return;
        this._legs = legs;
        this._layers.forEach((l) => l.dispose());
        this._layers = legs.map((leg, index) => new LegMapLayer(this.map, index, leg));
    }
}

class LegMapLayer {
    map: google.maps.Map;
    legIndex: number;
    leg: TicketLegWithDescription;
    private _markers: google.maps.marker.AdvancedMarkerElement[] = [];
    private renderRoots: Root[] = [];
    private _beforePolyline?: google.maps.Polyline;
    private _polyline?: google.maps.Polyline;
    private _afterPolyline?: google.maps.Polyline;

    constructor(map: google.maps.Map, legIndex: number, leg: TicketLegWithDescription) {
        this.map = map;
        this.legIndex = legIndex;
        this.leg = leg;

        this.addPolyline();
    }

    dispose() {
        this._markers.forEach((m) => m.map = null);
        this._markers = [];
        this.renderRoots.forEach((r) => r.unmount());
        this.renderRoots = [];
        this._beforePolyline?.setMap(null);
        this._polyline?.setMap(null);
        this._afterPolyline?.setMap(null);
    }

    private addPolyline() {
        const desc = this.leg.description;
        const polyline = desc.journey.polyline;
        if (!polyline) return;

        const strokeWeight = 10;
        const strokeColor = getLegColor(this.legIndex)[200];


        const fromIndex = polyline.stopTimeIndices[desc.fromStopTime.stopTimeIndex];
        const toIndex = polyline.stopTimeIndices[desc.toStopTime.stopTimeIndex];

        this._beforePolyline = new google.maps.Polyline({
            map: this.map,
            zIndex: zIndexes.legStopTime,
            path: polyline.coordinates.slice(0, fromIndex + 1).map(toLatLng),
            strokeColor,
            strokeWeight,
            strokeOpacity: 0.2
        });
        this._polyline = new google.maps.Polyline({
            map: this.map,
            zIndex: zIndexes.legStopTimeEmbarked,
            path: polyline.coordinates.slice(fromIndex, toIndex + 1).map(toLatLng),
            strokeColor,
            strokeWeight,
            strokeOpacity: 1.0
        });
        this._afterPolyline = new google.maps.Polyline({
            map: this.map,
            zIndex: zIndexes.legStopTime,
            path: polyline.coordinates.slice(toIndex).map(toLatLng),
            strokeColor,
            strokeWeight,
            strokeOpacity: 0.2
        });

        for (let stopTime of desc.journey.stopTimes) {
            const markerElement = document.createElement("div");
            const markerRoot = createRoot(markerElement);
            this.renderRoots.push(markerRoot);
            markerRoot.render(<LegStopTimeMarker
                stopTime={stopTime}
                legIndex={this.legIndex}
                leg={this.leg}
            />);

            const embarked = stopTime.stopTimeIndex >= desc.fromStopTime.stopTimeIndex && stopTime.stopTimeIndex <= desc.toStopTime.stopTimeIndex;

            this._markers.push(new google.maps.marker.AdvancedMarkerElement({
                position: toLatLng(stopTime),
                map: this.map,
                zIndex: embarked ? zIndexes.legStopTimeEmbarked : zIndexes.legStopTime,
                title: stopTime.platform.name,
                content: markerElement
            }));
        }
    }
}

function LegStopTimeMarker({ stopTime, legIndex, leg }: {
    stopTime: StopTimeLiteFragment,
    legIndex: number,
    leg: TicketLegWithDescription
}) {
    const desc = leg.description;
    const embarked = stopTime.stopTimeIndex >= desc.fromStopTime.stopTimeIndex && stopTime.stopTimeIndex <= desc.toStopTime.stopTimeIndex;

    const color = getLegColor(legIndex);

    return <div style={{ width: "30px", position: "relative", opacity: embarked ? undefined : 0.5 }}>
        <IconButton size="small" style={{
            background: embarked ? color["500"] : color[200],
            fontSize: "16px",
            color: "black",
            width: "30px",
            height: "30px"
        }} disabled>
            {stopTime.stopTimeIndex + "."}
        </IconButton>
        <div style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 40,
            width: "200px",
            pointerEvents: "none",
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center"
        }}>
            <Typography variant="caption">
                {stopTime.platform.name}
            </Typography>
        </div>
    </div>;
}