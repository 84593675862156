import { Dialog, DialogContent, DialogTitle, Link } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
    NewLegDepartureCandidatesQuery,
    NewLegDepartureCandidatesQueryVariables,
    VehicleTransportMode
} from "../../generated/gql/graphql";
import { gql } from "@apollo/client";
import LoadingModal from "../../widgets/LoadingModal";
import { formatTime, parseTime } from "../../util/date_time";
import { DateTime } from "luxon";
import { sortBy } from "lodash";
import { calculateDistance } from "../../util/coordinates";
import { LegEditorModal } from "./LegEditorModal";
import { useTicketPageV2Context } from "../ticket_v2/context";
import { lightGreen } from "@mui/material/colors";


const NEW_LEG_DEPARTURE_CANDIDATES_QUERY = gql`
    query NewLegDepartureCandidates(
        $ticketId: Int!
        $timestamp: String!,
        $latitude: Float!,
        $longitude: Float!,
    ){
        ticketDepartureCandidates(
            ticketId: $ticketId,
            timestamp: $timestamp,
            latitude: $latitude,
            longitude: $longitude
        ) {
            stopTime {
                stopTimeIndex
                scheduledDepartureTime
                latitude
                longitude
                platform {
                    id
                    name
                    publicCode
                }
                journey {
                    serviceJourneyId
                    tripId
                    operatingDate

                    destination
                    transportMode
                    transportSubMode
                    vehicleTransportMode

                    line {
                        publicCode
                    }
                }
            }
            vehicleIds
        }
    }
`;


export function NewLegCatalogModal(props: {
    timestamp: string,
    position: { latitude: number, longitude: number },
    vehicleId?: number,
    transportMode?: VehicleTransportMode,
    onClose: () => void,
    onSave: () => Promise<void>,
}) {
    const context = useTicketPageV2Context();
    const [modal, setModal] = useState<React.ReactNode>(null);
    const query = useQuery<NewLegDepartureCandidatesQuery, NewLegDepartureCandidatesQueryVariables>(NEW_LEG_DEPARTURE_CANDIDATES_QUERY, {
        variables: {
            ticketId: context.ticket.id,
            timestamp: props.timestamp,
            latitude: props.position.latitude,
            longitude: props.position.longitude
        }
    });
    if (query.loading)
        return <LoadingModal />;

    const candidates = sortBy(
        query.data!.ticketDepartureCandidates,
        (candidate) => {
            const stopTime = candidate.stopTime;
            const journey = stopTime.journey;
            let transportModeSortVar: string = journey.transportMode;

            if (journey.vehicleTransportMode === props.transportMode)
                transportModeSortVar = "A";
            else if (journey.vehicleTransportMode === VehicleTransportMode.Rail)
                transportModeSortVar = "B";
            else if (journey.vehicleTransportMode === VehicleTransportMode.Water)
                transportModeSortVar = "C";
            else if (journey.vehicleTransportMode === VehicleTransportMode.Bus)
                transportModeSortVar = "D";


            const distanceToPlatform = Math.round(calculateDistance(stopTime, props.position) / 100);
            return transportModeSortVar + distanceToPlatform + stopTime.platform.name + stopTime.scheduledDepartureTime;
        }
    );

    return <Dialog open={true} onClose={props.onClose} maxWidth={"lg"}>
        <DialogTitle>{"Select departure"}</DialogTitle>
        <DialogContent>
            <table>
                <thead>
                <tr>
                    <th></th>
                    <th>TripId</th>
                    <th>Transport mode</th>
                    <th>Platform</th>
                    <th>Scheduled departure time</th>
                    <th>Departure delay</th>
                    <th>Line</th>
                    <th>Destination</th>
                    <th>VehicleIds</th>
                </tr>
                </thead>
                <tbody>
                {candidates.map((candidate) => {
                    const stopTime = candidate.stopTime;
                    const deviation = getTimeDeviationMinutes(props.timestamp, stopTime.scheduledDepartureTime);
                    const outsideTimeRange = deviation < -5 || deviation > 10;

                    return <tr style={{
                        color: outsideTimeRange ? "#999" : "#000",
                        background: props.vehicleId && candidate.vehicleIds.indexOf(props.vehicleId) !== -1 ? lightGreen[200] : undefined
                    }}>
                        <td>
                            <Link
                                onClick={() => setModal(<LegEditorModal
                                    leg={{
                                        journey: stopTime.journey,
                                        vehicleId: props.vehicleId
                                    }}
                                    onClose={() => setModal(null)}
                                    onSave={() => props.onSave()}
                                />)}
                            >
                                New leg
                            </Link>
                        </td>
                        <td>{stopTime.journey.tripId}</td>
                        <td>{stopTime.journey.transportMode}</td>
                        <td>{stopTime.platform.name + (stopTime.platform.publicCode ? " (" + stopTime.platform.publicCode + ")" : "")}</td>
                        <td>{formatTime(stopTime.scheduledDepartureTime)}</td>
                        <td>{deviation + " min"}</td>
                        <td>{stopTime.journey.line.publicCode}</td>
                        <td>{stopTime.journey.destination}</td>
                        <td>{candidate.vehicleIds.map((v) => <div>{v}</div>)}</td>
                    </tr>;
                })}
                </tbody>
            </table>
            {modal}
        </DialogContent>
    </Dialog>;
}

function getTimeDeviationMinutes(legStartedTime: string, scheduleDepartureTime: string): number {
    const legStartedDateTime: DateTime = parseTime(legStartedTime);
    const scheduleDepartureDateTime: DateTime = parseTime(scheduleDepartureTime!);
    const deviation = scheduleDepartureDateTime.toSeconds() - legStartedDateTime.toSeconds();

    return Math.round(deviation / 60);
}