// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: reisefrihet/android/power_manager_dump.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "reisefrihet.android";

export interface PowerManagerDump {
  batteryDischargePredictionMillis: number;
  currentThermalStatus: PowerManagerDump_CurrentThermalStatus;
  locationPowerSaveMode: PowerManagerDump_LocationMode;
  thermalHeadroom: number;
  isDeviceIdleMode: boolean;
  isDeviceLightIdleMode: boolean;
  isExemptFromLowPowerStandby: boolean;
  isIgnoringBatteryOptimizations: boolean;
  isInteractive: boolean;
  isLowPowerStandbyEnabled: boolean;
  isPowerSaveMode: boolean;
}

export enum PowerManagerDump_CurrentThermalStatus {
  THERMAL_STATUS_NONE = "THERMAL_STATUS_NONE",
  THERMAL_STATUS_LIGHT = "THERMAL_STATUS_LIGHT",
  THERMAL_STATUS_MODERATE = "THERMAL_STATUS_MODERATE",
  THERMAL_STATUS_SEVERE = "THERMAL_STATUS_SEVERE",
  THERMAL_STATUS_CRITICAL = "THERMAL_STATUS_CRITICAL",
  THERMAL_STATUS_EMERGENCY = "THERMAL_STATUS_EMERGENCY",
  THERMAL_STATUS_SHUTDOWN = "THERMAL_STATUS_SHUTDOWN",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function powerManagerDump_CurrentThermalStatusFromJSON(object: any): PowerManagerDump_CurrentThermalStatus {
  switch (object) {
    case 0:
    case "THERMAL_STATUS_NONE":
      return PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE;
    case 1:
    case "THERMAL_STATUS_LIGHT":
      return PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_LIGHT;
    case 2:
    case "THERMAL_STATUS_MODERATE":
      return PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_MODERATE;
    case 3:
    case "THERMAL_STATUS_SEVERE":
      return PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_SEVERE;
    case 4:
    case "THERMAL_STATUS_CRITICAL":
      return PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_CRITICAL;
    case 5:
    case "THERMAL_STATUS_EMERGENCY":
      return PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_EMERGENCY;
    case 6:
    case "THERMAL_STATUS_SHUTDOWN":
      return PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_SHUTDOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PowerManagerDump_CurrentThermalStatus.UNRECOGNIZED;
  }
}

export function powerManagerDump_CurrentThermalStatusToJSON(object: PowerManagerDump_CurrentThermalStatus): string {
  switch (object) {
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE:
      return "THERMAL_STATUS_NONE";
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_LIGHT:
      return "THERMAL_STATUS_LIGHT";
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_MODERATE:
      return "THERMAL_STATUS_MODERATE";
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_SEVERE:
      return "THERMAL_STATUS_SEVERE";
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_CRITICAL:
      return "THERMAL_STATUS_CRITICAL";
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_EMERGENCY:
      return "THERMAL_STATUS_EMERGENCY";
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_SHUTDOWN:
      return "THERMAL_STATUS_SHUTDOWN";
    case PowerManagerDump_CurrentThermalStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function powerManagerDump_CurrentThermalStatusToNumber(object: PowerManagerDump_CurrentThermalStatus): number {
  switch (object) {
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE:
      return 0;
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_LIGHT:
      return 1;
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_MODERATE:
      return 2;
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_SEVERE:
      return 3;
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_CRITICAL:
      return 4;
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_EMERGENCY:
      return 5;
    case PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_SHUTDOWN:
      return 6;
    case PowerManagerDump_CurrentThermalStatus.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum PowerManagerDump_LocationMode {
  LOCATION_MODE_NO_CHANGE = "LOCATION_MODE_NO_CHANGE",
  LOCATION_MODE_GPS_DISABLED_WHEN_SCREEN_OFF = "LOCATION_MODE_GPS_DISABLED_WHEN_SCREEN_OFF",
  LOCATION_MODE_ALL_DISABLED_WHEN_SCREEN_OFF = "LOCATION_MODE_ALL_DISABLED_WHEN_SCREEN_OFF",
  LOCATION_MODE_FOREGROUND_ONLY = "LOCATION_MODE_FOREGROUND_ONLY",
  LOCATION_MODE_THROTTLE_REQUESTS_WHEN_SCREEN_OFF = "LOCATION_MODE_THROTTLE_REQUESTS_WHEN_SCREEN_OFF",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function powerManagerDump_LocationModeFromJSON(object: any): PowerManagerDump_LocationMode {
  switch (object) {
    case 0:
    case "LOCATION_MODE_NO_CHANGE":
      return PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE;
    case 1:
    case "LOCATION_MODE_GPS_DISABLED_WHEN_SCREEN_OFF":
      return PowerManagerDump_LocationMode.LOCATION_MODE_GPS_DISABLED_WHEN_SCREEN_OFF;
    case 2:
    case "LOCATION_MODE_ALL_DISABLED_WHEN_SCREEN_OFF":
      return PowerManagerDump_LocationMode.LOCATION_MODE_ALL_DISABLED_WHEN_SCREEN_OFF;
    case 3:
    case "LOCATION_MODE_FOREGROUND_ONLY":
      return PowerManagerDump_LocationMode.LOCATION_MODE_FOREGROUND_ONLY;
    case 4:
    case "LOCATION_MODE_THROTTLE_REQUESTS_WHEN_SCREEN_OFF":
      return PowerManagerDump_LocationMode.LOCATION_MODE_THROTTLE_REQUESTS_WHEN_SCREEN_OFF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PowerManagerDump_LocationMode.UNRECOGNIZED;
  }
}

export function powerManagerDump_LocationModeToJSON(object: PowerManagerDump_LocationMode): string {
  switch (object) {
    case PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE:
      return "LOCATION_MODE_NO_CHANGE";
    case PowerManagerDump_LocationMode.LOCATION_MODE_GPS_DISABLED_WHEN_SCREEN_OFF:
      return "LOCATION_MODE_GPS_DISABLED_WHEN_SCREEN_OFF";
    case PowerManagerDump_LocationMode.LOCATION_MODE_ALL_DISABLED_WHEN_SCREEN_OFF:
      return "LOCATION_MODE_ALL_DISABLED_WHEN_SCREEN_OFF";
    case PowerManagerDump_LocationMode.LOCATION_MODE_FOREGROUND_ONLY:
      return "LOCATION_MODE_FOREGROUND_ONLY";
    case PowerManagerDump_LocationMode.LOCATION_MODE_THROTTLE_REQUESTS_WHEN_SCREEN_OFF:
      return "LOCATION_MODE_THROTTLE_REQUESTS_WHEN_SCREEN_OFF";
    case PowerManagerDump_LocationMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function powerManagerDump_LocationModeToNumber(object: PowerManagerDump_LocationMode): number {
  switch (object) {
    case PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE:
      return 0;
    case PowerManagerDump_LocationMode.LOCATION_MODE_GPS_DISABLED_WHEN_SCREEN_OFF:
      return 1;
    case PowerManagerDump_LocationMode.LOCATION_MODE_ALL_DISABLED_WHEN_SCREEN_OFF:
      return 2;
    case PowerManagerDump_LocationMode.LOCATION_MODE_FOREGROUND_ONLY:
      return 3;
    case PowerManagerDump_LocationMode.LOCATION_MODE_THROTTLE_REQUESTS_WHEN_SCREEN_OFF:
      return 4;
    case PowerManagerDump_LocationMode.UNRECOGNIZED:
    default:
      return -1;
  }
}

function createBasePowerManagerDump(): PowerManagerDump {
  return {
    batteryDischargePredictionMillis: 0,
    currentThermalStatus: PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE,
    locationPowerSaveMode: PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE,
    thermalHeadroom: 0,
    isDeviceIdleMode: false,
    isDeviceLightIdleMode: false,
    isExemptFromLowPowerStandby: false,
    isIgnoringBatteryOptimizations: false,
    isInteractive: false,
    isLowPowerStandbyEnabled: false,
    isPowerSaveMode: false,
  };
}

export const PowerManagerDump: MessageFns<PowerManagerDump> = {
  encode(message: PowerManagerDump, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.batteryDischargePredictionMillis !== 0) {
      writer.uint32(8).int64(message.batteryDischargePredictionMillis);
    }
    if (message.currentThermalStatus !== PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE) {
      writer.uint32(16).int32(powerManagerDump_CurrentThermalStatusToNumber(message.currentThermalStatus));
    }
    if (message.locationPowerSaveMode !== PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE) {
      writer.uint32(24).int32(powerManagerDump_LocationModeToNumber(message.locationPowerSaveMode));
    }
    if (message.thermalHeadroom !== 0) {
      writer.uint32(37).float(message.thermalHeadroom);
    }
    if (message.isDeviceIdleMode !== false) {
      writer.uint32(40).bool(message.isDeviceIdleMode);
    }
    if (message.isDeviceLightIdleMode !== false) {
      writer.uint32(48).bool(message.isDeviceLightIdleMode);
    }
    if (message.isExemptFromLowPowerStandby !== false) {
      writer.uint32(56).bool(message.isExemptFromLowPowerStandby);
    }
    if (message.isIgnoringBatteryOptimizations !== false) {
      writer.uint32(64).bool(message.isIgnoringBatteryOptimizations);
    }
    if (message.isInteractive !== false) {
      writer.uint32(72).bool(message.isInteractive);
    }
    if (message.isLowPowerStandbyEnabled !== false) {
      writer.uint32(80).bool(message.isLowPowerStandbyEnabled);
    }
    if (message.isPowerSaveMode !== false) {
      writer.uint32(88).bool(message.isPowerSaveMode);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PowerManagerDump {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePowerManagerDump();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.batteryDischargePredictionMillis = longToNumber(reader.int64());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.currentThermalStatus = powerManagerDump_CurrentThermalStatusFromJSON(reader.int32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.locationPowerSaveMode = powerManagerDump_LocationModeFromJSON(reader.int32());
          continue;
        }
        case 4: {
          if (tag !== 37) {
            break;
          }

          message.thermalHeadroom = reader.float();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.isDeviceIdleMode = reader.bool();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.isDeviceLightIdleMode = reader.bool();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.isExemptFromLowPowerStandby = reader.bool();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.isIgnoringBatteryOptimizations = reader.bool();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.isInteractive = reader.bool();
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.isLowPowerStandbyEnabled = reader.bool();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.isPowerSaveMode = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PowerManagerDump {
    return {
      batteryDischargePredictionMillis: isSet(object.batteryDischargePredictionMillis)
        ? globalThis.Number(object.batteryDischargePredictionMillis)
        : 0,
      currentThermalStatus: isSet(object.currentThermalStatus)
        ? powerManagerDump_CurrentThermalStatusFromJSON(object.currentThermalStatus)
        : PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE,
      locationPowerSaveMode: isSet(object.locationPowerSaveMode)
        ? powerManagerDump_LocationModeFromJSON(object.locationPowerSaveMode)
        : PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE,
      thermalHeadroom: isSet(object.thermalHeadroom) ? globalThis.Number(object.thermalHeadroom) : 0,
      isDeviceIdleMode: isSet(object.isDeviceIdleMode) ? globalThis.Boolean(object.isDeviceIdleMode) : false,
      isDeviceLightIdleMode: isSet(object.isDeviceLightIdleMode)
        ? globalThis.Boolean(object.isDeviceLightIdleMode)
        : false,
      isExemptFromLowPowerStandby: isSet(object.isExemptFromLowPowerStandby)
        ? globalThis.Boolean(object.isExemptFromLowPowerStandby)
        : false,
      isIgnoringBatteryOptimizations: isSet(object.isIgnoringBatteryOptimizations)
        ? globalThis.Boolean(object.isIgnoringBatteryOptimizations)
        : false,
      isInteractive: isSet(object.isInteractive) ? globalThis.Boolean(object.isInteractive) : false,
      isLowPowerStandbyEnabled: isSet(object.isLowPowerStandbyEnabled)
        ? globalThis.Boolean(object.isLowPowerStandbyEnabled)
        : false,
      isPowerSaveMode: isSet(object.isPowerSaveMode) ? globalThis.Boolean(object.isPowerSaveMode) : false,
    };
  },

  toJSON(message: PowerManagerDump): unknown {
    const obj: any = {};
    if (message.batteryDischargePredictionMillis !== 0) {
      obj.batteryDischargePredictionMillis = Math.round(message.batteryDischargePredictionMillis);
    }
    if (message.currentThermalStatus !== PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE) {
      obj.currentThermalStatus = powerManagerDump_CurrentThermalStatusToJSON(message.currentThermalStatus);
    }
    if (message.locationPowerSaveMode !== PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE) {
      obj.locationPowerSaveMode = powerManagerDump_LocationModeToJSON(message.locationPowerSaveMode);
    }
    if (message.thermalHeadroom !== 0) {
      obj.thermalHeadroom = message.thermalHeadroom;
    }
    if (message.isDeviceIdleMode !== false) {
      obj.isDeviceIdleMode = message.isDeviceIdleMode;
    }
    if (message.isDeviceLightIdleMode !== false) {
      obj.isDeviceLightIdleMode = message.isDeviceLightIdleMode;
    }
    if (message.isExemptFromLowPowerStandby !== false) {
      obj.isExemptFromLowPowerStandby = message.isExemptFromLowPowerStandby;
    }
    if (message.isIgnoringBatteryOptimizations !== false) {
      obj.isIgnoringBatteryOptimizations = message.isIgnoringBatteryOptimizations;
    }
    if (message.isInteractive !== false) {
      obj.isInteractive = message.isInteractive;
    }
    if (message.isLowPowerStandbyEnabled !== false) {
      obj.isLowPowerStandbyEnabled = message.isLowPowerStandbyEnabled;
    }
    if (message.isPowerSaveMode !== false) {
      obj.isPowerSaveMode = message.isPowerSaveMode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PowerManagerDump>, I>>(base?: I): PowerManagerDump {
    return PowerManagerDump.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PowerManagerDump>, I>>(object: I): PowerManagerDump {
    const message = createBasePowerManagerDump();
    message.batteryDischargePredictionMillis = object.batteryDischargePredictionMillis ?? 0;
    message.currentThermalStatus = object.currentThermalStatus ??
      PowerManagerDump_CurrentThermalStatus.THERMAL_STATUS_NONE;
    message.locationPowerSaveMode = object.locationPowerSaveMode ??
      PowerManagerDump_LocationMode.LOCATION_MODE_NO_CHANGE;
    message.thermalHeadroom = object.thermalHeadroom ?? 0;
    message.isDeviceIdleMode = object.isDeviceIdleMode ?? false;
    message.isDeviceLightIdleMode = object.isDeviceLightIdleMode ?? false;
    message.isExemptFromLowPowerStandby = object.isExemptFromLowPowerStandby ?? false;
    message.isIgnoringBatteryOptimizations = object.isIgnoringBatteryOptimizations ?? false;
    message.isInteractive = object.isInteractive ?? false;
    message.isLowPowerStandbyEnabled = object.isLowPowerStandbyEnabled ?? false;
    message.isPowerSaveMode = object.isPowerSaveMode ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
