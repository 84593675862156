// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: no/kolumbus/sanntid_api/common.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "";

export enum VehicleSnapshotSource {
  SIGNALR = "SIGNALR",
  GRPC = "GRPC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function vehicleSnapshotSourceFromJSON(object: any): VehicleSnapshotSource {
  switch (object) {
    case 0:
    case "SIGNALR":
      return VehicleSnapshotSource.SIGNALR;
    case 1:
    case "GRPC":
      return VehicleSnapshotSource.GRPC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VehicleSnapshotSource.UNRECOGNIZED;
  }
}

export function vehicleSnapshotSourceToJSON(object: VehicleSnapshotSource): string {
  switch (object) {
    case VehicleSnapshotSource.SIGNALR:
      return "SIGNALR";
    case VehicleSnapshotSource.GRPC:
      return "GRPC";
    case VehicleSnapshotSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function vehicleSnapshotSourceToNumber(object: VehicleSnapshotSource): number {
  switch (object) {
    case VehicleSnapshotSource.SIGNALR:
      return 0;
    case VehicleSnapshotSource.GRPC:
      return 1;
    case VehicleSnapshotSource.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum TransportMode {
  None = "None",
  Bus = "Bus",
  Water = "Water",
  Rail = "Rail",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function transportModeFromJSON(object: any): TransportMode {
  switch (object) {
    case 0:
    case "None":
      return TransportMode.None;
    case 2:
    case "Bus":
      return TransportMode.Bus;
    case 4:
    case "Water":
      return TransportMode.Water;
    case 7:
    case "Rail":
      return TransportMode.Rail;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TransportMode.UNRECOGNIZED;
  }
}

export function transportModeToJSON(object: TransportMode): string {
  switch (object) {
    case TransportMode.None:
      return "None";
    case TransportMode.Bus:
      return "Bus";
    case TransportMode.Water:
      return "Water";
    case TransportMode.Rail:
      return "Rail";
    case TransportMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function transportModeToNumber(object: TransportMode): number {
  switch (object) {
    case TransportMode.None:
      return 0;
    case TransportMode.Bus:
      return 2;
    case TransportMode.Water:
      return 4;
    case TransportMode.Rail:
      return 7;
    case TransportMode.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum PassengerType {
  ADULT = "ADULT",
  CHILD = "CHILD",
  SENIOR = "SENIOR",
  YOUTH = "YOUTH",
  STUDENT = "STUDENT",
  MILITARY = "MILITARY",
  HJEM_JOBB_HJEM = "HJEM_JOBB_HJEM",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function passengerTypeFromJSON(object: any): PassengerType {
  switch (object) {
    case 0:
    case "ADULT":
      return PassengerType.ADULT;
    case 1:
    case "CHILD":
      return PassengerType.CHILD;
    case 2:
    case "SENIOR":
      return PassengerType.SENIOR;
    case 3:
    case "YOUTH":
      return PassengerType.YOUTH;
    case 4:
    case "STUDENT":
      return PassengerType.STUDENT;
    case 5:
    case "MILITARY":
      return PassengerType.MILITARY;
    case 6:
    case "HJEM_JOBB_HJEM":
      return PassengerType.HJEM_JOBB_HJEM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PassengerType.UNRECOGNIZED;
  }
}

export function passengerTypeToJSON(object: PassengerType): string {
  switch (object) {
    case PassengerType.ADULT:
      return "ADULT";
    case PassengerType.CHILD:
      return "CHILD";
    case PassengerType.SENIOR:
      return "SENIOR";
    case PassengerType.YOUTH:
      return "YOUTH";
    case PassengerType.STUDENT:
      return "STUDENT";
    case PassengerType.MILITARY:
      return "MILITARY";
    case PassengerType.HJEM_JOBB_HJEM:
      return "HJEM_JOBB_HJEM";
    case PassengerType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function passengerTypeToNumber(object: PassengerType): number {
  switch (object) {
    case PassengerType.ADULT:
      return 0;
    case PassengerType.CHILD:
      return 1;
    case PassengerType.SENIOR:
      return 2;
    case PassengerType.YOUTH:
      return 3;
    case PassengerType.STUDENT:
      return 4;
    case PassengerType.MILITARY:
      return 5;
    case PassengerType.HJEM_JOBB_HJEM:
      return 6;
    case PassengerType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum TicketEndedReason {
  NOT_ENDED = "NOT_ENDED",
  TELEMETRY_FAILURE = "TELEMETRY_FAILURE",
  TRIP_ENDED = "TRIP_ENDED",
  UNKNOWN = "UNKNOWN",
  DISEMBARK_VEHICLE = "DISEMBARK_VEHICLE",
  INACTIVITY = "INACTIVITY",
  USER_INITIATED = "USER_INITIATED",
  TAMPER_POWER_SAVER_ENABLED = "TAMPER_POWER_SAVER_ENABLED",
  TAMPER_PERMISSION_REVOKED = "TAMPER_PERMISSION_REVOKED",
  APP_TERMINATED_NOTIFICATION_IGNORED = "APP_TERMINATED_NOTIFICATION_IGNORED",
  ANDROID_FOREGROUND_SERVICE_NOTIFICATION_DISMISSED = "ANDROID_FOREGROUND_SERVICE_NOTIFICATION_DISMISSED",
  ANDROID_APP_TERMINATED_USER_REQUESTED = "ANDROID_APP_TERMINATED_USER_REQUESTED",
  ANDROID_FOREGROUND_SERVICE_FORBIDDEN = "ANDROID_FOREGROUND_SERVICE_FORBIDDEN",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function ticketEndedReasonFromJSON(object: any): TicketEndedReason {
  switch (object) {
    case 0:
    case "NOT_ENDED":
      return TicketEndedReason.NOT_ENDED;
    case 1:
    case "TELEMETRY_FAILURE":
      return TicketEndedReason.TELEMETRY_FAILURE;
    case 3:
    case "TRIP_ENDED":
      return TicketEndedReason.TRIP_ENDED;
    case 2:
    case "UNKNOWN":
      return TicketEndedReason.UNKNOWN;
    case 4:
    case "DISEMBARK_VEHICLE":
      return TicketEndedReason.DISEMBARK_VEHICLE;
    case 5:
    case "INACTIVITY":
      return TicketEndedReason.INACTIVITY;
    case 6:
    case "USER_INITIATED":
      return TicketEndedReason.USER_INITIATED;
    case 12:
    case "TAMPER_POWER_SAVER_ENABLED":
      return TicketEndedReason.TAMPER_POWER_SAVER_ENABLED;
    case 17:
    case "TAMPER_PERMISSION_REVOKED":
      return TicketEndedReason.TAMPER_PERMISSION_REVOKED;
    case 16:
    case "APP_TERMINATED_NOTIFICATION_IGNORED":
      return TicketEndedReason.APP_TERMINATED_NOTIFICATION_IGNORED;
    case 19:
    case "ANDROID_FOREGROUND_SERVICE_NOTIFICATION_DISMISSED":
      return TicketEndedReason.ANDROID_FOREGROUND_SERVICE_NOTIFICATION_DISMISSED;
    case 20:
    case "ANDROID_APP_TERMINATED_USER_REQUESTED":
      return TicketEndedReason.ANDROID_APP_TERMINATED_USER_REQUESTED;
    case 21:
    case "ANDROID_FOREGROUND_SERVICE_FORBIDDEN":
      return TicketEndedReason.ANDROID_FOREGROUND_SERVICE_FORBIDDEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TicketEndedReason.UNRECOGNIZED;
  }
}

export function ticketEndedReasonToJSON(object: TicketEndedReason): string {
  switch (object) {
    case TicketEndedReason.NOT_ENDED:
      return "NOT_ENDED";
    case TicketEndedReason.TELEMETRY_FAILURE:
      return "TELEMETRY_FAILURE";
    case TicketEndedReason.TRIP_ENDED:
      return "TRIP_ENDED";
    case TicketEndedReason.UNKNOWN:
      return "UNKNOWN";
    case TicketEndedReason.DISEMBARK_VEHICLE:
      return "DISEMBARK_VEHICLE";
    case TicketEndedReason.INACTIVITY:
      return "INACTIVITY";
    case TicketEndedReason.USER_INITIATED:
      return "USER_INITIATED";
    case TicketEndedReason.TAMPER_POWER_SAVER_ENABLED:
      return "TAMPER_POWER_SAVER_ENABLED";
    case TicketEndedReason.TAMPER_PERMISSION_REVOKED:
      return "TAMPER_PERMISSION_REVOKED";
    case TicketEndedReason.APP_TERMINATED_NOTIFICATION_IGNORED:
      return "APP_TERMINATED_NOTIFICATION_IGNORED";
    case TicketEndedReason.ANDROID_FOREGROUND_SERVICE_NOTIFICATION_DISMISSED:
      return "ANDROID_FOREGROUND_SERVICE_NOTIFICATION_DISMISSED";
    case TicketEndedReason.ANDROID_APP_TERMINATED_USER_REQUESTED:
      return "ANDROID_APP_TERMINATED_USER_REQUESTED";
    case TicketEndedReason.ANDROID_FOREGROUND_SERVICE_FORBIDDEN:
      return "ANDROID_FOREGROUND_SERVICE_FORBIDDEN";
    case TicketEndedReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function ticketEndedReasonToNumber(object: TicketEndedReason): number {
  switch (object) {
    case TicketEndedReason.NOT_ENDED:
      return 0;
    case TicketEndedReason.TELEMETRY_FAILURE:
      return 1;
    case TicketEndedReason.TRIP_ENDED:
      return 3;
    case TicketEndedReason.UNKNOWN:
      return 2;
    case TicketEndedReason.DISEMBARK_VEHICLE:
      return 4;
    case TicketEndedReason.INACTIVITY:
      return 5;
    case TicketEndedReason.USER_INITIATED:
      return 6;
    case TicketEndedReason.TAMPER_POWER_SAVER_ENABLED:
      return 12;
    case TicketEndedReason.TAMPER_PERMISSION_REVOKED:
      return 17;
    case TicketEndedReason.APP_TERMINATED_NOTIFICATION_IGNORED:
      return 16;
    case TicketEndedReason.ANDROID_FOREGROUND_SERVICE_NOTIFICATION_DISMISSED:
      return 19;
    case TicketEndedReason.ANDROID_APP_TERMINATED_USER_REQUESTED:
      return 20;
    case TicketEndedReason.ANDROID_FOREGROUND_SERVICE_FORBIDDEN:
      return 21;
    case TicketEndedReason.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum DevicePlatform {
  UNKNOWN_PLATFORM = "UNKNOWN_PLATFORM",
  IOS = "IOS",
  ANDROID = "ANDROID",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function devicePlatformFromJSON(object: any): DevicePlatform {
  switch (object) {
    case 0:
    case "UNKNOWN_PLATFORM":
      return DevicePlatform.UNKNOWN_PLATFORM;
    case 1:
    case "IOS":
      return DevicePlatform.IOS;
    case 2:
    case "ANDROID":
      return DevicePlatform.ANDROID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DevicePlatform.UNRECOGNIZED;
  }
}

export function devicePlatformToJSON(object: DevicePlatform): string {
  switch (object) {
    case DevicePlatform.UNKNOWN_PLATFORM:
      return "UNKNOWN_PLATFORM";
    case DevicePlatform.IOS:
      return "IOS";
    case DevicePlatform.ANDROID:
      return "ANDROID";
    case DevicePlatform.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function devicePlatformToNumber(object: DevicePlatform): number {
  switch (object) {
    case DevicePlatform.UNKNOWN_PLATFORM:
      return 0;
    case DevicePlatform.IOS:
      return 1;
    case DevicePlatform.ANDROID:
      return 2;
    case DevicePlatform.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum ReisefrihetEnvironmentProto {
  PRODUCTION = "PRODUCTION",
  TEST = "TEST",
  UNIT_TEST = "UNIT_TEST",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function reisefrihetEnvironmentProtoFromJSON(object: any): ReisefrihetEnvironmentProto {
  switch (object) {
    case 0:
    case "PRODUCTION":
      return ReisefrihetEnvironmentProto.PRODUCTION;
    case 1:
    case "TEST":
      return ReisefrihetEnvironmentProto.TEST;
    case 2:
    case "UNIT_TEST":
      return ReisefrihetEnvironmentProto.UNIT_TEST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ReisefrihetEnvironmentProto.UNRECOGNIZED;
  }
}

export function reisefrihetEnvironmentProtoToJSON(object: ReisefrihetEnvironmentProto): string {
  switch (object) {
    case ReisefrihetEnvironmentProto.PRODUCTION:
      return "PRODUCTION";
    case ReisefrihetEnvironmentProto.TEST:
      return "TEST";
    case ReisefrihetEnvironmentProto.UNIT_TEST:
      return "UNIT_TEST";
    case ReisefrihetEnvironmentProto.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function reisefrihetEnvironmentProtoToNumber(object: ReisefrihetEnvironmentProto): number {
  switch (object) {
    case ReisefrihetEnvironmentProto.PRODUCTION:
      return 0;
    case ReisefrihetEnvironmentProto.TEST:
      return 1;
    case ReisefrihetEnvironmentProto.UNIT_TEST:
      return 2;
    case ReisefrihetEnvironmentProto.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Empty {
}

export interface Timestamp {
  seconds: number;
  nanos: number;
}

export interface TripDateId {
  tripId: number;
  /** @deprecated */
  date: string;
  epochDay: number;
  serviceJourneyId: string;
}

function createBaseEmpty(): Empty {
  return {};
}

export const Empty: MessageFns<Empty> = {
  encode(_: Empty, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Empty {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Empty {
    return {};
  },

  toJSON(_: Empty): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Empty>, I>>(base?: I): Empty {
    return Empty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Empty>, I>>(_: I): Empty {
    const message = createBaseEmpty();
    return message;
  },
};

function createBaseTimestamp(): Timestamp {
  return { seconds: 0, nanos: 0 };
}

export const Timestamp: MessageFns<Timestamp> = {
  encode(message: Timestamp, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.seconds !== 0) {
      writer.uint32(8).int64(message.seconds);
    }
    if (message.nanos !== 0) {
      writer.uint32(16).int32(message.nanos);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Timestamp {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimestamp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.seconds = longToNumber(reader.int64());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.nanos = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Timestamp {
    return {
      seconds: isSet(object.seconds) ? globalThis.Number(object.seconds) : 0,
      nanos: isSet(object.nanos) ? globalThis.Number(object.nanos) : 0,
    };
  },

  toJSON(message: Timestamp): unknown {
    const obj: any = {};
    if (message.seconds !== 0) {
      obj.seconds = Math.round(message.seconds);
    }
    if (message.nanos !== 0) {
      obj.nanos = Math.round(message.nanos);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Timestamp>, I>>(base?: I): Timestamp {
    return Timestamp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Timestamp>, I>>(object: I): Timestamp {
    const message = createBaseTimestamp();
    message.seconds = object.seconds ?? 0;
    message.nanos = object.nanos ?? 0;
    return message;
  },
};

function createBaseTripDateId(): TripDateId {
  return { tripId: 0, date: "", epochDay: 0, serviceJourneyId: "" };
}

export const TripDateId: MessageFns<TripDateId> = {
  encode(message: TripDateId, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tripId !== 0) {
      writer.uint32(8).uint32(message.tripId);
    }
    if (message.date !== "") {
      writer.uint32(18).string(message.date);
    }
    if (message.epochDay !== 0) {
      writer.uint32(24).uint32(message.epochDay);
    }
    if (message.serviceJourneyId !== "") {
      writer.uint32(34).string(message.serviceJourneyId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TripDateId {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTripDateId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.tripId = reader.uint32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.date = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.epochDay = reader.uint32();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.serviceJourneyId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TripDateId {
    return {
      tripId: isSet(object.tripId) ? globalThis.Number(object.tripId) : 0,
      date: isSet(object.date) ? globalThis.String(object.date) : "",
      epochDay: isSet(object.epochDay) ? globalThis.Number(object.epochDay) : 0,
      serviceJourneyId: isSet(object.serviceJourneyId) ? globalThis.String(object.serviceJourneyId) : "",
    };
  },

  toJSON(message: TripDateId): unknown {
    const obj: any = {};
    if (message.tripId !== 0) {
      obj.tripId = Math.round(message.tripId);
    }
    if (message.date !== "") {
      obj.date = message.date;
    }
    if (message.epochDay !== 0) {
      obj.epochDay = Math.round(message.epochDay);
    }
    if (message.serviceJourneyId !== "") {
      obj.serviceJourneyId = message.serviceJourneyId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TripDateId>, I>>(base?: I): TripDateId {
    return TripDateId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TripDateId>, I>>(object: I): TripDateId {
    const message = createBaseTripDateId();
    message.tripId = object.tripId ?? 0;
    message.date = object.date ?? "";
    message.epochDay = object.epochDay ?? 0;
    message.serviceJourneyId = object.serviceJourneyId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
