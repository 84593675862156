import { getLegColor, TicketMapController, zIndexes } from "./map_controller";
import { useEffect, useRef } from "react";
import { splitPositionLog } from "./phone_polyline_layer";
import { toLatLng } from "../../../util/coordinates";
import { TpLocalPriceFragment } from "../../../generated/gql/graphql";

export function useLegsVehiclePolylineLayer(controller: TicketMapController) {
    const {current: legPolylines} = useRef<google.maps.Polyline[]>([]);

    const legs = controller.legs;
    const map = controller.map;

    useEffect(() => {
        if (!map) return;

        legPolylines.splice(0).forEach((polyline) => polyline.setMap(null));


        legs.forEach((leg, legIndex) => {
            const strokeColor = getLegColor(legIndex)[700];
            const strokeWeight = 4;

            const vehiclePositionLog = (leg as unknown as TpLocalPriceFragment["legs"][0]).vehiclePositionLog;
            const [before, during, after] = splitPositionLog(vehiclePositionLog, leg.startedTime, leg.description.endedTime);

            const beforePolyline = new google.maps.Polyline({
                map: map,
                path: before.map(toLatLng),
                zIndex: zIndexes.legVehiclePolyline,
                strokeColor,
                strokeOpacity: 0.3,
                strokeWeight
            });

            const polyline = new google.maps.Polyline({
                map: map,
                path: during.map(toLatLng),
                zIndex: zIndexes.legVehiclePolylineEmbarked,
                strokeColor,
                strokeWeight
            });

            const afterPolyline = new google.maps.Polyline({
                map: map,
                path: after.map(toLatLng),
                zIndex: zIndexes.legVehiclePolyline,
                strokeColor,
                strokeOpacity: 0.3,
                strokeWeight
            });

            legPolylines.push(polyline);
            legPolylines.push(beforePolyline);
            legPolylines.push(afterPolyline);
        });

        // eslint-disable-next-line
    }, [controller.map, legs]);

}