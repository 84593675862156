// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: no/kolumbus/sanntid_api/telemetry_android.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "";

/** package no.kolumbus.sanntid_api; */

export enum NullableBoolean {
  B_NULL = "B_NULL",
  B_FALSE = "B_FALSE",
  B_TRUE = "B_TRUE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function nullableBooleanFromJSON(object: any): NullableBoolean {
  switch (object) {
    case 0:
    case "B_NULL":
      return NullableBoolean.B_NULL;
    case 1:
    case "B_FALSE":
      return NullableBoolean.B_FALSE;
    case 2:
    case "B_TRUE":
      return NullableBoolean.B_TRUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NullableBoolean.UNRECOGNIZED;
  }
}

export function nullableBooleanToJSON(object: NullableBoolean): string {
  switch (object) {
    case NullableBoolean.B_NULL:
      return "B_NULL";
    case NullableBoolean.B_FALSE:
      return "B_FALSE";
    case NullableBoolean.B_TRUE:
      return "B_TRUE";
    case NullableBoolean.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function nullableBooleanToNumber(object: NullableBoolean): number {
  switch (object) {
    case NullableBoolean.B_NULL:
      return 0;
    case NullableBoolean.B_FALSE:
      return 1;
    case NullableBoolean.B_TRUE:
      return 2;
    case NullableBoolean.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface AndroidLocationProviderState {
  providerName: string;
  providerEnabled: NullableBoolean;
  locationEnabled: NullableBoolean;
  gnssCapabilities: string;
}

function createBaseAndroidLocationProviderState(): AndroidLocationProviderState {
  return {
    providerName: "",
    providerEnabled: NullableBoolean.B_NULL,
    locationEnabled: NullableBoolean.B_NULL,
    gnssCapabilities: "",
  };
}

export const AndroidLocationProviderState: MessageFns<AndroidLocationProviderState> = {
  encode(message: AndroidLocationProviderState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.providerName !== "") {
      writer.uint32(10).string(message.providerName);
    }
    if (message.providerEnabled !== NullableBoolean.B_NULL) {
      writer.uint32(16).int32(nullableBooleanToNumber(message.providerEnabled));
    }
    if (message.locationEnabled !== NullableBoolean.B_NULL) {
      writer.uint32(24).int32(nullableBooleanToNumber(message.locationEnabled));
    }
    if (message.gnssCapabilities !== "") {
      writer.uint32(34).string(message.gnssCapabilities);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AndroidLocationProviderState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAndroidLocationProviderState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.providerName = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.providerEnabled = nullableBooleanFromJSON(reader.int32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.locationEnabled = nullableBooleanFromJSON(reader.int32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.gnssCapabilities = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AndroidLocationProviderState {
    return {
      providerName: isSet(object.providerName) ? globalThis.String(object.providerName) : "",
      providerEnabled: isSet(object.providerEnabled)
        ? nullableBooleanFromJSON(object.providerEnabled)
        : NullableBoolean.B_NULL,
      locationEnabled: isSet(object.locationEnabled)
        ? nullableBooleanFromJSON(object.locationEnabled)
        : NullableBoolean.B_NULL,
      gnssCapabilities: isSet(object.gnssCapabilities) ? globalThis.String(object.gnssCapabilities) : "",
    };
  },

  toJSON(message: AndroidLocationProviderState): unknown {
    const obj: any = {};
    if (message.providerName !== "") {
      obj.providerName = message.providerName;
    }
    if (message.providerEnabled !== NullableBoolean.B_NULL) {
      obj.providerEnabled = nullableBooleanToJSON(message.providerEnabled);
    }
    if (message.locationEnabled !== NullableBoolean.B_NULL) {
      obj.locationEnabled = nullableBooleanToJSON(message.locationEnabled);
    }
    if (message.gnssCapabilities !== "") {
      obj.gnssCapabilities = message.gnssCapabilities;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AndroidLocationProviderState>, I>>(base?: I): AndroidLocationProviderState {
    return AndroidLocationProviderState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AndroidLocationProviderState>, I>>(object: I): AndroidLocationProviderState {
    const message = createBaseAndroidLocationProviderState();
    message.providerName = object.providerName ?? "";
    message.providerEnabled = object.providerEnabled ?? NullableBoolean.B_NULL;
    message.locationEnabled = object.locationEnabled ?? NullableBoolean.B_NULL;
    message.gnssCapabilities = object.gnssCapabilities ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
