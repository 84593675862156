import React from "react";
import {Typography} from "@mui/material";
import {TimestampText} from "../../widgets/date_time";
import {sortBy} from "lodash";
import {TraceButton} from "../../widgets/TraceButton";
import {useTicketPageV2Context} from "../ticket_v2/context";
import {gql} from "@apollo/client";

export const TICKET_STATEMENT_HISTORY_TAB_FRAGMENT = gql`
    fragment TicketStatementHistoryTab on Ticket {
        statementHistory {
            id
            analysisResultType
            responseStatusCode
            responseStatusDescription
            ticketStatementJsonString
            ticketStatementReportResponseJsonString
            createdTime
            trace {
                traceId
                spanId
                sampled
            }
        }
    }
`;

export function TicketStatementHistoryTable() {
    const context = useTicketPageV2Context();
    const list = sortBy(context.ticket.statementHistory, "createdTime").toReversed();

    return <div>
        <Typography variant={"h6"}>
            Ticket statement history
        </Typography>
        <table>
            <thead>
            <tr>
                <th>Created time</th>
                <th>Result type</th>
                <th>Ticket statement</th>
                <th>Response status</th>
                <th>Response</th>
            </tr>
            </thead>
            <tbody>
            {list.map((row, index) => {
                const nextStatement = list[index + 1];
                const formatted = formatTicketStatement(row.ticketStatementJsonString);
                const nextFormatted = nextStatement ? formatTicketStatement(nextStatement.ticketStatementJsonString) : null;
                const isSameAsNext = formatted === nextFormatted;

                return <tr key={row.id}>
                    <td><TimestampText value={row.createdTime} /></td>
                    <td>{row.analysisResultType}</td>
                    <td>
                        {isSameAsNext
                            ? "Same as previous statement"
                            : <pre>
                                {formatTicketStatement(row.ticketStatementJsonString)}
                            </pre>}

                        <a href={"/api/admin/ticket/ticket-statement-request.json?id=" + row.id} target={"_blank"}>
                            {" (JSON file)"}
                        </a>
                    </td>
                    <td>
                        {row.responseStatusDescription
                            ? <abbr title={row.responseStatusDescription}>
                                {row.responseStatusCode}
                            </abbr>
                            : row.responseStatusCode}
                    </td>
                    <td>
                        <pre>
                            {formatJsonPretty(row.ticketStatementReportResponseJsonString)}
                        </pre>
                    </td>
                    <td align={"right"}><TraceButton trace={row.trace} /></td>
                </tr>;
            })}
            </tbody>
        </table>
    </div>;
}

function formatTicketStatement(jsonString: string): string {
    const json = JSON.parse(jsonString);
    const hasDebug = json["debugAnalysisResult"] != undefined || json["debugOrderSummary"] != undefined;

    /// clear debug fields for rendering
    const jsonLocal = json["localPriceAnalysis"];
    if (jsonLocal != null) {
        delete jsonLocal["debugAnalysisResult"];
        delete jsonLocal["debugOrderSummary"];
    }

    return JSON.stringify(json, null, "\t") + (hasDebug ? " + DEBUG" : "");
}

function formatJsonPretty(jsonString: string): String {
    return JSON.stringify(
        JSON.parse(jsonString),
        null,
        "\t"
    );
}