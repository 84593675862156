import {useEffect, useMemo} from "react";
import {TicketMapController, zIndexes} from "./map_controller";
import {toLatLng} from "../../../util/coordinates";
import {createRoot} from "react-dom/client";
import {TicketRecordedPosition} from "../../../generated/gql/graphql";

function PhonePositionMarkerIcon() {
    return <div style={{
        width: "32px",
        height: "32px",
        transform: "translateY(50%)",
        borderRadius: "50%",
        boxShadow: "0px 0px 10px rgba(0, 146, 255, 0.5)"
    }}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 30.8136C7.8187 30.8136 1.18645 24.1813 1.18645 16C1.18645 7.8187 7.8187 1.18645 16 1.18645C24.1813 1.18645 30.8135 7.8187 30.8135 16C30.8135 24.1813 24.1813 30.8136 16 30.8136Z"
                fill="#0092FF" stroke="white" stroke-width="2.3729" />
        </svg>

    </div>;
}

export function usePhonePositionMarkerLayer(controller: TicketMapController) {
    const layer = useMemo(() => new PhonePositionMarkerLayer(controller), []);


    const {selectedRecordedPosition, phonePositionHovering} = controller.state;
    const phonePosition = phonePositionHovering || selectedRecordedPosition;

    useEffect(() => {
        layer.phonePosition = phonePosition;
    }, [controller.map, phonePosition]);


}


class PhonePositionMarkerLayer {
    private readonly controller: TicketMapController;
    private readonly markerContainer = document.createElement("div");
    private readonly markerRenderer = createRoot(this.markerContainer);
    private readonly marker = new google.maps.marker.AdvancedMarkerElement({
        zIndex: zIndexes.phonePositionMarker,
        content: this.markerContainer
    });
    private readonly circle = new google.maps.Circle({
        zIndex: zIndexes.phonePositionMarker - 1,
        fillOpacity: 0.3,
        fillColor: "#0092FF",
        strokeColor: "#0092FF"
    });

    constructor(controller: TicketMapController) {
        this.controller = controller;
        this.markerRenderer.render(<PhonePositionMarkerIcon />);
    }

    set phonePosition(phonePosition: TicketRecordedPosition | null | undefined) {
        if (phonePosition) {
            this.marker.position = toLatLng(phonePosition);
            this.marker.map = this.controller.map;


            this.circle.setRadius(phonePosition.accuracy);
            this.circle.setCenter(toLatLng(phonePosition));
            this.circle.setMap(this.controller.map);
        } else {
            this.marker.map = null;
            this.circle.setMap(null);
        }
    }
}