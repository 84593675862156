// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: reisefrihet/android/activity_manager_dump.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../no/kolumbus/sanntid_api/common";

export const protobufPackage = "reisefrihet.android";

export enum AndroidApplicationProcess {
  MAIN = "MAIN",
  TICKET = "TICKET",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function androidApplicationProcessFromJSON(object: any): AndroidApplicationProcess {
  switch (object) {
    case 0:
    case "MAIN":
      return AndroidApplicationProcess.MAIN;
    case 2:
    case "TICKET":
      return AndroidApplicationProcess.TICKET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AndroidApplicationProcess.UNRECOGNIZED;
  }
}

export function androidApplicationProcessToJSON(object: AndroidApplicationProcess): string {
  switch (object) {
    case AndroidApplicationProcess.MAIN:
      return "MAIN";
    case AndroidApplicationProcess.TICKET:
      return "TICKET";
    case AndroidApplicationProcess.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function androidApplicationProcessToNumber(object: AndroidApplicationProcess): number {
  switch (object) {
    case AndroidApplicationProcess.MAIN:
      return 0;
    case AndroidApplicationProcess.TICKET:
      return 2;
    case AndroidApplicationProcess.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ActivityManagerDump {
  process: AndroidApplicationProcess;
  ticketProcessExitInfo: ActivityManagerDump_ApplicationExitInfo | undefined;
  mainProcessExitInfo: ActivityManagerDump_ApplicationExitInfo | undefined;
  runningAppProcesses: ActivityManagerDump_RunningAppProcessInfo[];
  runningServices: ActivityManagerDump_RunningServiceInfo[];
  /** https://developer.android.com/reference/kotlin/android/app/ActivityManager#isbackgroundrestricted */
  largeMemoryClass: number;
  processesInErrorState: ActivityManagerDump_ProcessErrorStateInfo[];
  isBackgroundRestricted: boolean;
  isLowMemoryKillReportSupported: boolean;
  isLowRamDevice: boolean;
}

export interface ActivityManagerDump_ApplicationExitInfo {
  /** See https://developer.android.com/reference/kotlin/android/app/ApplicationExitInfo */
  description: string;
  importance: number;
  reason: ActivityManagerDump_ApplicationExitInfo_ExitReason;
  rss: number;
  status: number;
  timestamp: Timestamp | undefined;
}

export enum ActivityManagerDump_ApplicationExitInfo_ExitReason {
  REASON_UNKNOWN = "REASON_UNKNOWN",
  REASON_ANR = "REASON_ANR",
  /** REASON_CRASH - / Application process died because of an unhandled exception in Java code. */
  REASON_CRASH = "REASON_CRASH",
  /** REASON_CRASH_NATIVE - / Application process died because of a native code crash. */
  REASON_CRASH_NATIVE = "REASON_CRASH_NATIVE",
  /** REASON_DEPENDENCY_DIED - / Application process was killed because its dependency was going away, for example, a stable content provider connection's client will be killed if the provider is killed. */
  REASON_DEPENDENCY_DIED = "REASON_DEPENDENCY_DIED",
  /** REASON_EXCESSIVE_RESOURCE_USAGE - / Application process was killed by the system due to excessive resource usage. */
  REASON_EXCESSIVE_RESOURCE_USAGE = "REASON_EXCESSIVE_RESOURCE_USAGE",
  /**
   * REASON_EXIT_SELF - / Application process exit normally by itself, for example, via java.lang.System#exit; getStatus will specify the exit code.
   * / Applications should normally not do this, as the system has a better knowledge in terms of process management.
   */
  REASON_EXIT_SELF = "REASON_EXIT_SELF",
  /** REASON_FREEZER - / Application process was killed by App Freezer, for example, because it receives sync binder transactions while being frozen. */
  REASON_FREEZER = "REASON_FREEZER",
  /** REASON_INITIALIZATION_FAILURE - / Application process was killed because of initialization failure, for example, it took too long to attach to the system during the start, or there was an error during initialization. */
  REASON_INITIALIZATION_FAILURE = "REASON_INITIALIZATION_FAILURE",
  /**
   * REASON_LOW_MEMORY - Application process was killed by the system low memory killer, meaning the system was under memory pressure at the time of kill.
   * Not all devices support reporting REASON_LOW_MEMORY; on a device with no such support, when a process is killed due to memory pressure, the getReason will return REASON_SIGNALED and getStatus will return the value android.system.OsConstants#SIGKILL. Application should use ActivityManager.isLowMemoryKillReportSupported() to check if the device supports reporting REASON_LOW_MEMORY or not.
   */
  REASON_LOW_MEMORY = "REASON_LOW_MEMORY",
  /** REASON_OTHER - Application process was killed by the system for various other reasons which are not by problems in apps and not actionable by apps, for example, the system just finished updates; getDescription will specify the cause given by the system. */
  REASON_OTHER = "REASON_OTHER",
  /**
   * REASON_PACKAGE_STATE_CHANGE - Application process was killed because the app was disabled, or any of its component states have changed without android.content.pm.PackageManager#DONT_KILL_APP
   * Prior to android.os.Build.VERSION_CODES#UPSIDE_DOWN_CAKE, REASON_USER_REQUESTED was used to indicate that an app was updated.
   */
  REASON_PACKAGE_STATE_CHANGE = "REASON_PACKAGE_STATE_CHANGE",
  /**
   * REASON_PACKAGE_UPDATED - / Application process was killed because the app was disabled, or any of its component states have changed without android.content.pm.PackageManager#DONT_KILL_APP
   *
   * Prior to android.os.Build.VERSION_CODES#UPSIDE_DOWN_CAKE, REASON_USER_REQUESTED was used to indicate that an app was updated.
   */
  REASON_PACKAGE_UPDATED = "REASON_PACKAGE_UPDATED",
  /** REASON_PERMISSION_CHANGE - Application process was killed due to a runtime permission change. */
  REASON_PERMISSION_CHANGE = "REASON_PERMISSION_CHANGE",
  /** REASON_SIGNALED - Application process died due to the result of an OS signal; for example, android.system.OsConstants#SIGKILL; getStatus will specify the signal number. */
  REASON_SIGNALED = "REASON_SIGNALED",
  /** REASON_USER_REQUESTED - Application process was killed because of the user request, for example, user clicked the "Force stop" button of the application in the Settings, or removed the application away from Recents. */
  REASON_USER_REQUESTED = "REASON_USER_REQUESTED",
  /** REASON_USER_STOPPED - Application process was killed, because the user it is running as on devices with mutlple users, was stopped. */
  REASON_USER_STOPPED = "REASON_USER_STOPPED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function activityManagerDump_ApplicationExitInfo_ExitReasonFromJSON(
  object: any,
): ActivityManagerDump_ApplicationExitInfo_ExitReason {
  switch (object) {
    case 0:
    case "REASON_UNKNOWN":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN;
    case 6:
    case "REASON_ANR":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_ANR;
    case 4:
    case "REASON_CRASH":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_CRASH;
    case 5:
    case "REASON_CRASH_NATIVE":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_CRASH_NATIVE;
    case 12:
    case "REASON_DEPENDENCY_DIED":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_DEPENDENCY_DIED;
    case 9:
    case "REASON_EXCESSIVE_RESOURCE_USAGE":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_EXCESSIVE_RESOURCE_USAGE;
    case 1:
    case "REASON_EXIT_SELF":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_EXIT_SELF;
    case 14:
    case "REASON_FREEZER":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_FREEZER;
    case 7:
    case "REASON_INITIALIZATION_FAILURE":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_INITIALIZATION_FAILURE;
    case 3:
    case "REASON_LOW_MEMORY":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_LOW_MEMORY;
    case 13:
    case "REASON_OTHER":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_OTHER;
    case 15:
    case "REASON_PACKAGE_STATE_CHANGE":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PACKAGE_STATE_CHANGE;
    case 16:
    case "REASON_PACKAGE_UPDATED":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PACKAGE_UPDATED;
    case 8:
    case "REASON_PERMISSION_CHANGE":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PERMISSION_CHANGE;
    case 2:
    case "REASON_SIGNALED":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_SIGNALED;
    case 10:
    case "REASON_USER_REQUESTED":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_USER_REQUESTED;
    case 11:
    case "REASON_USER_STOPPED":
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_USER_STOPPED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActivityManagerDump_ApplicationExitInfo_ExitReason.UNRECOGNIZED;
  }
}

export function activityManagerDump_ApplicationExitInfo_ExitReasonToJSON(
  object: ActivityManagerDump_ApplicationExitInfo_ExitReason,
): string {
  switch (object) {
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN:
      return "REASON_UNKNOWN";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_ANR:
      return "REASON_ANR";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_CRASH:
      return "REASON_CRASH";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_CRASH_NATIVE:
      return "REASON_CRASH_NATIVE";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_DEPENDENCY_DIED:
      return "REASON_DEPENDENCY_DIED";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_EXCESSIVE_RESOURCE_USAGE:
      return "REASON_EXCESSIVE_RESOURCE_USAGE";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_EXIT_SELF:
      return "REASON_EXIT_SELF";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_FREEZER:
      return "REASON_FREEZER";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_INITIALIZATION_FAILURE:
      return "REASON_INITIALIZATION_FAILURE";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_LOW_MEMORY:
      return "REASON_LOW_MEMORY";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_OTHER:
      return "REASON_OTHER";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PACKAGE_STATE_CHANGE:
      return "REASON_PACKAGE_STATE_CHANGE";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PACKAGE_UPDATED:
      return "REASON_PACKAGE_UPDATED";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PERMISSION_CHANGE:
      return "REASON_PERMISSION_CHANGE";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_SIGNALED:
      return "REASON_SIGNALED";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_USER_REQUESTED:
      return "REASON_USER_REQUESTED";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_USER_STOPPED:
      return "REASON_USER_STOPPED";
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function activityManagerDump_ApplicationExitInfo_ExitReasonToNumber(
  object: ActivityManagerDump_ApplicationExitInfo_ExitReason,
): number {
  switch (object) {
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN:
      return 0;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_ANR:
      return 6;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_CRASH:
      return 4;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_CRASH_NATIVE:
      return 5;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_DEPENDENCY_DIED:
      return 12;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_EXCESSIVE_RESOURCE_USAGE:
      return 9;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_EXIT_SELF:
      return 1;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_FREEZER:
      return 14;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_INITIALIZATION_FAILURE:
      return 7;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_LOW_MEMORY:
      return 3;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_OTHER:
      return 13;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PACKAGE_STATE_CHANGE:
      return 15;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PACKAGE_UPDATED:
      return 16;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_PERMISSION_CHANGE:
      return 8;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_SIGNALED:
      return 2;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_USER_REQUESTED:
      return 10;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_USER_STOPPED:
      return 11;
    case ActivityManagerDump_ApplicationExitInfo_ExitReason.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** https://developer.android.com/reference/kotlin/android/app/ActivityManager.RunningAppProcessInfo */
export interface ActivityManagerDump_RunningAppProcessInfo {
  importance: number;
  importanceReasonCode: number;
  importanceReasonComponentName: string;
  lastTrimLevel: number;
  lru: number;
  pkgList: string[];
}

export interface ActivityManagerDump_ProcessErrorStateInfo {
  condition: number;
  longMsg: string;
  pid: number;
  processName: string;
  shortMsg: string;
  stackTrace: string;
  tag: string;
  uid: number;
}

export interface ActivityManagerDump_RunningServiceInfo {
  activeSince: Timestamp | undefined;
  clientCount: number;
  clientLabel: number;
  clientPackage: string;
  crashCount: number;
  flags: number;
  foreground: boolean;
  lastActivityTime: Timestamp | undefined;
  pid: number;
  process: string;
  /** If non-zero, this service is not currently running, but scheduled to restart at the given time. */
  restarting: number;
  service: string;
  started: boolean;
  uid: number;
}

function createBaseActivityManagerDump(): ActivityManagerDump {
  return {
    process: AndroidApplicationProcess.MAIN,
    ticketProcessExitInfo: undefined,
    mainProcessExitInfo: undefined,
    runningAppProcesses: [],
    runningServices: [],
    largeMemoryClass: 0,
    processesInErrorState: [],
    isBackgroundRestricted: false,
    isLowMemoryKillReportSupported: false,
    isLowRamDevice: false,
  };
}

export const ActivityManagerDump: MessageFns<ActivityManagerDump> = {
  encode(message: ActivityManagerDump, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.process !== AndroidApplicationProcess.MAIN) {
      writer.uint32(8).int32(androidApplicationProcessToNumber(message.process));
    }
    if (message.ticketProcessExitInfo !== undefined) {
      ActivityManagerDump_ApplicationExitInfo.encode(message.ticketProcessExitInfo, writer.uint32(18).fork()).join();
    }
    if (message.mainProcessExitInfo !== undefined) {
      ActivityManagerDump_ApplicationExitInfo.encode(message.mainProcessExitInfo, writer.uint32(26).fork()).join();
    }
    for (const v of message.runningAppProcesses) {
      ActivityManagerDump_RunningAppProcessInfo.encode(v!, writer.uint32(34).fork()).join();
    }
    for (const v of message.runningServices) {
      ActivityManagerDump_RunningServiceInfo.encode(v!, writer.uint32(98).fork()).join();
    }
    if (message.largeMemoryClass !== 0) {
      writer.uint32(48).int32(message.largeMemoryClass);
    }
    for (const v of message.processesInErrorState) {
      ActivityManagerDump_ProcessErrorStateInfo.encode(v!, writer.uint32(58).fork()).join();
    }
    if (message.isBackgroundRestricted !== false) {
      writer.uint32(64).bool(message.isBackgroundRestricted);
    }
    if (message.isLowMemoryKillReportSupported !== false) {
      writer.uint32(72).bool(message.isLowMemoryKillReportSupported);
    }
    if (message.isLowRamDevice !== false) {
      writer.uint32(80).bool(message.isLowRamDevice);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ActivityManagerDump {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityManagerDump();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.process = androidApplicationProcessFromJSON(reader.int32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.ticketProcessExitInfo = ActivityManagerDump_ApplicationExitInfo.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.mainProcessExitInfo = ActivityManagerDump_ApplicationExitInfo.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.runningAppProcesses.push(ActivityManagerDump_RunningAppProcessInfo.decode(reader, reader.uint32()));
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.runningServices.push(ActivityManagerDump_RunningServiceInfo.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.largeMemoryClass = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.processesInErrorState.push(ActivityManagerDump_ProcessErrorStateInfo.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.isBackgroundRestricted = reader.bool();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.isLowMemoryKillReportSupported = reader.bool();
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.isLowRamDevice = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivityManagerDump {
    return {
      process: isSet(object.process)
        ? androidApplicationProcessFromJSON(object.process)
        : AndroidApplicationProcess.MAIN,
      ticketProcessExitInfo: isSet(object.ticketProcessExitInfo)
        ? ActivityManagerDump_ApplicationExitInfo.fromJSON(object.ticketProcessExitInfo)
        : undefined,
      mainProcessExitInfo: isSet(object.mainProcessExitInfo)
        ? ActivityManagerDump_ApplicationExitInfo.fromJSON(object.mainProcessExitInfo)
        : undefined,
      runningAppProcesses: globalThis.Array.isArray(object?.runningAppProcesses)
        ? object.runningAppProcesses.map((e: any) => ActivityManagerDump_RunningAppProcessInfo.fromJSON(e))
        : [],
      runningServices: globalThis.Array.isArray(object?.runningServices)
        ? object.runningServices.map((e: any) => ActivityManagerDump_RunningServiceInfo.fromJSON(e))
        : [],
      largeMemoryClass: isSet(object.largeMemoryClass) ? globalThis.Number(object.largeMemoryClass) : 0,
      processesInErrorState: globalThis.Array.isArray(object?.processesInErrorState)
        ? object.processesInErrorState.map((e: any) => ActivityManagerDump_ProcessErrorStateInfo.fromJSON(e))
        : [],
      isBackgroundRestricted: isSet(object.isBackgroundRestricted)
        ? globalThis.Boolean(object.isBackgroundRestricted)
        : false,
      isLowMemoryKillReportSupported: isSet(object.isLowMemoryKillReportSupported)
        ? globalThis.Boolean(object.isLowMemoryKillReportSupported)
        : false,
      isLowRamDevice: isSet(object.isLowRamDevice) ? globalThis.Boolean(object.isLowRamDevice) : false,
    };
  },

  toJSON(message: ActivityManagerDump): unknown {
    const obj: any = {};
    if (message.process !== AndroidApplicationProcess.MAIN) {
      obj.process = androidApplicationProcessToJSON(message.process);
    }
    if (message.ticketProcessExitInfo !== undefined) {
      obj.ticketProcessExitInfo = ActivityManagerDump_ApplicationExitInfo.toJSON(message.ticketProcessExitInfo);
    }
    if (message.mainProcessExitInfo !== undefined) {
      obj.mainProcessExitInfo = ActivityManagerDump_ApplicationExitInfo.toJSON(message.mainProcessExitInfo);
    }
    if (message.runningAppProcesses?.length) {
      obj.runningAppProcesses = message.runningAppProcesses.map((e) =>
        ActivityManagerDump_RunningAppProcessInfo.toJSON(e)
      );
    }
    if (message.runningServices?.length) {
      obj.runningServices = message.runningServices.map((e) => ActivityManagerDump_RunningServiceInfo.toJSON(e));
    }
    if (message.largeMemoryClass !== 0) {
      obj.largeMemoryClass = Math.round(message.largeMemoryClass);
    }
    if (message.processesInErrorState?.length) {
      obj.processesInErrorState = message.processesInErrorState.map((e) =>
        ActivityManagerDump_ProcessErrorStateInfo.toJSON(e)
      );
    }
    if (message.isBackgroundRestricted !== false) {
      obj.isBackgroundRestricted = message.isBackgroundRestricted;
    }
    if (message.isLowMemoryKillReportSupported !== false) {
      obj.isLowMemoryKillReportSupported = message.isLowMemoryKillReportSupported;
    }
    if (message.isLowRamDevice !== false) {
      obj.isLowRamDevice = message.isLowRamDevice;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivityManagerDump>, I>>(base?: I): ActivityManagerDump {
    return ActivityManagerDump.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivityManagerDump>, I>>(object: I): ActivityManagerDump {
    const message = createBaseActivityManagerDump();
    message.process = object.process ?? AndroidApplicationProcess.MAIN;
    message.ticketProcessExitInfo =
      (object.ticketProcessExitInfo !== undefined && object.ticketProcessExitInfo !== null)
        ? ActivityManagerDump_ApplicationExitInfo.fromPartial(object.ticketProcessExitInfo)
        : undefined;
    message.mainProcessExitInfo = (object.mainProcessExitInfo !== undefined && object.mainProcessExitInfo !== null)
      ? ActivityManagerDump_ApplicationExitInfo.fromPartial(object.mainProcessExitInfo)
      : undefined;
    message.runningAppProcesses =
      object.runningAppProcesses?.map((e) => ActivityManagerDump_RunningAppProcessInfo.fromPartial(e)) || [];
    message.runningServices =
      object.runningServices?.map((e) => ActivityManagerDump_RunningServiceInfo.fromPartial(e)) || [];
    message.largeMemoryClass = object.largeMemoryClass ?? 0;
    message.processesInErrorState =
      object.processesInErrorState?.map((e) => ActivityManagerDump_ProcessErrorStateInfo.fromPartial(e)) || [];
    message.isBackgroundRestricted = object.isBackgroundRestricted ?? false;
    message.isLowMemoryKillReportSupported = object.isLowMemoryKillReportSupported ?? false;
    message.isLowRamDevice = object.isLowRamDevice ?? false;
    return message;
  },
};

function createBaseActivityManagerDump_ApplicationExitInfo(): ActivityManagerDump_ApplicationExitInfo {
  return {
    description: "",
    importance: 0,
    reason: ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN,
    rss: 0,
    status: 0,
    timestamp: undefined,
  };
}

export const ActivityManagerDump_ApplicationExitInfo: MessageFns<ActivityManagerDump_ApplicationExitInfo> = {
  encode(message: ActivityManagerDump_ApplicationExitInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.importance !== 0) {
      writer.uint32(16).int32(message.importance);
    }
    if (message.reason !== ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN) {
      writer.uint32(56).int32(activityManagerDump_ApplicationExitInfo_ExitReasonToNumber(message.reason));
    }
    if (message.rss !== 0) {
      writer.uint32(24).int64(message.rss);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(message.timestamp, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ActivityManagerDump_ApplicationExitInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityManagerDump_ApplicationExitInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.importance = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.reason = activityManagerDump_ApplicationExitInfo_ExitReasonFromJSON(reader.int32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.rss = longToNumber(reader.int64());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.timestamp = Timestamp.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivityManagerDump_ApplicationExitInfo {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      importance: isSet(object.importance) ? globalThis.Number(object.importance) : 0,
      reason: isSet(object.reason)
        ? activityManagerDump_ApplicationExitInfo_ExitReasonFromJSON(object.reason)
        : ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN,
      rss: isSet(object.rss) ? globalThis.Number(object.rss) : 0,
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      timestamp: isSet(object.timestamp) ? Timestamp.fromJSON(object.timestamp) : undefined,
    };
  },

  toJSON(message: ActivityManagerDump_ApplicationExitInfo): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.importance !== 0) {
      obj.importance = Math.round(message.importance);
    }
    if (message.reason !== ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN) {
      obj.reason = activityManagerDump_ApplicationExitInfo_ExitReasonToJSON(message.reason);
    }
    if (message.rss !== 0) {
      obj.rss = Math.round(message.rss);
    }
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = Timestamp.toJSON(message.timestamp);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivityManagerDump_ApplicationExitInfo>, I>>(
    base?: I,
  ): ActivityManagerDump_ApplicationExitInfo {
    return ActivityManagerDump_ApplicationExitInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivityManagerDump_ApplicationExitInfo>, I>>(
    object: I,
  ): ActivityManagerDump_ApplicationExitInfo {
    const message = createBaseActivityManagerDump_ApplicationExitInfo();
    message.description = object.description ?? "";
    message.importance = object.importance ?? 0;
    message.reason = object.reason ?? ActivityManagerDump_ApplicationExitInfo_ExitReason.REASON_UNKNOWN;
    message.rss = object.rss ?? 0;
    message.status = object.status ?? 0;
    message.timestamp = (object.timestamp !== undefined && object.timestamp !== null)
      ? Timestamp.fromPartial(object.timestamp)
      : undefined;
    return message;
  },
};

function createBaseActivityManagerDump_RunningAppProcessInfo(): ActivityManagerDump_RunningAppProcessInfo {
  return {
    importance: 0,
    importanceReasonCode: 0,
    importanceReasonComponentName: "",
    lastTrimLevel: 0,
    lru: 0,
    pkgList: [],
  };
}

export const ActivityManagerDump_RunningAppProcessInfo: MessageFns<ActivityManagerDump_RunningAppProcessInfo> = {
  encode(message: ActivityManagerDump_RunningAppProcessInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.importance !== 0) {
      writer.uint32(8).int32(message.importance);
    }
    if (message.importanceReasonCode !== 0) {
      writer.uint32(16).int32(message.importanceReasonCode);
    }
    if (message.importanceReasonComponentName !== "") {
      writer.uint32(26).string(message.importanceReasonComponentName);
    }
    if (message.lastTrimLevel !== 0) {
      writer.uint32(32).int32(message.lastTrimLevel);
    }
    if (message.lru !== 0) {
      writer.uint32(40).int32(message.lru);
    }
    for (const v of message.pkgList) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ActivityManagerDump_RunningAppProcessInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityManagerDump_RunningAppProcessInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.importance = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.importanceReasonCode = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.importanceReasonComponentName = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.lastTrimLevel = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.lru = reader.int32();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.pkgList.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivityManagerDump_RunningAppProcessInfo {
    return {
      importance: isSet(object.importance) ? globalThis.Number(object.importance) : 0,
      importanceReasonCode: isSet(object.importanceReasonCode) ? globalThis.Number(object.importanceReasonCode) : 0,
      importanceReasonComponentName: isSet(object.importanceReasonComponentName)
        ? globalThis.String(object.importanceReasonComponentName)
        : "",
      lastTrimLevel: isSet(object.lastTrimLevel) ? globalThis.Number(object.lastTrimLevel) : 0,
      lru: isSet(object.lru) ? globalThis.Number(object.lru) : 0,
      pkgList: globalThis.Array.isArray(object?.pkgList) ? object.pkgList.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ActivityManagerDump_RunningAppProcessInfo): unknown {
    const obj: any = {};
    if (message.importance !== 0) {
      obj.importance = Math.round(message.importance);
    }
    if (message.importanceReasonCode !== 0) {
      obj.importanceReasonCode = Math.round(message.importanceReasonCode);
    }
    if (message.importanceReasonComponentName !== "") {
      obj.importanceReasonComponentName = message.importanceReasonComponentName;
    }
    if (message.lastTrimLevel !== 0) {
      obj.lastTrimLevel = Math.round(message.lastTrimLevel);
    }
    if (message.lru !== 0) {
      obj.lru = Math.round(message.lru);
    }
    if (message.pkgList?.length) {
      obj.pkgList = message.pkgList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivityManagerDump_RunningAppProcessInfo>, I>>(
    base?: I,
  ): ActivityManagerDump_RunningAppProcessInfo {
    return ActivityManagerDump_RunningAppProcessInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivityManagerDump_RunningAppProcessInfo>, I>>(
    object: I,
  ): ActivityManagerDump_RunningAppProcessInfo {
    const message = createBaseActivityManagerDump_RunningAppProcessInfo();
    message.importance = object.importance ?? 0;
    message.importanceReasonCode = object.importanceReasonCode ?? 0;
    message.importanceReasonComponentName = object.importanceReasonComponentName ?? "";
    message.lastTrimLevel = object.lastTrimLevel ?? 0;
    message.lru = object.lru ?? 0;
    message.pkgList = object.pkgList?.map((e) => e) || [];
    return message;
  },
};

function createBaseActivityManagerDump_ProcessErrorStateInfo(): ActivityManagerDump_ProcessErrorStateInfo {
  return { condition: 0, longMsg: "", pid: 0, processName: "", shortMsg: "", stackTrace: "", tag: "", uid: 0 };
}

export const ActivityManagerDump_ProcessErrorStateInfo: MessageFns<ActivityManagerDump_ProcessErrorStateInfo> = {
  encode(message: ActivityManagerDump_ProcessErrorStateInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.condition !== 0) {
      writer.uint32(8).int32(message.condition);
    }
    if (message.longMsg !== "") {
      writer.uint32(18).string(message.longMsg);
    }
    if (message.pid !== 0) {
      writer.uint32(24).int32(message.pid);
    }
    if (message.processName !== "") {
      writer.uint32(42).string(message.processName);
    }
    if (message.shortMsg !== "") {
      writer.uint32(50).string(message.shortMsg);
    }
    if (message.stackTrace !== "") {
      writer.uint32(58).string(message.stackTrace);
    }
    if (message.tag !== "") {
      writer.uint32(66).string(message.tag);
    }
    if (message.uid !== 0) {
      writer.uint32(72).uint32(message.uid);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ActivityManagerDump_ProcessErrorStateInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityManagerDump_ProcessErrorStateInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.condition = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.longMsg = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.pid = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.processName = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.shortMsg = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.stackTrace = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.tag = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.uid = reader.uint32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivityManagerDump_ProcessErrorStateInfo {
    return {
      condition: isSet(object.condition) ? globalThis.Number(object.condition) : 0,
      longMsg: isSet(object.longMsg) ? globalThis.String(object.longMsg) : "",
      pid: isSet(object.pid) ? globalThis.Number(object.pid) : 0,
      processName: isSet(object.processName) ? globalThis.String(object.processName) : "",
      shortMsg: isSet(object.shortMsg) ? globalThis.String(object.shortMsg) : "",
      stackTrace: isSet(object.stackTrace) ? globalThis.String(object.stackTrace) : "",
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      uid: isSet(object.uid) ? globalThis.Number(object.uid) : 0,
    };
  },

  toJSON(message: ActivityManagerDump_ProcessErrorStateInfo): unknown {
    const obj: any = {};
    if (message.condition !== 0) {
      obj.condition = Math.round(message.condition);
    }
    if (message.longMsg !== "") {
      obj.longMsg = message.longMsg;
    }
    if (message.pid !== 0) {
      obj.pid = Math.round(message.pid);
    }
    if (message.processName !== "") {
      obj.processName = message.processName;
    }
    if (message.shortMsg !== "") {
      obj.shortMsg = message.shortMsg;
    }
    if (message.stackTrace !== "") {
      obj.stackTrace = message.stackTrace;
    }
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.uid !== 0) {
      obj.uid = Math.round(message.uid);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivityManagerDump_ProcessErrorStateInfo>, I>>(
    base?: I,
  ): ActivityManagerDump_ProcessErrorStateInfo {
    return ActivityManagerDump_ProcessErrorStateInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivityManagerDump_ProcessErrorStateInfo>, I>>(
    object: I,
  ): ActivityManagerDump_ProcessErrorStateInfo {
    const message = createBaseActivityManagerDump_ProcessErrorStateInfo();
    message.condition = object.condition ?? 0;
    message.longMsg = object.longMsg ?? "";
    message.pid = object.pid ?? 0;
    message.processName = object.processName ?? "";
    message.shortMsg = object.shortMsg ?? "";
    message.stackTrace = object.stackTrace ?? "";
    message.tag = object.tag ?? "";
    message.uid = object.uid ?? 0;
    return message;
  },
};

function createBaseActivityManagerDump_RunningServiceInfo(): ActivityManagerDump_RunningServiceInfo {
  return {
    activeSince: undefined,
    clientCount: 0,
    clientLabel: 0,
    clientPackage: "",
    crashCount: 0,
    flags: 0,
    foreground: false,
    lastActivityTime: undefined,
    pid: 0,
    process: "",
    restarting: 0,
    service: "",
    started: false,
    uid: 0,
  };
}

export const ActivityManagerDump_RunningServiceInfo: MessageFns<ActivityManagerDump_RunningServiceInfo> = {
  encode(message: ActivityManagerDump_RunningServiceInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.activeSince !== undefined) {
      Timestamp.encode(message.activeSince, writer.uint32(10).fork()).join();
    }
    if (message.clientCount !== 0) {
      writer.uint32(16).int32(message.clientCount);
    }
    if (message.clientLabel !== 0) {
      writer.uint32(24).int32(message.clientLabel);
    }
    if (message.clientPackage !== "") {
      writer.uint32(34).string(message.clientPackage);
    }
    if (message.crashCount !== 0) {
      writer.uint32(40).int32(message.crashCount);
    }
    if (message.flags !== 0) {
      writer.uint32(48).int32(message.flags);
    }
    if (message.foreground !== false) {
      writer.uint32(56).bool(message.foreground);
    }
    if (message.lastActivityTime !== undefined) {
      Timestamp.encode(message.lastActivityTime, writer.uint32(66).fork()).join();
    }
    if (message.pid !== 0) {
      writer.uint32(72).int32(message.pid);
    }
    if (message.process !== "") {
      writer.uint32(82).string(message.process);
    }
    if (message.restarting !== 0) {
      writer.uint32(88).int64(message.restarting);
    }
    if (message.service !== "") {
      writer.uint32(98).string(message.service);
    }
    if (message.started !== false) {
      writer.uint32(104).bool(message.started);
    }
    if (message.uid !== 0) {
      writer.uint32(112).int32(message.uid);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ActivityManagerDump_RunningServiceInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityManagerDump_RunningServiceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.activeSince = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.clientCount = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.clientLabel = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.clientPackage = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.crashCount = reader.int32();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.flags = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.foreground = reader.bool();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.lastActivityTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.pid = reader.int32();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.process = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.restarting = longToNumber(reader.int64());
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.service = reader.string();
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.started = reader.bool();
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.uid = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivityManagerDump_RunningServiceInfo {
    return {
      activeSince: isSet(object.activeSince) ? Timestamp.fromJSON(object.activeSince) : undefined,
      clientCount: isSet(object.clientCount) ? globalThis.Number(object.clientCount) : 0,
      clientLabel: isSet(object.clientLabel) ? globalThis.Number(object.clientLabel) : 0,
      clientPackage: isSet(object.clientPackage) ? globalThis.String(object.clientPackage) : "",
      crashCount: isSet(object.crashCount) ? globalThis.Number(object.crashCount) : 0,
      flags: isSet(object.flags) ? globalThis.Number(object.flags) : 0,
      foreground: isSet(object.foreground) ? globalThis.Boolean(object.foreground) : false,
      lastActivityTime: isSet(object.lastActivityTime) ? Timestamp.fromJSON(object.lastActivityTime) : undefined,
      pid: isSet(object.pid) ? globalThis.Number(object.pid) : 0,
      process: isSet(object.process) ? globalThis.String(object.process) : "",
      restarting: isSet(object.restarting) ? globalThis.Number(object.restarting) : 0,
      service: isSet(object.service) ? globalThis.String(object.service) : "",
      started: isSet(object.started) ? globalThis.Boolean(object.started) : false,
      uid: isSet(object.uid) ? globalThis.Number(object.uid) : 0,
    };
  },

  toJSON(message: ActivityManagerDump_RunningServiceInfo): unknown {
    const obj: any = {};
    if (message.activeSince !== undefined) {
      obj.activeSince = Timestamp.toJSON(message.activeSince);
    }
    if (message.clientCount !== 0) {
      obj.clientCount = Math.round(message.clientCount);
    }
    if (message.clientLabel !== 0) {
      obj.clientLabel = Math.round(message.clientLabel);
    }
    if (message.clientPackage !== "") {
      obj.clientPackage = message.clientPackage;
    }
    if (message.crashCount !== 0) {
      obj.crashCount = Math.round(message.crashCount);
    }
    if (message.flags !== 0) {
      obj.flags = Math.round(message.flags);
    }
    if (message.foreground !== false) {
      obj.foreground = message.foreground;
    }
    if (message.lastActivityTime !== undefined) {
      obj.lastActivityTime = Timestamp.toJSON(message.lastActivityTime);
    }
    if (message.pid !== 0) {
      obj.pid = Math.round(message.pid);
    }
    if (message.process !== "") {
      obj.process = message.process;
    }
    if (message.restarting !== 0) {
      obj.restarting = Math.round(message.restarting);
    }
    if (message.service !== "") {
      obj.service = message.service;
    }
    if (message.started !== false) {
      obj.started = message.started;
    }
    if (message.uid !== 0) {
      obj.uid = Math.round(message.uid);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivityManagerDump_RunningServiceInfo>, I>>(
    base?: I,
  ): ActivityManagerDump_RunningServiceInfo {
    return ActivityManagerDump_RunningServiceInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivityManagerDump_RunningServiceInfo>, I>>(
    object: I,
  ): ActivityManagerDump_RunningServiceInfo {
    const message = createBaseActivityManagerDump_RunningServiceInfo();
    message.activeSince = (object.activeSince !== undefined && object.activeSince !== null)
      ? Timestamp.fromPartial(object.activeSince)
      : undefined;
    message.clientCount = object.clientCount ?? 0;
    message.clientLabel = object.clientLabel ?? 0;
    message.clientPackage = object.clientPackage ?? "";
    message.crashCount = object.crashCount ?? 0;
    message.flags = object.flags ?? 0;
    message.foreground = object.foreground ?? false;
    message.lastActivityTime = (object.lastActivityTime !== undefined && object.lastActivityTime !== null)
      ? Timestamp.fromPartial(object.lastActivityTime)
      : undefined;
    message.pid = object.pid ?? 0;
    message.process = object.process ?? "";
    message.restarting = object.restarting ?? 0;
    message.service = object.service ?? "";
    message.started = object.started ?? false;
    message.uid = object.uid ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
