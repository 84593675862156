import {
    LocalPriceFragment,
    StopTimeLiteFragment,
    TicketPriceSource,
    TicketRowFragment,
} from "../../generated/gql/graphql";
import React from "react";
import {
    Button,
    Icon,
    IconButton,
    Link as MaterialLink,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { RelativeTimeText, TimestampText } from "../../widgets/date_time";
import { formatMoney } from "../NetsTransactionsListPage";
import { sortBy, sumBy } from "lodash";
import { Link } from "react-router-dom";
import { buildFlagIcon } from "../FrontPage";
import { DiagnosticButton, JsonDiagnosticProperty } from "../../widgets/diagnostics";
import { Property, PropertyList } from "./TicketPageHeader";
import { formatPercentage } from "../../util/formatting";
import { isLearningEnvironment, isSuperUser } from "../../util/active_user_context";
import { LegEditorModal } from "../ticket/LegEditorModal";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/react-hooks";
import { green, orange, red } from "@mui/material/colors";
import { Property as CSSProperty } from "csstype";
import { Ticket, useTicketPageV2ContextOrNull } from "./context";
import { CheckCircleIcon, DangerousIcon, DeleteIcon, TransferWithinAStationIcon } from "../../widgets/icons";

type Invoice = Ticket["invoices"][0];
type Transaction = Invoice["delegatedTransactions"][0] & {};
type TransactionCredit = Invoice["transactionCredit"] & {};

type TicketInfoTabProps = {
    ticket: Ticket;
};

function formatDistanceKm(distance: number): String {
    return (distance / 1000).toFixed(1) + " km";
}

export function TicketInfoTab({ ticket }: TicketInfoTabProps) {
    const localPrices = sortBy(ticket.analysisLocalPrice, (l: LocalPriceFragment) => {
        if (l.active) {
            return "2200";
        } else {
            return l.createdTime;
        }
    }).reverse();

    return (
        <div>
            {localPrices.map((l) => {
                const localPrice = l as LocalPriceFragment;

                return <TicketLocalPriceSection key={localPrice.id} ticket={ticket} localPrice={localPrice} />;
            })}
            <InvoiceTransactionLog ticket={ticket} />
        </div>
    );
}

function TicketPriceSourceBadge(props: { source: TicketPriceSource }) {
    if (!isSuperUser()) return null;
    let label: String;
    let color: CSSProperty.BackgroundColor = green[500];

    switch (props.source) {
        case TicketPriceSource.App:
            return;
        case TicketPriceSource.LearningAnalysis:
            label = "Server Analysis";
            break;
        case TicketPriceSource.Complaint:
            label = "Klage korrigering";
            break;
        case TicketPriceSource.LearningData:
            label = "Manuell læringsdata";
            color = orange[800];
            break;
        case TicketPriceSource.ServerTimeout:
            color = red[500];
            label = "Server timeout";
            break;
        case TicketPriceSource.Legacy:
            color = red[500];
            label = "Legacy";
            break;
        case TicketPriceSource.PaymentIgnoredCorrection:
            color = red[500];
            label = "PaymentIgnoredCorrection";
            break;
    }
    return (
        <Button variant="contained" disabled style={{ color: "#FFF", backgroundColor: color, margin: "0 10px" }}>
            {label}
        </Button>
    );
}

export function TicketLocalPriceSection({ ticket, localPrice }: { ticket: Ticket; localPrice: LocalPriceFragment }) {
    return (
        <div key={localPrice.id} style={{ marginBottom: "30px" }}>
            <div style={{ display: "flex" }}>
                <Typography
                    variant={"h6"}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: localPrice.active ? "" : "line-through",
                    }}
                >
                    <div>
                        Billett-pris <RelativeTimeText value={localPrice.createdTime} relativeTo={ticket.createdTime} />
                    </div>
                    <TicketPriceSourceBadge source={localPrice.source} />
                    {localPrice.active ? null : (
                        <Tooltip title={"Denne billettprisen er ikke aktiv"}>
                            <Button color={"error"} startIcon={<DeleteIcon />} disabled style={{ color: red[600] }}>
                                Utdatert
                            </Button>
                        </Tooltip>
                    )}
                    <DiagnosticButton>
                        <JsonDiagnosticProperty name={"ticket.analysisLocalPrice[]"} value={localPrice} />
                        <JsonDiagnosticProperty
                            name={"localPrice.debugOrderSummaryJsonString"}
                            json={localPrice.debugOrderSummaryJsonString}
                        />
                        <JsonDiagnosticProperty
                            name={"localPrice.debugTicketAnalysisResultJsonString"}
                            json={localPrice.debugTicketAnalysisResultJsonString}
                        />
                    </DiagnosticButton>
                </Typography>
                <div style={{ flex: "1" }} />
            </div>
            <PropertyList>
                <Property
                    requiresSuperUser
                    title={"AnalysisConfidence"}
                    value={formatPercentage(localPrice.analysisConfidence)}
                />
            </PropertyList>
            <TicketLocalLegsTable ticket={ticket} localPrice={localPrice} />
        </div>
    );
}

const DELETE_LEG_MUTATION = gql`
    mutation DeleteLeg($legId: Int!) {
        deleteTicketLeg(id: $legId)
    }
`;

export function TicketLocalLegsTable({
    ticket,
    localPrice,
}: {
    ticket: Ticket | TicketRowFragment;
    localPrice: LocalPriceFragment;
}) {
    const context = useTicketPageV2ContextOrNull();
    const [modal, setModal] = React.useState<React.ReactNode | null>(null);
    const showPriceExcludingAddons =
        ticket.passengerTypeInfo.addonBicycle > 0 || ticket.passengerTypeInfo.passengerTypeAddons.length > 0;
    const legs = sortBy(localPrice.legs, "startedTime");
    const mutation = useMutation(DELETE_LEG_MUTATION, { variables: {} });

    if (legs.length == 0) return <Typography>Ingen reise-legg detektert.</Typography>;

    const showEditActions = isLearningEnvironment() && context !== null;
    const showBillingColumns = !isLearningEnvironment();
    const showDescriptionColumns = legs.every((l) => !!l.description);

    return (
        <Table style={{ width: showDescriptionColumns ? "100%" : "max-content", marginBottom: "20px" }}>
            <TableHead>
                <TableRow>
                    {showDescriptionColumns ? (
                        [
                            <TableCell align={"right"} style={{ width: "50px" }}>
                                Linje
                            </TableCell>,
                            <TableCell>Destinasjon</TableCell>,
                            <TableCell>Ombord</TableCell>,
                            <TableCell>Avstigning</TableCell>,
                            <TableCell>Trip-Id</TableCell>,
                            <TableCell style={{ width: "70px" }}>Vogn-nr</TableCell>,
                        ]
                    ) : (
                        <TableCell>Ombord</TableCell>
                    )}
                    <TableCell align={"right"}>Reiselengde</TableCell>
                    {showBillingColumns
                        ? [
                              <TableCell>Transportmiddel</TableCell>,
                              <TableCell>Sone</TableCell>,

                              showPriceExcludingAddons ? (
                                  <TableCell align={"right"}>Rabattert pris (eksl. medpassasjerer/sykler)</TableCell>
                              ) : null,
                              <TableCell colSpan={2} align={"right"}>
                                  Pris
                              </TableCell>,
                              <TableCell>Prisgaranti</TableCell>,
                          ]
                        : null}
                    <TableCell align={"right"}>%</TableCell>
                    <TableCell></TableCell>
                    {showEditActions ? <TableCell></TableCell> : null}
                </TableRow>
            </TableHead>
            <TableBody>
                {legs.map((leg) => {
                    const d = leg.description;
                    return (
                        <TableRow key={leg.id}>
                            {showDescriptionColumns ? (
                                [
                                    <TableCell align={"right"}>{d?.journey?.line?.publicCode}</TableCell>,
                                    <TableCell>{d?.journey?.destination}</TableCell>,
                                    <TableCell>
                                        <StopTimeCell
                                            ticket={ticket}
                                            legTime={leg.startedTime}
                                            stopTime={d!.fromStopTime as StopTimeLiteFragment}
                                        />
                                    </TableCell>,
                                    <TableCell>
                                        <StopTimeCell
                                            ticket={ticket}
                                            legTime={d!.endedTime}
                                            stopTime={d!.toStopTime as StopTimeLiteFragment}
                                        />
                                    </TableCell>,
                                    <TableCell>{d?.journey?.tripId || d?.journey?.serviceJourneyId}</TableCell>,
                                    <TableCell>{d?.vehicleId}</TableCell>,
                                ]
                            ) : (
                                <TableCell>
                                    <RelativeTimeText value={leg.startedTime} relativeTo={ticket.createdTime} />
                                </TableCell>
                            )}
                            <TableCell align={"right"}>
                                {leg.distance > 0 ? formatDistanceKm(leg.distance) : "Krever 4.25+"}
                            </TableCell>
                            {showBillingColumns
                                ? [
                                      <TableCell>{leg.billingTransportModeName || "Krever 4.25+"}</TableCell>,
                                      <TableCell>{leg.zoneSpecString}</TableCell>,
                                      showPriceExcludingAddons ? (
                                          <TableCell align={"right"}>
                                              {formatMoney(leg.discountedPriceExcludingAddonsIncludingVat)}
                                          </TableCell>
                                      ) : null,
                                      <TableCell
                                          align={"right"}
                                          style={{
                                              textDecoration: "line-through",
                                              textAlign: "right",
                                              paddingRight: "0",
                                          }}
                                      >
                                          {formatMoney(leg.undiscountedPriceIncludingVat)}
                                      </TableCell>,
                                      <TableCell align={"right"} style={{ textAlign: "right", paddingLeft: "0" }}>
                                          {formatMoney(leg.discountedPriceIncludingVat)}
                                      </TableCell>,
                                      <TableCell>
                                          {leg.billingTransportMode ? leg.priceGuaranteeProductName : "Krever 4.25+"}
                                      </TableCell>,
                                  ]
                                : null}
                            <TableCell align={"right"}>{formatPercentage(leg.analysisConfidence)}</TableCell>
                            <TableCell padding={"none"} style={{ padding: "none" }}>
                                {buildFlagIcon(
                                    leg.isTransfer,
                                    <TransferWithinAStationIcon />,
                                    "Reisen har overgangsprising",
                                )}
                            </TableCell>
                            {showEditActions ? (
                                <TableCell align={"right"} padding="none" style={{ width: "68px" }}>
                                    <IconButton
                                        onClick={() =>
                                            setModal(
                                                <LegEditorModal
                                                    leg={{
                                                        id: leg.id,
                                                        journey: leg.description!.journey,
                                                        fromStopTimeIndex: (leg.description!
                                                            .fromStopTime as StopTimeLiteFragment)!.stopTimeIndex,
                                                        toStopTimeIndex: (leg.description!
                                                            .toStopTime as StopTimeLiteFragment)!.stopTimeIndex,
                                                        startedTime: leg.startedTime,
                                                        endedTime: leg.description!.endedTime,
                                                        vehicleId: leg.description!.vehicleId,
                                                    }}
                                                    onClose={() => {
                                                        setModal(null);
                                                    }}
                                                    onSave={() =>
                                                        context!.handleRefreshData().then(() => setModal(null))
                                                    }
                                                />,
                                            )
                                        }
                                    >
                                        <Icon>edit</Icon>
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            mutation[0]({ variables: { legId: leg.id } }).then(() =>
                                                context!.handleRefreshData(),
                                            )
                                        }
                                    >
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </TableCell>
                            ) : null}
                        </TableRow>
                    );
                })}
            </TableBody>
            <TableFooter>
                {showBillingColumns ? (
                    <TableRow>
                        <TableCell colSpan={(showDescriptionColumns ? 6 : 1) + 1 + 2} align={"right"}>
                            Sum:
                        </TableCell>
                        {showPriceExcludingAddons ? (
                            <TableCell align={"right"}>
                                {formatMoney(localPrice.discountedPriceExcludingAddonsIncludingVat)}
                            </TableCell>
                        ) : null}
                        <TableCell align={"right"} style={{ textDecoration: "line-through", paddingRight: "0" }}>
                            {formatMoney(localPrice.undiscountedPriceIncludingVat)}
                        </TableCell>
                        <TableCell style={{ textAlign: "right", paddingLeft: "0" }}>
                            {formatMoney(localPrice.discountedPriceIncludingVat)}
                        </TableCell>
                        <TableCell colSpan={showEditActions ? 4 : 3} />
                    </TableRow>
                ) : null}
            </TableFooter>
            {modal}
        </Table>
    );
}

function StopTimeCell(props: {
    ticket: Ticket | TicketRowFragment;
    legTime: string | null | undefined;
    stopTime: StopTimeLiteFragment | null | undefined;
}) {
    return (
        <>
            <RelativeTimeText includeSeconds value={props.legTime} relativeTo={props.ticket.createdTime} />
            {props.stopTime ? (
                <>
                    <br />
                    {isSuperUser() ? props.stopTime.stopTimeIndex + ". " : ""}
                    {props.stopTime.platform.name}
                </>
            ) : null}
        </>
    );
}

function InvoiceTransactionLog({ ticket }: { ticket: Ticket }) {
    if (isLearningEnvironment()) return null;
    const children: Array<React.ReactNode> = [];

    let totalBalance = 0;
    let totalPaid = 0;

    const transactionIds = new Set<any>();

    const invoicesReversed = [...ticket.invoices];
    invoicesReversed.reverse();
    invoicesReversed.forEach((invoice) => {
        invoice.delegatedTransactions.forEach((transaction) => {
            if (!transactionIds.has(transaction.id)) {
                children.push(<TransactionRow key={transaction.id} ticket={ticket} transaction={transaction} />);
                transactionIds.add(transaction.id);
            }
            if (transaction.processedSuccessfullyTime && invoice.transaction?.id === transaction.id)
                totalPaid += invoice.totalAmount;
        });

        const transactionCredit = invoice.transactionCredit;
        if (transactionCredit) {
            const key = "TC-" + transactionCredit.id;
            if (!transactionIds.has(key)) {
                children.push(
                    <TransactionCreditRow key={transactionCredit.id} ticket={ticket} credit={transactionCredit} />,
                );

                transactionIds.add(key);
            }
            if (transactionCredit.processedSuccessfullyTime) totalPaid += invoice.totalAmount;
        }

        children.push(<InvoiceRow key={invoice.invoiceNumber} invoice={invoice} />);
        totalBalance += invoice.totalAmount;
    });

    children.reverse();

    return (
        <div>
            <Typography variant={"h6"}>Betalingshistorikk</Typography>
            <Table style={{ maxWidth: "1000px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Tid</TableCell>
                        <TableCell>Beskrivelse</TableCell>
                        <TableCell>Nets respons</TableCell>
                        <TableCell style={{ width: "140px" }}></TableCell>
                        <TableCell align={"right"} style={{ width: "100px" }}>
                            Billettpris
                        </TableCell>
                        <TableCell align={"right"} style={{ width: "100px" }}>
                            Betalt
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
                <TableFooter>
                    <SumRow totalInvoiceAmount={totalBalance} totalPaid={totalPaid} />
                </TableFooter>
            </Table>
        </div>
    );
}

function InvoiceRow({ invoice }: { invoice: Invoice }) {
    return (
        <TableRow>
            <TableCell>
                <TimestampText value={invoice.createdTime} />
            </TableCell>
            <TableCell>{invoice.descriptionNorwegian}</TableCell>
            <TableCell colSpan={2}></TableCell>
            <TableCell align={"right"}>{formatMoney(invoice.totalAmount)}</TableCell>
            <TableCell></TableCell>
        </TableRow>
    );
}

function TransactionRow({ ticket, transaction }: { ticket: Ticket; transaction: Transaction }) {
    const ticketInvoicesDelegated = transaction.invoicesDelegated.filter((i) => i.ticket.id === ticket.id);
    const ticketInvoices = transaction.invoices.filter((i) => i.ticket.id === ticket.id);

    const ticketSum = sumBy(ticketInvoicesDelegated, "totalAmount");
    const transactionAmount = sumBy(transaction.invoicesDelegated, "totalAmount");

    let priceText = formatMoney(transactionAmount);
    if (ticketSum != transactionAmount) priceText += ", hvorav " + formatMoney(ticketSum) + " gjelder denne billetten";

    return (
        <TableRow key={"t+" + transaction.id}>
            <TableCell>
                <TimestampText value={transaction.createdTime} />
            </TableCell>
            <TableCell>
                <MaterialLink component={Link} to={"/nets-transaction/" + transaction.id}>
                    Kortbetaling ({priceText})
                </MaterialLink>
                <br />
                {formatCard(transaction.savedCard)}
            </TableCell>
            <TableCell colSpan={2}>
                <TransactionNetsResponseText transaction={transaction!} />
            </TableCell>
            <TableCell></TableCell>
            <TableCell align={"right"}>
                {ticketInvoices.length === 0
                    ? "-"
                    : transaction.processedSuccessfullyTime
                      ? formatMoney(sumBy(ticketInvoices, "totalAmount"))
                      : "processedSuccessfullyTime == null"}
            </TableCell>
        </TableRow>
    );
}

function TransactionCreditRow({ ticket, credit }: { ticket: Ticket; credit: TransactionCredit }) {
    const ticketInvoices = ticket.invoices.filter((i) => i.transactionCredit?.id === credit.id);
    return (
        <TableRow>
            <TableCell>
                <TimestampText value={credit.createdTime} />
            </TableCell>
            <TableCell>
                <MaterialLink component={Link} to={"/nets-transaction/" + credit.transaction.id}>
                    Kort refusjon
                </MaterialLink>
                <br />
                {formatCard(credit.transaction.savedCard)}
            </TableCell>
            <TableCell colSpan={2}>
                <TransactionNetsResponseText transaction={credit!} />
            </TableCell>
            <TableCell />
            <TableCell align={"right"}>-{formatMoney(sumBy(ticketInvoices, "totalAmount"))}</TableCell>
        </TableRow>
    );
}

export function TransactionNetsResponseText({
    transaction,
}: {
    transaction: {
        /// NetsTransaction
        readonly netsCaptureParsedResponseCode?: string | undefined | null;
        readonly netsCaptureResponseText?: string | undefined | null;
        readonly netsCaptureResponseCode?: string | undefined | null;
        readonly netsCaptureResponseSource?: string | undefined | null;
    } & {
        /// NetsTransactionCredit
        readonly netsProcessParsedResponseCode?: string | undefined | null;
        readonly netsProcessResponseText?: string | undefined | null;
        readonly netsProcessResponseCode?: string | undefined | null;
        readonly netsProcessResponseSource?: string | undefined | null;
    };
}) {
    const parsed = transaction.netsCaptureParsedResponseCode || transaction.netsProcessParsedResponseCode;
    const responseText = transaction.netsCaptureResponseText || transaction.netsProcessResponseText;
    const responseCode = transaction.netsCaptureResponseCode || transaction.netsProcessResponseCode;
    const responseSource = transaction.netsCaptureResponseSource || transaction.netsProcessResponseSource;

    let text = responseCode;

    if (responseSource) text += " / " + responseSource;

    return (
        <Typography>
            {responseCode == "OK" ? <CheckCircleIcon color={"success"} /> : <DangerousIcon color={"error"} />}

            <abbr
                title={parsed + "\nresponseText=" + responseText || ""}
                style={
                    responseCode == "OK"
                        ? {}
                        : {
                              color: "#F00",
                              fontWeight: "bold",
                          }
                }
            >
                {text}
            </abbr>
        </Typography>
    );
}

function formatCard(savedCard: { readonly issuer: string; readonly maskedPan: string }): string {
    return savedCard.issuer + " " + savedCard.maskedPan.substring(savedCard.maskedPan.length - 4);
}

function SumRow(props: { totalInvoiceAmount: number; totalPaid: number }) {
    const amountOwed = props.totalInvoiceAmount - props.totalPaid;

    function buildRow(text: string, col1: string, col2: string, style?: React.CSSProperties) {
        const mergedStyle: React.CSSProperties = { fontSize: "20px", color: "black", fontWeight: "400", ...style };

        return (
            <TableRow>
                <TableCell colSpan={3} />
                <TableCell style={mergedStyle}>{text}</TableCell>
                <TableCell align={"right"} style={mergedStyle}>
                    {col1}
                </TableCell>
                <TableCell align={"right"} style={mergedStyle}>
                    {col2}
                </TableCell>
            </TableRow>
        );
    }

    return [
        <TableRow>
            <TableCell colSpan={6} />
        </TableRow>,
        buildRow("Sum", formatMoney(props.totalInvoiceAmount), formatMoney(props.totalPaid), { fontWeight: "normal" }),
        <TableRow>
            <TableCell colSpan={6} />
        </TableRow>,
        amountOwed >= 0
            ? buildRow("Å betale", "", formatMoney(amountOwed))
            : buildRow("Å refundere", "", formatMoney(-amountOwed)),
    ];
}
