import {
    Alert,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid2,
    InputAdornment,
    Link as MaterialLink,
    TextField,
    Typography,
} from "@mui/material";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import {
    LocalPriceFragment,
    MutationSaveTicketComplaintArgs,
    TicketComplaintStatus,
    TicketPageV2Query,
} from "../../generated/gql/graphql";
import React, { FormEvent, useState } from "react";
import { Property, PropertyList } from "./TicketPageHeader";
import { formatMoney } from "../NetsTransactionsListPage";
import { TimestampText } from "../../widgets/date_time";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import EditIcon from "@mui/icons-material/Edit";
import { JsonDiagnosticButton } from "../../widgets/diagnostics";
import { TicketLocalLegsTable } from "./TicketInfoTab";
import { formatCustomerId, formatTicketId } from "../../util/formatting";
import { Ticket } from "./context";

interface TicketComplaintsTabProps {
    ticket: Ticket;
    onRefreshData: () => Promise<unknown>;
}

export default function TicketComplaintsTab(props: TicketComplaintsTabProps) {
    const [dialog, setDialog] = useState<React.ReactNode>(null);

    function handleRefundButtonClick() {
        setDialog(
            <SaveTicketComplaintDialog
                ticket={props.ticket}
                status={TicketComplaintStatus.Approved}
                onClose={() => setDialog(null)}
                onSave={() => {
                    setDialog(null);
                    return props.onRefreshData();
                }}
            />,
        );
    }

    const isAllComplaintsProcessed =
        props.ticket.complaints.filter((c) => c.status == TicketComplaintStatus.Pending).length == 0;

    return (
        <div>
            {isAllComplaintsProcessed ? (
                <Button
                    variant={"contained"}
                    startIcon={<CreditCardOffIcon />}
                    onClick={handleRefundButtonClick}
                    style={{ marginBottom: "40px" }}
                >
                    Korriger pris
                </Button>
            ) : null}

            {props.ticket.complaints.length == 0 ? <Typography>Ingen klager/refusjoner registrert.</Typography> : null}
            {props.ticket.complaints.map((complaint) => (
                <TicketComplaintInfo
                    key={complaint.id}
                    ticket={props.ticket}
                    complaint={complaint}
                    onRefreshData={props.onRefreshData}
                />
            ))}

            {dialog}
        </div>
    );
}

type TicketComplaint = Ticket["complaints"][0];

interface TicketComplaintInfoProps {
    ticket: Ticket;
    complaint: TicketComplaint;
    onRefreshData: () => Promise<unknown>;
}

function TicketComplaintInfo({ ticket, complaint, onRefreshData }: TicketComplaintInfoProps) {
    const [dialog, setDialog] = useState<React.ReactNode>(null);

    let statusAlert: React.ReactElement;

    switch (complaint.status) {
        case TicketComplaintStatus.Approved:
            statusAlert = (
                <Alert icon={<CheckIcon />} severity={"success"}>
                    <b>{formatApprovedStatus(complaint)}</b> av {complaint.statusChangedByEmail}
                    <br />
                    <TimestampText value={complaint.statusChangedTime} />
                    <Property title={"Intern kommentar"} value={complaint.internalComment} />
                    <Property
                        title={"E-postbekreftelse"}
                        value={complaint.customerMessage ? "Ja. Kommentar: " + complaint.customerMessage : "Ikke sendt"}
                    />
                    <Property title={"Opprinnelig billettpris"} value={formatMoney(complaint.originalPrice)} />
                    {complaint.status === TicketComplaintStatus.Approved ? (
                        <Property title={"Korrigert billettpris"} value={formatMoney(complaint.correctPrice)} />
                    ) : null}
                </Alert>
            );
            break;
        case TicketComplaintStatus.Rejected:
            statusAlert = (
                <Alert icon={<RemoveCircleIcon />} severity={"error"}>
                    <b>Avvist</b> av {complaint.statusChangedByEmail}
                    <br />
                    <TimestampText value={complaint.statusChangedTime} />
                    <Property title={"Intern kommentar"} value={complaint.internalComment} />
                    <Property
                        title={"E-postbekreftelse"}
                        value={complaint.customerMessage ? "Ja. Kommentar: " + complaint.customerMessage : "Ikke sendt"}
                    />
                </Alert>
            );
            break;
        case TicketComplaintStatus.Pending:
            function openResolveDialog(status: TicketComplaintStatus) {
                setDialog(
                    <SaveTicketComplaintDialog
                        ticketComplaintId={complaint.id}
                        ticket={ticket}
                        status={status}
                        onClose={() => setDialog(null)}
                        onSave={() => {
                            setDialog(null);
                            return onRefreshData();
                        }}
                    />,
                );
            }

            statusAlert = (
                <Alert
                    icon={<HourglassBottomIcon />}
                    severity={"info"}
                    action={
                        <ButtonGroup variant="outlined">
                            <Button
                                startIcon={<EditIcon />}
                                color={"success"}
                                onClick={() => openResolveDialog(TicketComplaintStatus.Approved)}
                            >
                                Korriger billettpris
                            </Button>
                            <Button
                                startIcon={<RemoveCircleIcon />}
                                color={"error"}
                                onClick={() => openResolveDialog(TicketComplaintStatus.Rejected)}
                            >
                                Avvis
                            </Button>
                        </ButtonGroup>
                    }
                >
                    <b>Venter behandling</b>
                </Alert>
            );
            break;
    }

    return (
        <div style={{ marginBottom: "40px" }}>
            <div style={{ marginBottom: "20px" }}>{statusAlert}</div>
            <JsonDiagnosticButton name={"complaint"} value={complaint} />
            <PropertyList>
                {complaint.supportTicketUrl ? (
                    <Property
                        title={"Support ticket URL"}
                        value={
                            <MaterialLink href={complaint.supportTicketUrl} target={"_blank"}>
                                {complaint.supportTicketUrl}
                            </MaterialLink>
                        }
                    />
                ) : null}
                {complaint.customerEmail ? (
                    <Property
                        title={"E-postaddresse"}
                        value={
                            <MaterialLink href={"mailto:" + complaint.customerEmail} target={"_blank"}>
                                {complaint.customerEmail}
                            </MaterialLink>
                        }
                    />
                ) : null}
                <Property title={"Rapportert"} value={<TimestampText value={complaint.createdTime} />} />
                <Property title={"Beskrivelse"} value={complaint.description} />

                <Typography variant={"h6"} style={{ marginTop: "20px" }}>
                    Opprinnelig billett-pris
                </Typography>

                {complaint.localPrice ? (
                    <TicketLocalLegsTable ticket={ticket} localPrice={complaint.localPrice as LocalPriceFragment} />
                ) : (
                    <Typography>Krever app versjon 4.25.2+</Typography>
                )}
            </PropertyList>

            {dialog}
        </div>
    );
}

function formatApprovedStatus(complaint: TicketComplaint) {
    if (complaint.correctPrice === 0) {
        return "Refundert";
    } else {
        return "Korrigert";
    }
}

const UPDATE_MUTATION = gql`
    mutation UpdateComplaint(
        $ticketId: Int!
        $id: Int
        $description: String!
        $supportTicketUrl: String!
        $status: TicketComplaintStatus!
        $correctPrice: Int!
        $customerMessage: String!
        $internalComment: String!
    ) {
        saveTicketComplaint(
            ticketId: $ticketId
            id: $id
            description: $description
            supportTicketUrl: $supportTicketUrl
            status: $status
            correctPrice: $correctPrice
            customerMessage: $customerMessage
            internalComment: $internalComment
        ) {
            id
        }
    }
`;

function SaveTicketComplaintDialog({
    ticket,
    ticketComplaintId,
    status,
    onClose,
    onSave,
}: {
    ticket: TicketPageV2Query["ticket"];
    ticketComplaintId?: number;
    status: TicketComplaintStatus;
    onClose: () => void;
    onSave: () => void;
}) {
    const mutation = useMutation<TicketComplaint, MutationSaveTicketComplaintArgs>(UPDATE_MUTATION);
    const [description, setDescription] = useState("");
    const [supportTicketUrl, setSupportTicketUrl] = useState("");
    const [includeInternalComment, setIncludeInternalComment] = useState(false);
    const [internalComment, setInternalComment] = useState("");
    const [customerMessage, setCustomerMessage] = useState("");
    const [correctPrice, setCorrectPrice] = useState("");
    const [sendEmail, setSendEmail] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    function handleSubmit(event: FormEvent) {
        event.stopPropagation();
        event.preventDefault();
        setShowErrors(true);

        if (!ticketComplaintId) {
            if (description.trim().length == 0) {
                return;
            }
        }

        if (sendEmail && customerMessage.trim().length == 0) {
            return;
        }

        if (status === TicketComplaintStatus.Approved) {
            if (correctPrice == "") {
                return;
            }
        }

        if (mutation[1].loading) {
            return;
        }

        mutation[0]({
            variables: {
                ticketId: ticket.id,
                id: ticketComplaintId,
                status,
                description,
                supportTicketUrl,
                internalComment: includeInternalComment ? internalComment : "",
                correctPrice: (parseInt(correctPrice) || 0) * 100,
                customerMessage: sendEmail ? customerMessage : "",
            },
        }).then(() => {
            onSave();
        });
    }

    function buildApproveText(oldPrice: number, correctPrice: string) {
        if (correctPrice == "") {
            return "Krever beløp";
        }

        const newPrice = parseInt(correctPrice) * 100;

        if (newPrice > oldPrice) {
            return "Belast " + formatMoney(newPrice - oldPrice);
        } else {
            return "Refunder " + formatMoney(oldPrice - newPrice);
        }
    }

    return (
        <Dialog
            open
            onClose={internalComment.length == 0 && customerMessage.length == 0 ? onClose : undefined}
            maxWidth={"sm"}
        >
            <form onSubmit={handleSubmit} style={{ width: "600px" }}>
                <DialogTitle>
                    {status == TicketComplaintStatus.Approved ? "Korriger billettpris" : "Avvis klage"}
                </DialogTitle>
                <DialogContent>
                    {!ticketComplaintId ? (
                        <React.Fragment>
                            <PropertyList>
                                <Property title={"App-ID"} value={formatCustomerId(ticket.customer.id)} />
                                <Property title={"Billett-ID"} value={formatTicketId(ticket.id)} />
                                <Property
                                    title={"Gyldig fra / Bestilt dato"}
                                    value={<TimestampText value={ticket.createdTime} />}
                                />
                            </PropertyList>

                            <TextField
                                autoFocus
                                fullWidth
                                variant={"filled"}
                                type={"url"}
                                label={"Support ticket URL"}
                                value={supportTicketUrl}
                                onChange={(e) => setSupportTicketUrl(e.target.value)}
                                style={{ marginBottom: "20px" }}
                            />
                            <TextField
                                multiline
                                minRows={5}
                                fullWidth
                                required
                                error={showErrors && description.trim().length == 0}
                                variant={"filled"}
                                label={"Beskrivelse"}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </React.Fragment>
                    ) : null}

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={includeInternalComment}
                                onChange={(_, checked) => setIncludeInternalComment(checked)}
                            />
                        }
                        label="Inkluder intern kommentar"
                    />

                    {includeInternalComment ? (
                        <TextField
                            autoFocus
                            fullWidth
                            minRows={5}
                            multiline
                            variant={"filled"}
                            label={"Intern kommentar"}
                            value={internalComment}
                            onChange={(e) => setInternalComment(e.target.value)}
                            style={{ marginBottom: "20px" }}
                        />
                    ) : null}

                    <FormControlLabel
                        control={<Checkbox checked={sendEmail} onChange={(_, checked) => setSendEmail(checked)} />}
                        label="Send e-postbekreftelse til kunden"
                    />

                    {sendEmail ? (
                        <TextField
                            multiline
                            minRows={5}
                            fullWidth
                            required
                            error={showErrors && customerMessage.trim().length == 0}
                            variant={"filled"}
                            label={"Kommentar til kunde (inkluderes i e-postbekreftelse)"}
                            value={customerMessage}
                            onChange={(e) => setCustomerMessage(e.target.value)}
                            style={{ marginBottom: "20px" }}
                        />
                    ) : null}

                    <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                    {status == TicketComplaintStatus.Approved ? (
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 6 }}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={"Aktiv billettpris"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">kr</InputAdornment>,
                                    }}
                                    value={(ticket.price || 0) / 100}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6 }}>
                                <TextField
                                    fullWidth
                                    required
                                    autoFocus={!!ticketComplaintId}
                                    label={"Ny billettpris"}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">kr</InputAdornment>,
                                    }}
                                    value={correctPrice}
                                    onChange={(e) => {
                                        if (/^\d*$/.test(e.target.value)) {
                                            setCorrectPrice(e.target.value);
                                        } else {
                                            setCorrectPrice("");
                                            e.target.blur();
                                        }
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Avbryt</Button>
                    <Button
                        variant={"contained"}
                        type={"submit"}
                        startIcon={mutation[1].loading ? <CircularProgress color={"inherit"} size={20} /> : null}
                        disabled={status == TicketComplaintStatus.Approved && correctPrice === ""}
                    >
                        {status == TicketComplaintStatus.Approved
                            ? buildApproveText(ticket.price || 0, correctPrice)
                            : "Avvis klage"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
