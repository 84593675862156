import { Paper, Table, TableBody, TableCell, TableHead, TableRow, TableRowProps } from "@mui/material";
import { parseTime, parseTimeOrNull } from "../../util/date_time";
import React, { useState } from "react";
import { getLegsWithDescription, Ticket, TicketLegWithDescription, useTicketPageV2Context } from "../ticket_v2/context";
import { RelativeTimeText } from "../../widgets/date_time";
import { DateTime } from "luxon";
import { grey } from "@mui/material/colors";
import { VehicleTransportMode } from "../../generated/gql/graphql";
import { getLegColor } from "./map/map_controller";
import { getTimeGaps } from "../../util/time_gaps";

type TicketBeaconSession = Ticket["beaconSessions"][number];

export function TicketBeaconSessionsTable() {
    const [focusedVehicleId, setFocusedVehicleId] = useState<VehicleTransportMode | number | null>(null);
    const context = useTicketPageV2Context();
    const ticket = context.ticket;
    const ticketCreatedTime = parseTime(ticket.createdTime);
    const list = context.ticket.beaconSessions;
    const legs = getLegsWithDescription(ticket);

    const gaps = getTimeGaps(parseTime(ticket.createdTime), parseTimeOrNull(ticket.endedTime), context.ticket.beaconScans.map((b) => parseTime(b.recordedTime)));

    console.log("Gaps", gaps);

    const rows: [DateTime, React.ReactNode][] = list.map(session => {
        const beacon = session.beacon;


        const beaconId = beacon ? beacon.vehicleId || beacon.transportMode || 2 : 0;

        const matchingLegs = getMatchingLegIndex(legs, beacon);
        const legIndex = matchingLegs.length > 0 ? legs.indexOf(matchingLegs[0]) : -1;

        return [
            parseTime(session.startTime),
            <SessionRow
                ticketCreatedTime={ticketCreatedTime}
                startTime={parseTime(session.startTime)}
                endTime={parseTime(session.endTime)}
                TableRowProps={beacon ? {
                    onMouseEnter: () => setFocusedVehicleId(beaconId),
                    onMouseLeave: () => setFocusedVehicleId(null),
                    style: {
                        backgroundColor: focusedVehicleId === beaconId ? grey[300] : (legIndex >= 0 ? getLegColor(legIndex)[300] : undefined)
                    }
                } : {}}
            >
                {beacon ? [
                    <TableCell padding="none" align={"right"}>{beacon.major}</TableCell>,
                    <TableCell padding="none" align={"right"}>{beacon.minor}</TableCell>,
                    <TableCell>{beacon.transportMode}</TableCell>,
                    <TableCell>{beacon.vehicleId}</TableCell>
                ] : <TableCell colSpan={4}>No beacons</TableCell>}
            </SessionRow>
        ];
    });

    gaps.forEach(([start, end]) => {
        rows.push([start, <SessionRow ticketCreatedTime={ticketCreatedTime} startTime={start} endTime={end}>
            <TableCell colSpan={4}>Data missing</TableCell>
        </SessionRow>
        ]);
    });

    rows.sort((a, b) => a[0].toMillis() - b[0].toMillis());

    return <Paper style={{ width: "fit-content" }}>
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell padding={"none"} align={"right"} width={"50px"}>Major</TableCell>
                    <TableCell padding={"none"} align={"right"} width={"50px"}>Minor</TableCell>
                    <TableCell width={"60px"}>Mode</TableCell>
                    <TableCell>Vogn-id</TableCell>
                    <TableCell>Tidsperiode</TableCell>
                    <TableCell>Billett-tid</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(([_, row]) => row)}
            </TableBody>
        </Table>
    </Paper>;
}

function SessionRow(props: {
    ticketCreatedTime: DateTime<true>,
    startTime: DateTime<true>,
    endTime: DateTime<true>,

    TableRowProps?: TableRowProps,
    children: React.ReactNode,
}) {
    const duration = parseTime(props.endTime).diff(parseTime(props.startTime));
    const startTimeOffset = parseTime(props.startTime).diff(props.ticketCreatedTime);
    const endTimeOffset = parseTime(props.endTime).diff(props.ticketCreatedTime);
    return <TableRow
        {...props.TableRowProps}
        style={{
            color: duration.toMillis()! > 15_000 ? "inherit" : "#AAA",
            ...props.TableRowProps?.style
        }}
    >
        {props.children}
        <TableCell>
            <RelativeTimeText
                includeSeconds
                value={props.startTime}
                relativeTo={props.ticketCreatedTime}
            />
            {" – "}
            <RelativeTimeText
                includeSeconds
                value={props.endTime}
                relativeTo={props.startTime}
            />
        </TableCell>
        <TableCell>
            {startTimeOffset.normalize().toFormat("hh:mm:ss")}
            {" – "}
            {endTimeOffset.normalize().toFormat("hh:mm:ss")}
        </TableCell>
        <TableCell align={"right"}>{duration.as("minutes").toFixed(0) + " min"}</TableCell>
    </TableRow>;
}

function getMatchingLegIndex(legs: ReadonlyArray<TicketLegWithDescription>, beacon: TicketBeaconSession["beacon"]): ReadonlyArray<TicketLegWithDescription> {
    if (!beacon) return [];
    return legs.filter((leg) => {
        const legVehicleId = leg.description.vehicleId;
        const legTransportMode = leg.description.journey.vehicleTransportMode;
        if (legVehicleId && legVehicleId !== beacon.vehicleId) return false;
        if (legTransportMode && legTransportMode !== beacon.transportMode) return false;
        return true;
    });
}
