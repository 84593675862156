import { gql } from "@apollo/client";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
    NewLegTripJourneyDetailsQuery,
    NewLegTripJourneyDetailsQueryVariables,
    NewLegTripMutation,
    NewLegTripMutationVariables
} from "../../generated/gql/graphql";
import { sortBy } from "lodash";
import { calculateDistance, LatLngObject } from "../../util/coordinates";
import { DateTimeFormat, formatTime, parseTime, parseTimeOrNull } from "../../util/date_time";
import LoadingModal from "../../widgets/LoadingModal";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useTicketPageV2Context } from "../ticket_v2/context";
import { TimestampText } from "../../widgets/date_time";
import { formatPercentage } from "../../util/formatting";
import { grey } from "@mui/material/colors";

const NEW_LEG_TRIP_QUERY = gql`
    query NewLegTripJourneyDetails(
        $ticketId: Int!
        $journeyId: Int,
        $tripId: Int,
        $serviceJourneyId: String,
        $operatingDate: String!,
        $vehicleId: Int
    ){
        journey(journeyId: $journeyId, tripId: $tripId, serviceJourneyId: $serviceJourneyId, operatingDate: $operatingDate){
            id
            line{
                id
                publicCode
            }
            destination
            stopTimes{
                stopTimeIndex
                platform{
                    id
                    name
                    publicCode
                }
                boarding
                alighting
                scheduledDepartureTime

                latitude
                longitude
            }

            ticketLegFromToAnalysis(ticketId: $ticketId, vehicleId: $vehicleId) {
                fromStopTimeIndex
                toStopTimeIndex
                embarkedConfidences
                fromConfidences
                toConfidences
            }

            vehiclePositionLog(ticketId: $ticketId, vehicleId: $vehicleId) {
                vehicleId
                recordedTime
                latitude
                longitude
                tripId
            }
        }
    }
`;

const NEW_LEG_TRIP_Mutation = gql`
    mutation NewLegTrip(
        $id: Int,
        $ticketId: Int!
        $journeyId: Int!,
        $vehicleId: Int
        $fromStopTimeIndex: Int!
        $toStopTimeIndex: Int!,
        $startedTime: ISOTimestamp,
        $endedTime: ISOTimestamp,
    ){
        saveLeg(
            id: $id,
            ticketId: $ticketId,
            journeyId: $journeyId,
            vehicleId: $vehicleId,
            fromStopTimeIndex: $fromStopTimeIndex,
            toStopTimeIndex: $toStopTimeIndex,
            startedTime: $startedTime,
            endedTime: $endedTime,
        ){
            id
        }
    }
`;

export const LegEditorModal = memo<{
    leg: {
        id?: number | null,
        journey: {
            id?: number | null,
            serviceJourneyId?: string | null,
            tripId?: number | null,
            operatingDate: string,
        },
        fromStopTimeIndex?: number | null,
        toStopTimeIndex?: number | null,
        startedTime?: string,
        endedTime?: string,
        vehicleId?: number | null,
    }
    onClose: () => void,
    onSave: () => Promise<void>,
}>(({ leg, onClose, onSave }) => {
    const ticket = useTicketPageV2Context()!.ticket;
    const [vehicleId, setVehicleId] = useState<string>(leg.vehicleId ? leg.vehicleId.toString() : "");
    const query = useQuery<NewLegTripJourneyDetailsQuery, NewLegTripJourneyDetailsQueryVariables>(NEW_LEG_TRIP_QUERY, {
        variables: {
            ticketId: ticket.id,
            journeyId: leg.journey.id,
            tripId: leg.journey.tripId,
            serviceJourneyId: leg.journey.serviceJourneyId,
            operatingDate: leg.journey.operatingDate,
            vehicleId: vehicleId ? parseInt(vehicleId) : null
        }
    });
    const mutation = useMutation<NewLegTripMutation, NewLegTripMutationVariables>(NEW_LEG_TRIP_Mutation);
    const [fromStopTimeIndex, setFromStopTimeIndex] = useState<number | null>(leg.fromStopTimeIndex ?? null);
    const [toStopTimeIndex, setToStopTimeIndex] = useState<number | null>(leg.toStopTimeIndex ?? null);
    const [startedTime, setStartedTime] = useState<DateTime | null>(parseTimeOrNull(leg.startedTime));
    const [endedTime, setEndedTime] = useState<DateTime | null>(parseTimeOrNull(leg.endedTime));
    const [loaded, setLoaded] = useState<boolean>(false);

    const journey = query.data?.journey || query.previousData?.journey;

    useEffect(() => {
        if (!journey) return;
        if (!fromStopTimeIndex && !toStopTimeIndex) {
            setFromStopTimeIndex(journey.ticketLegFromToAnalysis.fromStopTimeIndex ?? null);
            setToStopTimeIndex(journey.ticketLegFromToAnalysis.toStopTimeIndex ?? null);
        }
        setLoaded(true);
    }, [journey]);

    if (query.loading && !journey)
        return <LoadingModal />;
    console.log("journey", journey!.ticketLegFromToAnalysis);
    if (!loaded)
        return <LoadingModal />;


    return <Dialog open={true} onClose={onClose} fullWidth maxWidth={"xl"}>
        <DialogTitle>
            {(leg.id ? "Rediger legg: " : "Opprett legg:") + journey!.line.publicCode + " " + journey!.destination}

            <Grid2 container spacing={2}>
                <Grid2 size={{ md: 2 }}>
                    <TextField
                        label={"Vogn id"}
                        fullWidth
                        value={vehicleId}
                        onChange={(e) => setVehicleId(e.target.value.trim())}
                    />
                </Grid2>
                {startedTime ? <Grid2 size={{ md: 2 }}>
                    <TimeField
                        fullWidth
                        ampm={false}
                        label={"Start"}
                        format="HH:mm:ss"
                        referenceDate={parseTime(ticket.createdTime)}
                        value={startedTime}
                        onChange={(e) => setStartedTime(e as DateTime)}
                    />
                </Grid2> : null}

                {endedTime ? <Grid2 size={{ md: 2 }}>
                    <TimeField
                        fullWidth
                        ampm={false}
                        label={"End"}
                        format="HH:mm:ss"
                        referenceDate={parseTime(ticket.createdTime)}
                        value={endedTime}
                        onChange={(e) => setEndedTime(e as DateTime)}
                    />
                </Grid2> : null}
            </Grid2>

        </DialogTitle>
        <DialogContent>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>Stopp</TableCell>
                        <TableCell>Rutetid</TableCell>
                        <TableCell>Vogn besøk</TableCell>
                        <TableCell>App besøk</TableCell>
                        <TableCell colSpan={2}>Fra</TableCell>
                        <TableCell colSpan={2}>Til</TableCell>
                        <TableCell>Ombord</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {journey!.stopTimes.map((stopTime, index) => {
                        const embarkedConfidence = journey!.ticketLegFromToAnalysis.embarkedConfidences[index];
                        const fromConfidence = journey!.ticketLegFromToAnalysis.fromConfidences[index];
                        const toConfidence = journey!.ticketLegFromToAnalysis.toConfidences[index];

                        function backgroundColor(confidence: number) {
                            return "rgba(165,214,167," + confidence + ")";
                        }

                        function setToStopTime() {
                            setToStopTimeIndex(stopTime.stopTimeIndex);
                            setEndedTime(null);

                            if (fromStopTimeIndex !== null && fromStopTimeIndex >= stopTime.stopTimeIndex) {
                                setFromStopTimeIndex(null);
                                setStartedTime(null);
                            }
                        }

                        function setFromStopTime() {
                            setFromStopTimeIndex(stopTime.stopTimeIndex);
                            setStartedTime(null);

                            if (toStopTimeIndex !== null && stopTime.stopTimeIndex >= toStopTimeIndex) {
                                setToStopTimeIndex(null);
                                setEndedTime(null);
                            }
                        }

                        const isEmbarked = fromStopTimeIndex !== null && fromStopTimeIndex <= stopTime.stopTimeIndex && toStopTimeIndex !== null && toStopTimeIndex >= stopTime.stopTimeIndex;
                        return <TableRow key={index} style={{ background: isEmbarked ? grey[100] : undefined }}>
                            <TableCell>{stopTime.stopTimeIndex}.</TableCell>
                            <TableCell>{stopTime.platform.name}</TableCell>
                            <TableCell>
                                <TimestampText
                                    format={DateTimeFormat.TIME}
                                    value={stopTime.scheduledDepartureTime}
                                />
                            </TableCell>
                            <TableCell>
                                <PositionLogVisits
                                    position={stopTime}
                                    positionLog={journey!.vehiclePositionLog}
                                    entryDistance={50}
                                />
                            </TableCell>
                            <TableCell>
                                <PositionLogVisits
                                    position={stopTime}
                                    positionLog={ticket.positionLog}
                                    entryDistance={150}
                                />
                            </TableCell>
                            <TableCell
                                padding={"none"}
                                style={{ backgroundColor: backgroundColor(fromConfidence) }}
                            >
                                <Radio
                                    color="default"
                                    size={"small"}
                                    disabled={!stopTime.boarding}
                                    checked={fromStopTimeIndex === stopTime.stopTimeIndex}
                                    onChange={setFromStopTime}
                                />
                            </TableCell>
                            <TableCell
                                align={"right"}
                                style={{ backgroundColor: backgroundColor(fromConfidence) }}
                                onClick={stopTime.boarding ? setFromStopTime : undefined}
                            >
                                {formatConfidence(fromConfidence)}</TableCell>
                            <TableCell
                                padding={"none"}
                                style={{ backgroundColor: backgroundColor(toConfidence) }}
                            >
                                <Radio
                                    color="default"
                                    size={"small"}
                                    disabled={!stopTime.alighting}
                                    checked={toStopTimeIndex === stopTime.stopTimeIndex}
                                    autoFocus={toStopTimeIndex === stopTime.stopTimeIndex}
                                    onChange={setToStopTime}
                                />
                            </TableCell>
                            <TableCell
                                align={"right"}
                                style={{ backgroundColor: backgroundColor(toConfidence), cursor: "pointer" }}
                                onClick={stopTime.alighting ? setToStopTime : undefined}
                            >
                                {formatConfidence(toConfidence)}
                            </TableCell>
                            <TableCell
                                align={"right"}
                                style={{ backgroundColor: backgroundColor(embarkedConfidence) }}
                            >
                                {formatConfidence(embarkedConfidence)}
                            </TableCell>
                        </TableRow>;
                    })}
                </TableBody>
            </Table>
        </DialogContent>;
        <DialogActions>
            <Button onClick={onClose}>
                Lukk
            </Button>
            <Button disabled={fromStopTimeIndex == null || toStopTimeIndex == null} onClick={async () => {
                await mutation[0]({
                    variables: {
                        id: leg.id,
                        journeyId: query.data!.journey.id,
                        vehicleId: vehicleId ? parseInt(vehicleId) : null,
                        ticketId: ticket.id,
                        fromStopTimeIndex: fromStopTimeIndex!,
                        toStopTimeIndex: toStopTimeIndex!,
                        startedTime: startedTime,
                        endedTime: endedTime
                    }
                });
                await onSave();
            }}>
                Lagre
            </Button>
        </DialogActions>;
    </Dialog>
        ;
});

function formatConfidence(confidence: number) {
    if (confidence > 0.5) {
        return <b>{formatPercentage(confidence)}</b>;
    } else if (confidence > 0.03) {
        return formatPercentage(confidence);
    } else {
        return null;
    }
}


function PositionLogVisits<T extends RecordedPosition>(props: {
    position: LatLngObject,
    positionLog: ReadonlyArray<T>,
    entryDistance: number,
}) {
    if (props.positionLog.length === 0) return null;
    const visits = useMemo<ReadonlyArray<StopTimeVisit<T>>>(() => getStopTimeVisits(props.position, props.positionLog, props.entryDistance), [props.position, props.positionLog, props.entryDistance]);

    if (visits.length === 0) {
        const closestRecordedPosition = sortBy(props.positionLog, position => calculateDistance(position, props.position))[0];
        const distance = calculateDistance(closestRecordedPosition, props.position);

        return <Typography variant={"body2"}>
            {formatTime(closestRecordedPosition.recordedTime)}
            {" (" + distance.toFixed(0) + " m)"}
        </Typography>;
    }

    return visits.map((visit) => {
        return <Typography variant={"body2"}>
            <TimestampText
                format={DateTimeFormat.TIME}
                value={visit.entry.outside.recordedTime}
            />
            {" – "}
            <TimestampText
                format={DateTimeFormat.TIME}
                value={visit.exit.outside.recordedTime}
            />
            {" (" + visit.closest.distance.toFixed(0) + " m)"}
        </Typography>;
    });

}

type RecordedPosition = LatLngObject & {
    recordedTime: string
    accuracy?: number;
};

interface StopTimeVisit<T extends RecordedPosition> {
    entry: Transition<T>;
    closest: Transition<T>;
    exit: Transition<T>;
}

interface Transition<T extends RecordedPosition> {
    inside: T;
    outside: T;
    distance: number;
}

function getStopTimeVisits<T extends RecordedPosition>(stopTime: LatLngObject, positionLog: ReadonlyArray<T>, entryDistance: number = 100, exitDistance: number = entryDistance * 1.8): ReadonlyArray<StopTimeVisit<T>> {
    if (positionLog.length === 0) return [];

    let previous = positionLog[0];

    let visits: StopTimeVisit<T>[] = [];
    let activeVisit: StopTimeVisit<T> | null = null;

    positionLog.forEach((position) => {
        const distance = calculateDistance(position, stopTime);
        const distanceMinusAccuracy = distance - (position.accuracy || 0);

        if (activeVisit !== null && distance < activeVisit.closest.distance) {
            activeVisit.closest = { inside: position, outside: position, distance };
        }

        if (distanceMinusAccuracy > exitDistance) {
            if (activeVisit !== null) {
                activeVisit.exit.outside = position;
                visits.push(activeVisit);
                activeVisit = null;
            }
        } else {
            if (activeVisit === null) {
                if (distance < entryDistance) {
                    activeVisit = {
                        entry: { outside: previous, inside: position, distance },
                        closest: { outside: position, inside: position, distance },
                        exit: { outside: position, inside: position, distance }
                    };
                }
            } else {
                activeVisit.exit = { outside: position, inside: position, distance };
            }
        }
        previous = position;
    });
    if (activeVisit !== null) {
        visits.push(activeVisit);
    }
    return visits;
}