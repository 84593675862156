import { brown, lightBlue, lightGreen, orange } from "@mui/material/colors";
import { Color } from "@mui/material/styles/createPalette";
import {
    getLegsWithDescription,
    Ticket,
    TicketLegWithDescription,
    TicketPageV2ContextType,
    useTicketPageV2Context
} from "../../ticket_v2/context";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
    Position_AndroidLocationProvider,
    StopTimeLiteFragment,
    TicketRecordedPosition,
    VehiclePositionFragment,
    VehicleSnapshotQuery,
    VehicleSnapshotQueryVariables,
    VehicleTransportMode
} from "../../../generated/gql/graphql";
import { parseTime, parseTimeOrNull } from "../../../util/date_time";
import { minBy, sortBy } from "lodash";
import { LegEditorModal } from "../LegEditorModal";
import { ISOTimestamp } from "../../statistics/StatisticsProcessingPage";
import { NewLegCatalogModal } from "../NewLegCatalogModal";
import { QueryResult, useQuery } from "@apollo/react-hooks";
import { gql } from "@apollo/client";
import { LatLng, toLatLng } from "../../../util/coordinates";
import LatLngBounds = google.maps.LatLngBounds;


const ASSET_SNAPSHOT_QUERY = gql`
    query VehicleSnapshot($timestamp: String!) {
        vehicleSnapshot(timestamp: $timestamp) {
            recordedTime
            vehicles {
                ...VehiclePosition
            }
        }
    }

    fragment VehiclePosition on VehiclePosition {
        tripId
        operatingDate
        vehicleId
        linePublicCode
        latitude
        longitude
        recordedTime
        transportMode
        grpcPositionType
        grpcPositionSource
    }
`;

export const zIndexes = {
    legStopTime: 5,
    legStopTimeEmbarked: 10,
    vehiclePosition: 500,
    legVehiclePolyline: 1400,
    legVehiclePolylineEmbarked: 1430,
    vehiclePositionSelected: 1500,
    phonePositionPolyline: 2000,
    vehiclePositionFocused: 5000,
    phonePositionMarker: 6000
};


const legColors: Color[] = [
    lightGreen,
    lightBlue,
    brown,
    orange

];

export function getLegColor(index: number): Color {
    return legColors[index % legColors.length];
}

export type TicketMapStateListener = (state: TicketMapState, oldState: TicketMapState) => void;
export type VehicleSnapshot = NonNullable<VehicleSnapshotQuery["vehicleSnapshot"]>;

export interface TicketMapController {
    map: google.maps.Map | null,
    ticketPageContext: TicketPageV2ContextType,
    ticket: Ticket;
    legs: ReadonlyArray<TicketLegWithDescription>;
    positionLog: ReadonlyArray<TicketRecordedPosition>;
    state: TicketMapState,

    addStateListener(listener: TicketMapStateListener): void;

    removeStateListener(listener: TicketMapStateListener): void;

    setState(state: Partial<TicketMapState>): void,

    goToTime(time: DateTime): void,

    goToTime(time: DateTime, scrollPositionIntoView: boolean): void,

    setModal(modal: React.ReactNode | null): void,

    onNewLegButtonClick(position: VehiclePositionFragment): void,

    onJourneyCatalogButtonClick(options: OpenJourneyCatalogOptions): void,

    getLegMatching(v: { tripId?: number | null, vehicleId: number }): [TicketLegWithDescription, number] | null;

    panToInclude(position: LatLng): void;
}

export class TicketMapControllerImpl implements TicketMapController {
    map: google.maps.Map | null = null;
    vehicleSnapshotQuery!: QueryResult<VehicleSnapshotQuery, VehicleSnapshotQueryVariables>;
    state: TicketMapState;
    _setStateHook!: (state: TicketMapState) => void;
    private _legsCached?: ReadonlyArray<TicketLegWithDescription>;
    private _positionLogCached?: ReadonlyArray<TicketRecordedPosition>;
    private readonly _listeners = new Set<TicketMapStateListener>();

    constructor(ticketPageContext: TicketPageV2ContextType) {
        this._ticketPageContext = ticketPageContext;
        this.state = createInitialState(ticketPageContext.ticket);
        this.onTicketUpdated();
        this.addStateListener(this.onStateChanged);
    }

    get ticket(): Ticket {
        return this.ticketPageContext.ticket;
    }

    _ticketPageContext!: TicketPageV2ContextType;

    get ticketPageContext(): TicketPageV2ContextType {
        return this._ticketPageContext;
    }

    _onTicketUpdatedCache?: Ticket;

    set ticketPageContext(value: TicketPageV2ContextType) {
        const oldTicket = this._onTicketUpdatedCache;
        this._ticketPageContext = value;
        this._onTicketUpdatedCache = value.ticket;
        if (value.ticket !== oldTicket) {
            this.onTicketUpdated();
        }
    }

    get legs(): ReadonlyArray<TicketLegWithDescription> {
        if (this._legsCached === undefined)
            this._legsCached = getLegsWithDescription(this.ticket);
        return this._legsCached!;
    }

    get positionLog(): ReadonlyArray<TicketRecordedPosition> {
        if (this._positionLogCached === undefined) {
            this._positionLogCached = sortBy(this.ticket.positionLog, "recordedTime");
            if (this.state.filterProvider) {
                this._positionLogCached = this._positionLogCached.filter((s) => s.androidLocationProvider === this.state.filterProvider);
            }
        }
        return this._positionLogCached!;
    }

    getLegMatching(match: { tripId?: number | null; vehicleId: number; }): [TicketLegWithDescription, number] | null {
        const legs = this.legs;
        const index = legs.findIndex((leg) => {
            if (match.tripId && match.tripId !== leg.description.journey.tripId) {
                return false;
            }

            return match.vehicleId === leg.description.vehicleId;
        });
        if (index === -1) {
            return null;
        }
        return [legs[index], index];
    }

    setState(state: Partial<TicketMapState>) {
        const oldState = this.state;
        this.state = { ...this.state, ...state };
        this._setStateHook(this.state);
        this.notifyStateListeners(this.state, oldState);
    }


    goToTime(time: DateTime, scrollPositionIntoView: boolean = true) {
        const event = minBy(this.ticket.positionLog, (r) => {
            return Math.abs(parseTime(r.recordedTime).toMillis() - time!.toMillis());
        })!;
        this.setState({
            currentTime: time,
            selectedRecordedPosition: event
        });
        if (scrollPositionIntoView)
            document.getElementById(event.uuid)!.scrollIntoView({ behavior: "smooth" });
    }

    setModal(modal: React.ReactNode | null) {
        this.setState({ modal });
    }

    useHook() {
        this.ticketPageContext = useTicketPageV2Context();
        [, this._setStateHook] = useState<TicketMapState>(this.state);

        this.vehicleSnapshotQuery = useQuery<VehicleSnapshotQuery, VehicleSnapshotQueryVariables>(ASSET_SNAPSHOT_QUERY, {
            variables: { timestamp: this.state.currentTime.toISO()! },
            skip: false
        });

        const vehicleSnapshot: VehicleSnapshot | null = (this.vehicleSnapshotQuery.data || this.vehicleSnapshotQuery.previousData)?.vehicleSnapshot || null;
        if (vehicleSnapshot !== this.state.vehicleSnapshot) {
            this.setState({ vehicleSnapshot });
        }
    }

    onJourneyCatalogButtonClick(options: OpenJourneyCatalogOptions) {
        this.setModal(<NewLegCatalogModal
            {...options}
            onClose={() => this.setModal(null)}
            onSave={() => this.ticketPageContext.handleRefreshData().then(() => this.setModal(null))}
        />);
    }

    onNewLegButtonClick(position: VehiclePositionFragment) {
        const leg = this.getLegMatching(position)?.["0"];

        if (!leg && !position.tripId) {
            this.onJourneyCatalogButtonClick({
                vehicleId: position.vehicleId,
                transportMode: position.transportMode,
                timestamp: position.recordedTime,
                position
            });
            return;
        }

        const legProps = leg ? {
            id: leg.id,
            journey: leg.description!.journey,
            fromStopTimeIndex: (leg.description!
                .fromStopTime as StopTimeLiteFragment)!.stopTimeIndex,
            toStopTimeIndex: (leg.description!
                .toStopTime as StopTimeLiteFragment)!.stopTimeIndex,
            startedTime: leg.startedTime,
            endedTime: leg.description!.endedTime,
            vehicleId: leg.description!.vehicleId
        } : {
            journey: {
                tripId: position.tripId!,
                operatingDate: position.operatingDate!
            },
            vehicleId: position.vehicleId
        };

        this.setState({
            modal: <LegEditorModal
                leg={legProps}
                onClose={() => this.setModal(null)}
                onSave={() => this.ticketPageContext.handleRefreshData().then(() => this.setModal(null))}
            />,
            vehicleId: position.vehicleId
        });
    }

    addStateListener(listener: TicketMapStateListener) {
        this._listeners.add(listener);
    }

    removeStateListener(listener: TicketMapStateListener) {
        this._listeners.delete(listener);
    }

    panToInclude(position: LatLng) {
        const map = this.map;
        if (!map) return;
        const contains = map.getBounds()!.contains(toLatLng(position));

        if (!contains) {
            let bounds = new LatLngBounds();
            bounds = bounds.extend(map.getBounds()!.getNorthEast());
            bounds = bounds.extend(map.getBounds()!.getSouthWest());
            bounds = bounds.extend(toLatLng(position));

            map.fitBounds(bounds, 10);
        }
    }

    private notifyStateListeners(newState: TicketMapState, oldState: TicketMapState) {
        const listeners = [...this._listeners];
        listeners.forEach((listener) => {
            try {
                listener(newState, oldState);
            } catch (e) {
                console.error("State listener failed", e, newState, oldState);
            }
        });
    }

    private onTicketUpdated() {
        this._positionLogCached = undefined;
        this._legsCached = undefined;
    }

    private onStateChanged: TicketMapStateListener = (newState: TicketMapState, oldState: TicketMapState) => {
        if (newState.filterProvider !== oldState.filterProvider) {
            this._positionLogCached = undefined;
        }
    };
}

type OpenJourneyCatalogOptions = {
    timestamp: ISOTimestamp,
    position: { latitude: number, longitude: number },
    transportMode?: VehicleTransportMode
    vehicleId?: number
};

export type TicketMapState = Readonly<{
    vehicleId?: number,
    /**
     * Set when hovering a vehicle
     */
    vehicleIdFocused?: number,
    currentTime: DateTime,
    selectedRecordedPosition?: TicketRecordedPosition;
    phonePositionHovering?: TicketRecordedPosition;
    currentMapPosition: google.maps.LatLngLiteral;
    modal?: React.ReactNode,
    filterProvider?: Position_AndroidLocationProvider

    vehicleSnapshot: VehicleSnapshot | null
}>;

function createInitialState(ticket: Ticket): TicketMapState {
    let startedTime = getLegsWithDescription(ticket)[0]?.startedTime || ticket.createdTime;
    const position = ticket.positionLog.find((p) => p.recordedTime >= startedTime) || ticket.positionLog[0];

    return {
        selectedRecordedPosition: position,
        currentTime: parseTimeOrNull(position?.recordedTime) || parseTime(ticket.createdTime),
        currentMapPosition: position ? toLatLng(position) : { lat: 58.9305979, lng: 5.6927593 },
        // vehicleId: getLegsWithDescription(ticket)
        //     .map((l) => l.description.vehicleId)
        //     .find((l) => !!l) || undefined,
        vehicleSnapshot: null
    };
}

export function useStateListener(controller: TicketMapController, listener: TicketMapStateListener, deps: React.DependencyList = []) {
    useEffect(() => {
        controller.addStateListener(listener);

        return () => {
            controller.removeStateListener(listener);
        };
    }, deps);
}


export function useTicketMapState(controller: TicketMapController): TicketMapState {
    const [state, setState] = useState(controller.state);

    useStateListener(controller, (newState) => {
        setState(newState);
    }, []);

    return state;
}