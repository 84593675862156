import { VehiclePositionFragment, VehicleTransportMode } from "../../../generated/gql/graphql";
import { Button, ButtonGroup, Link as MaterialLink, Typography } from "@mui/material";
import { Property, PropertyList } from "../../ticket_v2/TicketPageHeader";
import { RelativeTimeText, TimestampText } from "../../../widgets/date_time";
import WarningIcon from "@mui/icons-material/Warning";
import { DateTimeFormat, parseTime } from "../../../util/date_time";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import React, { useEffect, useRef } from "react";
import { getLegColor, TicketMapController, VehicleSnapshot, zIndexes } from "./map_controller";
import { TicketLegWithDescription } from "../../ticket_v2/context";
import { createRoot, Root } from "react-dom/client";
import { blue } from "@mui/material/colors";
import { QuestionMark } from "@mui/icons-material";
import CollisionBehavior = google.maps.CollisionBehavior;

export function useVehicleSnapshotLayer(controller: TicketMapController) {
    const { current: assetMarkers } = useRef<{ [vehicleId: number]: VehicleMarkerFeature }>({});

    const state = controller.state;
    const vehicleSnapshot = state.vehicleSnapshot;
    useEffect(() => {
        if (!controller.map || !vehicleSnapshot) return;
        const vehiclePositions = vehicleSnapshot.vehicles as VehiclePositionFragment[];
        const vehicleIds = vehiclePositions.map(a => a.vehicleId);

        Object.keys(assetMarkers)
            .map(vehicleId => parseInt(vehicleId))
            .filter(vehicleId => !vehicleIds.includes(vehicleId))
            .forEach(vehicleId => {
                assetMarkers[vehicleId].dispose();
                delete assetMarkers[vehicleId];
            });


        vehiclePositions.forEach((position) => {
            const markerId = position.vehicleId;
            let marker = assetMarkers[markerId];

            if (!marker) {
                marker = new VehicleMarkerFeature(controller, vehicleSnapshot, position);
                assetMarkers[markerId] = marker;
            }
            marker.setVehiclePosition(vehicleSnapshot, position);
        });

    }, [controller.map, vehicleSnapshot]);

    const legs = controller.legs;
    Object.values(assetMarkers).forEach((marker) => {
        const vehicleId = marker.vehiclePosition.vehicleId;
        marker.opened = state.vehicleId === vehicleId;
        marker.focused = state.vehicleIdFocused ? state.vehicleIdFocused === vehicleId : null;

        const legIndex = legs.findIndex((leg: TicketLegWithDescription) => leg.description?.vehicleId === vehicleId);
        marker.legIndex = legIndex != -1 ? legIndex : null;
    });
}

class VehicleMarkerFeature {
    controller: TicketMapController;
    vehicleSnapshot: VehicleSnapshot;
    private readonly marker: google.maps.marker.AdvancedMarkerElement;
    private readonly _markerRoot: Root;
    private _infoWindow?: google.maps.InfoWindow;
    private _infoWindowRoot?: Root;

    constructor(
        controller: TicketMapController,
        vehicleSnapshot: VehicleSnapshot,
        vehiclePosition: VehiclePositionFragment
    ) {
        this.controller = controller;
        this.vehicleSnapshot = vehicleSnapshot;
        this._vehiclePosition = vehiclePosition;

        const markerElement = document.createElement("div");
        this._markerRoot = createRoot(markerElement);
        this.marker = new google.maps.marker.AdvancedMarkerElement({
            content: markerElement,
            collisionBehavior: CollisionBehavior.REQUIRED,
            zIndex: zIndexes.vehiclePosition
        });
        this.marker.map = controller.map;


        this.marker.addListener("click", () => {
            this.controller.setState({ vehicleId: this.opened ? undefined : this.vehiclePosition.vehicleId });
        });

        this.setVehiclePosition(vehicleSnapshot, vehiclePosition);
    }

    private _vehiclePosition: VehiclePositionFragment;

    get vehiclePosition(): VehiclePositionFragment {
        return this._vehiclePosition;
    }

    private _opened: boolean = false;

    get opened(): boolean {
        return this._opened;
    }

    set opened(value: boolean) {
        if (value == this._opened) return;
        this._opened = value;

        this.renderMarker();

        if (value) {
            if (!this._infoWindow) {
                const container = document.createElement("div");
                this._infoWindowRoot = createRoot(container);

                this._infoWindow = new google.maps.InfoWindow({
                    content: container
                });
                this._infoWindow.addListener("close", () => {
                    this.controller.setState({ vehicleId: undefined });
                });

            }
            this.renderInfoWindow();
            this._infoWindow.open(this.map, this.marker);
        } else {
            this._infoWindow?.close();
        }

    }

    private _focused: boolean | null = null;
    set focused(value: boolean | null) {
        if (value === this._focused) return;
        this._focused = value;
        this.renderMarker();
    }

    private _legIndex: number | null = null;

    set legIndex(value: number | null) {
        if (value === this._legIndex) return;
        this._legIndex = value;
        this.renderMarker();
    }

    private get map(): google.maps.Map {
        return this.controller.map!;
    }

    setVehiclePosition(snapshot: VehicleSnapshot, position: VehiclePositionFragment) {
        this.vehicleSnapshot = snapshot;
        this._vehiclePosition = position;
        this.marker.position = { lat: position.latitude, lng: position.longitude };
        this.marker.title = position.vehicleId + " (" + position.tripId + ")";
        this.renderMarker();
        this.renderInfoWindow();
    }

    dispose() {
        this._infoWindow?.close();
        this.marker.map = null;
        this._infoWindowRoot?.unmount();
        this._markerRoot.unmount();
    }

    private renderMarker() {
        this.marker.zIndex = this._opened ? zIndexes.vehiclePositionSelected : this._focused ? zIndexes.vehiclePositionFocused : zIndexes.vehiclePosition;
        this._markerRoot.render(<VehiclePositionIcon
            legIndex={this._legIndex}
            vehicleSnapshot={this.vehicleSnapshot}
            vehiclePosition={this._vehiclePosition}
            focused={this._focused}
        />);
    }

    private renderInfoWindow() {
        this._infoWindowRoot?.render(<VehiclePositionInfoWindow
            controller={this.controller}
            vehiclePosition={this.vehiclePosition}
        />);
    }
}

function VehiclePositionIcon(props: {
    legIndex: number | null,
    vehicleSnapshot: VehicleSnapshot,
    vehiclePosition: VehiclePositionFragment,
    focused: boolean | null
}) {
    const linePublicCode = props.vehiclePosition.linePublicCode || "";
    let content: React.ReactNode = linePublicCode;

    if (props.vehiclePosition.transportMode === VehicleTransportMode.Rail) {
        content = <DirectionsTransitIcon fontSize={"inherit"} />;
    }
    if (props.vehiclePosition.transportMode === VehicleTransportMode.Water) {
        content = <DirectionsBoatIcon fontSize={"inherit"} />;
    }

    let size = content ? 30 : 15;
    let fontSize = linePublicCode?.length >= 3 ? "12px" : "15px";
    let border = "2px solid #FFF";
    let background = content ? "#333F48" : "#919191";
    if (props.vehiclePosition.grpcPositionType.toUpperCase() != "GPS") {
        background = "#F7BC00";
        border = "2px solid ##F7BC00";
    }

    const positionAge = parseTime(props.vehiclePosition.recordedTime).diff(parseTime(props.vehicleSnapshot.recordedTime));
    const positionSeconds = positionAge.rescale().as("seconds");
    if (Math.abs(positionSeconds) > 10) {
        background = "#EA5332";
        border = "2px solid #EA5332";
    }

    if (props.legIndex !== null) {
        size = 35;
        background = getLegColor(props.legIndex)[800];
    }

    if (props.focused) {
        background = blue[500];
        size = 40;
    } else if (props.focused === false) {
        size = Math.min(size, 25);
        fontSize = "10px";
    }

    return <div style={{
        background: background,
        width: size,
        height: size,
        borderRadius: "50%",
        textAlign: "center",
        lineHeight: size + "px",
        color: "white",
        fontSize,
        fontWeight: "bold",
        border,
        boxShadow: "0px 5px 10px -1px rgba(0, 0, 0, 0.2)" // Smooth grey shadow
    }}>
        {content}
    </div>;
}


export function TransportModeIcon(props: { transportMode?: VehicleTransportMode | null, size?: "small" }) {
    switch (props.transportMode) {
        case VehicleTransportMode.Bus:
            return <DirectionsBusIcon fontSize={props.size} />;
        case VehicleTransportMode.Rail:
            return <DirectionsTransitIcon fontSize={props.size} />;
        case VehicleTransportMode.Water:
            return <DirectionsBoatIcon fontSize={props.size} />;
        default:
            return <QuestionMark fontSize={props.size} />;
    }
}

function VehiclePositionInfoWindow({ vehiclePosition, controller }: {
    vehiclePosition: VehiclePositionFragment,
    controller: TicketMapController,
}) {
    const titleWidth = "90px";

    const beaconSessions = controller.ticket.beaconSessions.filter(session => session.beacon?.vehicleId === vehiclePosition.vehicleId);

    return <div>
        <Typography variant={"subtitle1"} style={{ display: "flex", alignItems: "center" }}>
            <TransportModeIcon transportMode={vehiclePosition.transportMode} />
            <span style={{ width: "10px" }} />
            {vehiclePosition.vehicleId}
        </Typography>
        <br />
        <PropertyList>
            {vehiclePosition.linePublicCode ? <Property
                title={"Line"}
                value={vehiclePosition.linePublicCode}
                titleWidth={titleWidth}
            /> : null}
            {vehiclePosition.tripId ? <Property
                title={"TripId"}
                value={vehiclePosition.tripId}
                titleWidth={titleWidth}
            /> : null}
            <Property
                title={"Recorded Time"}
                value={<RelativeTimeText
                    includeSeconds
                    showTimeDifference
                    value={vehiclePosition.recordedTime}
                    relativeTo={controller.state.currentTime}
                    relativeToLabel={"TicketTelemetryEvent.recordedTime"}
                />}
                titleWidth={titleWidth}
            />
            <Property
                title={"gRPC source"}
                value={<span>
                    {vehiclePosition.grpcPositionType.toUpperCase() != "GPS" ? <WarningIcon color={"error"} /> : null}
                    {vehiclePosition.grpcPositionType}/{vehiclePosition.grpcPositionSource}
                </span>}
                titleWidth={titleWidth}
            />
            {controller.ticket.beaconSessions ? <div style={{ marginBottom: "8px" }}>
                    Beacon sessions<br />
                    {beaconSessions.length === 0 ? "Ingen matcher" : null}
                    {beaconSessions.map((s) => {
                        return <span>
                        <MaterialLink onClick={() => controller.goToTime(parseTime(s.startTime))}>
                            <TimestampText format={DateTimeFormat.TIME} value={s.startTime} />
                        </MaterialLink>
                            {" — "}
                            <MaterialLink onClick={() => controller.goToTime(parseTime(s.endTime))}>
                            <TimestampText format={DateTimeFormat.TIME} value={s.endTime} />
                        </MaterialLink>
                        <br />
                    </span>;
                    })}
                </div>
                : null}
            <ButtonGroup size={"small"} variant="outlined" color={"primary"} aria-label="Basic button group">
                <Button disabled={!vehiclePosition.tripId}
                        onClick={() => controller.onNewLegButtonClick(vehiclePosition)}>New leg</Button>
                <Button onClick={() => controller.onJourneyCatalogButtonClick({
                    timestamp: controller.state.currentTime.toISO()!,
                    position: vehiclePosition,
                    transportMode: vehiclePosition.transportMode,
                    vehicleId: vehiclePosition.vehicleId
                })}>
                    Journey catalog
                </Button>
            </ButtonGroup>
        </PropertyList>

    </div>;
}
