// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: no/kolumbus/sanntid_api/vehicle_data_bulky.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import {
  Timestamp,
  TransportMode,
  transportModeFromJSON,
  transportModeToJSON,
  transportModeToNumber,
  TripDateId,
  VehicleSnapshotSource,
  vehicleSnapshotSourceFromJSON,
  vehicleSnapshotSourceToJSON,
  vehicleSnapshotSourceToNumber,
} from "./common";

export const protobufPackage = "";

export interface VehiclePositionsList {
  positions: VehiclePosition[];
}

export interface VehicleSnapshot {
  /** This is the timestamp written on the message from gRPC or Signalr */
  timestamp: Timestamp | undefined;
  position: VehiclePosition[];
  source: VehicleSnapshotSource;
  /** This is the time the snapshot was received by the client */
  createdTime: Timestamp | undefined;
}

export interface VehiclePosition {
  vehicleId: number;
  tripId: TripDateId | undefined;
  latitude: number;
  longitude: number;
  recordedTime: Timestamp | undefined;
  destination: string;
  lineName: string;
  transportMode: TransportMode;
  /** See https://grpc.kolumbus.no/protos/v1/rthub.proto SourcesEnum.Values */
  positionSource: number;
  /** See https://grpc.kolumbus.no/protos/v1/rthub.proto PositionSource.Values */
  positionType: number;
}

export enum VehiclePosition_GrpcPositionType {
  None = "None",
  Gps = "Gps",
  Udr = "Udr",
  Simulation = "Simulation",
  Unknown = "Unknown",
  Hfps = "Hfps",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function vehiclePosition_GrpcPositionTypeFromJSON(object: any): VehiclePosition_GrpcPositionType {
  switch (object) {
    case 0:
    case "None":
      return VehiclePosition_GrpcPositionType.None;
    case 1:
    case "Gps":
      return VehiclePosition_GrpcPositionType.Gps;
    case 2:
    case "Udr":
      return VehiclePosition_GrpcPositionType.Udr;
    case 3:
    case "Simulation":
      return VehiclePosition_GrpcPositionType.Simulation;
    case 4:
    case "Unknown":
      return VehiclePosition_GrpcPositionType.Unknown;
    case 5:
    case "Hfps":
      return VehiclePosition_GrpcPositionType.Hfps;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VehiclePosition_GrpcPositionType.UNRECOGNIZED;
  }
}

export function vehiclePosition_GrpcPositionTypeToJSON(object: VehiclePosition_GrpcPositionType): string {
  switch (object) {
    case VehiclePosition_GrpcPositionType.None:
      return "None";
    case VehiclePosition_GrpcPositionType.Gps:
      return "Gps";
    case VehiclePosition_GrpcPositionType.Udr:
      return "Udr";
    case VehiclePosition_GrpcPositionType.Simulation:
      return "Simulation";
    case VehiclePosition_GrpcPositionType.Unknown:
      return "Unknown";
    case VehiclePosition_GrpcPositionType.Hfps:
      return "Hfps";
    case VehiclePosition_GrpcPositionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function vehiclePosition_GrpcPositionTypeToNumber(object: VehiclePosition_GrpcPositionType): number {
  switch (object) {
    case VehiclePosition_GrpcPositionType.None:
      return 0;
    case VehiclePosition_GrpcPositionType.Gps:
      return 1;
    case VehiclePosition_GrpcPositionType.Udr:
      return 2;
    case VehiclePosition_GrpcPositionType.Simulation:
      return 3;
    case VehiclePosition_GrpcPositionType.Unknown:
      return 4;
    case VehiclePosition_GrpcPositionType.Hfps:
      return 5;
    case VehiclePosition_GrpcPositionType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum VehiclePosition_GrpcPositionSource {
  PT_UNKNOWN = "PT_UNKNOWN",
  SIRI = "SIRI",
  HFPS = "HFPS",
  Netex = "Netex",
  Kystverket = "Kystverket",
  APC = "APC",
  Computed = "Computed",
  NextGen = "NextGen",
  Masterloop = "Masterloop",
  Composer = "Composer",
  Forecast = "Forecast",
  Norled = "Norled",
  Citybike = "Citybike",
  MPC = "MPC",
  NorgesbussAPC = "NorgesbussAPC",
  KolumbusVehicleCapacity = "KolumbusVehicleCapacity",
  RyfylkeSAPC = "RyfylkeSAPC",
  HaugalandetAPC = "HaugalandetAPC",
  SanntidsApi = "SanntidsApi",
  RyfylkeNAPC = "RyfylkeNAPC",
  DalaneAPC = "DalaneAPC",
  NomadRail = "NomadRail",
  BaneNorStopMonitoring = "BaneNorStopMonitoring",
  EnturGraphQL = "EnturGraphQL",
  BarentsWatch = "BarentsWatch",
  Medstraum = "Medstraum",
  Ridango = "Ridango",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function vehiclePosition_GrpcPositionSourceFromJSON(object: any): VehiclePosition_GrpcPositionSource {
  switch (object) {
    case 0:
    case "PT_UNKNOWN":
      return VehiclePosition_GrpcPositionSource.PT_UNKNOWN;
    case 1:
    case "SIRI":
      return VehiclePosition_GrpcPositionSource.SIRI;
    case 2:
    case "HFPS":
      return VehiclePosition_GrpcPositionSource.HFPS;
    case 3:
    case "Netex":
      return VehiclePosition_GrpcPositionSource.Netex;
    case 4:
    case "Kystverket":
      return VehiclePosition_GrpcPositionSource.Kystverket;
    case 5:
    case "APC":
      return VehiclePosition_GrpcPositionSource.APC;
    case 6:
    case "Computed":
      return VehiclePosition_GrpcPositionSource.Computed;
    case 7:
    case "NextGen":
      return VehiclePosition_GrpcPositionSource.NextGen;
    case 8:
    case "Masterloop":
      return VehiclePosition_GrpcPositionSource.Masterloop;
    case 9:
    case "Composer":
      return VehiclePosition_GrpcPositionSource.Composer;
    case 10:
    case "Forecast":
      return VehiclePosition_GrpcPositionSource.Forecast;
    case 11:
    case "Norled":
      return VehiclePosition_GrpcPositionSource.Norled;
    case 12:
    case "Citybike":
      return VehiclePosition_GrpcPositionSource.Citybike;
    case 13:
    case "MPC":
      return VehiclePosition_GrpcPositionSource.MPC;
    case 14:
    case "NorgesbussAPC":
      return VehiclePosition_GrpcPositionSource.NorgesbussAPC;
    case 15:
    case "KolumbusVehicleCapacity":
      return VehiclePosition_GrpcPositionSource.KolumbusVehicleCapacity;
    case 16:
    case "RyfylkeSAPC":
      return VehiclePosition_GrpcPositionSource.RyfylkeSAPC;
    case 17:
    case "HaugalandetAPC":
      return VehiclePosition_GrpcPositionSource.HaugalandetAPC;
    case 18:
    case "SanntidsApi":
      return VehiclePosition_GrpcPositionSource.SanntidsApi;
    case 19:
    case "RyfylkeNAPC":
      return VehiclePosition_GrpcPositionSource.RyfylkeNAPC;
    case 20:
    case "DalaneAPC":
      return VehiclePosition_GrpcPositionSource.DalaneAPC;
    case 21:
    case "NomadRail":
      return VehiclePosition_GrpcPositionSource.NomadRail;
    case 22:
    case "BaneNorStopMonitoring":
      return VehiclePosition_GrpcPositionSource.BaneNorStopMonitoring;
    case 23:
    case "EnturGraphQL":
      return VehiclePosition_GrpcPositionSource.EnturGraphQL;
    case 24:
    case "BarentsWatch":
      return VehiclePosition_GrpcPositionSource.BarentsWatch;
    case 25:
    case "Medstraum":
      return VehiclePosition_GrpcPositionSource.Medstraum;
    case 26:
    case "Ridango":
      return VehiclePosition_GrpcPositionSource.Ridango;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VehiclePosition_GrpcPositionSource.UNRECOGNIZED;
  }
}

export function vehiclePosition_GrpcPositionSourceToJSON(object: VehiclePosition_GrpcPositionSource): string {
  switch (object) {
    case VehiclePosition_GrpcPositionSource.PT_UNKNOWN:
      return "PT_UNKNOWN";
    case VehiclePosition_GrpcPositionSource.SIRI:
      return "SIRI";
    case VehiclePosition_GrpcPositionSource.HFPS:
      return "HFPS";
    case VehiclePosition_GrpcPositionSource.Netex:
      return "Netex";
    case VehiclePosition_GrpcPositionSource.Kystverket:
      return "Kystverket";
    case VehiclePosition_GrpcPositionSource.APC:
      return "APC";
    case VehiclePosition_GrpcPositionSource.Computed:
      return "Computed";
    case VehiclePosition_GrpcPositionSource.NextGen:
      return "NextGen";
    case VehiclePosition_GrpcPositionSource.Masterloop:
      return "Masterloop";
    case VehiclePosition_GrpcPositionSource.Composer:
      return "Composer";
    case VehiclePosition_GrpcPositionSource.Forecast:
      return "Forecast";
    case VehiclePosition_GrpcPositionSource.Norled:
      return "Norled";
    case VehiclePosition_GrpcPositionSource.Citybike:
      return "Citybike";
    case VehiclePosition_GrpcPositionSource.MPC:
      return "MPC";
    case VehiclePosition_GrpcPositionSource.NorgesbussAPC:
      return "NorgesbussAPC";
    case VehiclePosition_GrpcPositionSource.KolumbusVehicleCapacity:
      return "KolumbusVehicleCapacity";
    case VehiclePosition_GrpcPositionSource.RyfylkeSAPC:
      return "RyfylkeSAPC";
    case VehiclePosition_GrpcPositionSource.HaugalandetAPC:
      return "HaugalandetAPC";
    case VehiclePosition_GrpcPositionSource.SanntidsApi:
      return "SanntidsApi";
    case VehiclePosition_GrpcPositionSource.RyfylkeNAPC:
      return "RyfylkeNAPC";
    case VehiclePosition_GrpcPositionSource.DalaneAPC:
      return "DalaneAPC";
    case VehiclePosition_GrpcPositionSource.NomadRail:
      return "NomadRail";
    case VehiclePosition_GrpcPositionSource.BaneNorStopMonitoring:
      return "BaneNorStopMonitoring";
    case VehiclePosition_GrpcPositionSource.EnturGraphQL:
      return "EnturGraphQL";
    case VehiclePosition_GrpcPositionSource.BarentsWatch:
      return "BarentsWatch";
    case VehiclePosition_GrpcPositionSource.Medstraum:
      return "Medstraum";
    case VehiclePosition_GrpcPositionSource.Ridango:
      return "Ridango";
    case VehiclePosition_GrpcPositionSource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function vehiclePosition_GrpcPositionSourceToNumber(object: VehiclePosition_GrpcPositionSource): number {
  switch (object) {
    case VehiclePosition_GrpcPositionSource.PT_UNKNOWN:
      return 0;
    case VehiclePosition_GrpcPositionSource.SIRI:
      return 1;
    case VehiclePosition_GrpcPositionSource.HFPS:
      return 2;
    case VehiclePosition_GrpcPositionSource.Netex:
      return 3;
    case VehiclePosition_GrpcPositionSource.Kystverket:
      return 4;
    case VehiclePosition_GrpcPositionSource.APC:
      return 5;
    case VehiclePosition_GrpcPositionSource.Computed:
      return 6;
    case VehiclePosition_GrpcPositionSource.NextGen:
      return 7;
    case VehiclePosition_GrpcPositionSource.Masterloop:
      return 8;
    case VehiclePosition_GrpcPositionSource.Composer:
      return 9;
    case VehiclePosition_GrpcPositionSource.Forecast:
      return 10;
    case VehiclePosition_GrpcPositionSource.Norled:
      return 11;
    case VehiclePosition_GrpcPositionSource.Citybike:
      return 12;
    case VehiclePosition_GrpcPositionSource.MPC:
      return 13;
    case VehiclePosition_GrpcPositionSource.NorgesbussAPC:
      return 14;
    case VehiclePosition_GrpcPositionSource.KolumbusVehicleCapacity:
      return 15;
    case VehiclePosition_GrpcPositionSource.RyfylkeSAPC:
      return 16;
    case VehiclePosition_GrpcPositionSource.HaugalandetAPC:
      return 17;
    case VehiclePosition_GrpcPositionSource.SanntidsApi:
      return 18;
    case VehiclePosition_GrpcPositionSource.RyfylkeNAPC:
      return 19;
    case VehiclePosition_GrpcPositionSource.DalaneAPC:
      return 20;
    case VehiclePosition_GrpcPositionSource.NomadRail:
      return 21;
    case VehiclePosition_GrpcPositionSource.BaneNorStopMonitoring:
      return 22;
    case VehiclePosition_GrpcPositionSource.EnturGraphQL:
      return 23;
    case VehiclePosition_GrpcPositionSource.BarentsWatch:
      return 24;
    case VehiclePosition_GrpcPositionSource.Medstraum:
      return 25;
    case VehiclePosition_GrpcPositionSource.Ridango:
      return 26;
    case VehiclePosition_GrpcPositionSource.UNRECOGNIZED:
    default:
      return -1;
  }
}

function createBaseVehiclePositionsList(): VehiclePositionsList {
  return { positions: [] };
}

export const VehiclePositionsList: MessageFns<VehiclePositionsList> = {
  encode(message: VehiclePositionsList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.positions) {
      VehiclePosition.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VehiclePositionsList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVehiclePositionsList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.positions.push(VehiclePosition.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VehiclePositionsList {
    return {
      positions: globalThis.Array.isArray(object?.positions)
        ? object.positions.map((e: any) => VehiclePosition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: VehiclePositionsList): unknown {
    const obj: any = {};
    if (message.positions?.length) {
      obj.positions = message.positions.map((e) => VehiclePosition.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VehiclePositionsList>, I>>(base?: I): VehiclePositionsList {
    return VehiclePositionsList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VehiclePositionsList>, I>>(object: I): VehiclePositionsList {
    const message = createBaseVehiclePositionsList();
    message.positions = object.positions?.map((e) => VehiclePosition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVehicleSnapshot(): VehicleSnapshot {
  return { timestamp: undefined, position: [], source: VehicleSnapshotSource.SIGNALR, createdTime: undefined };
}

export const VehicleSnapshot: MessageFns<VehicleSnapshot> = {
  encode(message: VehicleSnapshot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestamp !== undefined) {
      Timestamp.encode(message.timestamp, writer.uint32(10).fork()).join();
    }
    for (const v of message.position) {
      VehiclePosition.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.source !== VehicleSnapshotSource.SIGNALR) {
      writer.uint32(24).int32(vehicleSnapshotSourceToNumber(message.source));
    }
    if (message.createdTime !== undefined) {
      Timestamp.encode(message.createdTime, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VehicleSnapshot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVehicleSnapshot();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.timestamp = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.position.push(VehiclePosition.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.source = vehicleSnapshotSourceFromJSON(reader.int32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.createdTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VehicleSnapshot {
    return {
      timestamp: isSet(object.timestamp) ? Timestamp.fromJSON(object.timestamp) : undefined,
      position: globalThis.Array.isArray(object?.position)
        ? object.position.map((e: any) => VehiclePosition.fromJSON(e))
        : [],
      source: isSet(object.source) ? vehicleSnapshotSourceFromJSON(object.source) : VehicleSnapshotSource.SIGNALR,
      createdTime: isSet(object.createdTime) ? Timestamp.fromJSON(object.createdTime) : undefined,
    };
  },

  toJSON(message: VehicleSnapshot): unknown {
    const obj: any = {};
    if (message.timestamp !== undefined) {
      obj.timestamp = Timestamp.toJSON(message.timestamp);
    }
    if (message.position?.length) {
      obj.position = message.position.map((e) => VehiclePosition.toJSON(e));
    }
    if (message.source !== VehicleSnapshotSource.SIGNALR) {
      obj.source = vehicleSnapshotSourceToJSON(message.source);
    }
    if (message.createdTime !== undefined) {
      obj.createdTime = Timestamp.toJSON(message.createdTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VehicleSnapshot>, I>>(base?: I): VehicleSnapshot {
    return VehicleSnapshot.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VehicleSnapshot>, I>>(object: I): VehicleSnapshot {
    const message = createBaseVehicleSnapshot();
    message.timestamp = (object.timestamp !== undefined && object.timestamp !== null)
      ? Timestamp.fromPartial(object.timestamp)
      : undefined;
    message.position = object.position?.map((e) => VehiclePosition.fromPartial(e)) || [];
    message.source = object.source ?? VehicleSnapshotSource.SIGNALR;
    message.createdTime = (object.createdTime !== undefined && object.createdTime !== null)
      ? Timestamp.fromPartial(object.createdTime)
      : undefined;
    return message;
  },
};

function createBaseVehiclePosition(): VehiclePosition {
  return {
    vehicleId: 0,
    tripId: undefined,
    latitude: 0,
    longitude: 0,
    recordedTime: undefined,
    destination: "",
    lineName: "",
    transportMode: TransportMode.None,
    positionSource: 0,
    positionType: 0,
  };
}

export const VehiclePosition: MessageFns<VehiclePosition> = {
  encode(message: VehiclePosition, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.vehicleId !== 0) {
      writer.uint32(16).uint32(message.vehicleId);
    }
    if (message.tripId !== undefined) {
      TripDateId.encode(message.tripId, writer.uint32(26).fork()).join();
    }
    if (message.latitude !== 0) {
      writer.uint32(37).float(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(45).float(message.longitude);
    }
    if (message.recordedTime !== undefined) {
      Timestamp.encode(message.recordedTime, writer.uint32(50).fork()).join();
    }
    if (message.destination !== "") {
      writer.uint32(74).string(message.destination);
    }
    if (message.lineName !== "") {
      writer.uint32(82).string(message.lineName);
    }
    if (message.transportMode !== TransportMode.None) {
      writer.uint32(88).int32(transportModeToNumber(message.transportMode));
    }
    if (message.positionSource !== 0) {
      writer.uint32(120).uint32(message.positionSource);
    }
    if (message.positionType !== 0) {
      writer.uint32(128).uint32(message.positionType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VehiclePosition {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVehiclePosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.vehicleId = reader.uint32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.tripId = TripDateId.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 37) {
            break;
          }

          message.latitude = reader.float();
          continue;
        }
        case 5: {
          if (tag !== 45) {
            break;
          }

          message.longitude = reader.float();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.recordedTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.destination = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.lineName = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.transportMode = transportModeFromJSON(reader.int32());
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.positionSource = reader.uint32();
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.positionType = reader.uint32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VehiclePosition {
    return {
      vehicleId: isSet(object.vehicleId) ? globalThis.Number(object.vehicleId) : 0,
      tripId: isSet(object.tripId) ? TripDateId.fromJSON(object.tripId) : undefined,
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      recordedTime: isSet(object.recordedTime) ? Timestamp.fromJSON(object.recordedTime) : undefined,
      destination: isSet(object.destination) ? globalThis.String(object.destination) : "",
      lineName: isSet(object.lineName) ? globalThis.String(object.lineName) : "",
      transportMode: isSet(object.transportMode) ? transportModeFromJSON(object.transportMode) : TransportMode.None,
      positionSource: isSet(object.positionSource) ? globalThis.Number(object.positionSource) : 0,
      positionType: isSet(object.positionType) ? globalThis.Number(object.positionType) : 0,
    };
  },

  toJSON(message: VehiclePosition): unknown {
    const obj: any = {};
    if (message.vehicleId !== 0) {
      obj.vehicleId = Math.round(message.vehicleId);
    }
    if (message.tripId !== undefined) {
      obj.tripId = TripDateId.toJSON(message.tripId);
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.recordedTime !== undefined) {
      obj.recordedTime = Timestamp.toJSON(message.recordedTime);
    }
    if (message.destination !== "") {
      obj.destination = message.destination;
    }
    if (message.lineName !== "") {
      obj.lineName = message.lineName;
    }
    if (message.transportMode !== TransportMode.None) {
      obj.transportMode = transportModeToJSON(message.transportMode);
    }
    if (message.positionSource !== 0) {
      obj.positionSource = Math.round(message.positionSource);
    }
    if (message.positionType !== 0) {
      obj.positionType = Math.round(message.positionType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VehiclePosition>, I>>(base?: I): VehiclePosition {
    return VehiclePosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VehiclePosition>, I>>(object: I): VehiclePosition {
    const message = createBaseVehiclePosition();
    message.vehicleId = object.vehicleId ?? 0;
    message.tripId = (object.tripId !== undefined && object.tripId !== null)
      ? TripDateId.fromPartial(object.tripId)
      : undefined;
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.recordedTime = (object.recordedTime !== undefined && object.recordedTime !== null)
      ? Timestamp.fromPartial(object.recordedTime)
      : undefined;
    message.destination = object.destination ?? "";
    message.lineName = object.lineName ?? "";
    message.transportMode = object.transportMode ?? TransportMode.None;
    message.positionSource = object.positionSource ?? 0;
    message.positionType = object.positionType ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
