import gql from "graphql-tag";
import { LocalPriceFragment, QuestionnaireFragment } from "../../generated/gql/graphql";
import { Property, PropertyList } from "./TicketPageHeader";
import { Typography } from "@mui/material";
import { TimestampText } from "../../widgets/date_time";
import { TicketLocalPriceSection } from "./TicketInfoTab";
import { Ticket } from "./context";

export const QUESTIONNAIRE_FRAGMENT = gql`
    fragment Questionnaire on Questionnaire {
        analysis {
            ...LocalPrice
        }
        createdTime
        answeredTime
        answered

        prompts {
            question
            answer
        }
    }
`;

type Questionnaire = Ticket["questionnaire"] & QuestionnaireFragment;

interface TicketQuestionnaireTabProps {
    ticket: Ticket;
}

export default function TicketQuestionnaireTab(props: TicketQuestionnaireTabProps) {
    const questionnaire = props.ticket.questionnaire as Questionnaire | undefined;
    if (!questionnaire) {
        return <Typography>Ingen spørreskjema sendt</Typography>;
    }

    return (
        <div>
            <TicketLocalPriceSection ticket={props.ticket} localPrice={questionnaire.analysis as LocalPriceFragment} />

            <Typography variant={"h6"} style={{ marginTop: "40px", marginBottom: "10px" }}>
                Spørreskjema
            </Typography>

            <PropertyList>
                <Property title={"Sendt"} value={<TimestampText value={questionnaire.createdTime} />} />
                <Property title={"Besvart"} value={<TimestampText value={questionnaire.answeredTime} />} />
            </PropertyList>

            <div style={{ marginBottom: "20px" }} />

            {questionnaire.prompts.map((p) => (
                <div style={{ marginBottom: "15px" }}>
                    <Typography variant={"h6"} style={{ fontSize: "1rem" }}>
                        {p.question}
                    </Typography>
                    <Typography variant={"body1"}>{p.answer || <i>Ubesvart</i>}</Typography>
                </div>
            ))}
        </div>
    );
}
