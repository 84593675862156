import { CustomerPageQuery } from "../../generated/gql/graphql";
import React from "react";
import { Alert, Grid, Link as MaterialLink, Typography } from "@mui/material";
import { formatCustomerId, formatTicketId } from "../../util/formatting";
import { TimestampText } from "../../widgets/date_time";
import { Property, PropertyList } from "../ticket_v2/TicketPageHeader";
import { JsonDiagnosticButton } from "../../widgets/diagnostics";
import { groupBy, sortBy, sumBy } from "lodash";
import { formatMoney } from "../NetsTransactionsListPage";
import { Link } from "react-router-dom";

type Customer = CustomerPageQuery["customer"];
type CustomerPageHeaderProps = {
    customer: Customer;
};

export function CustomerPageHeader({ customer }: CustomerPageHeaderProps) {
    return (
        <div>
            <h2>
                Kunde: {formatCustomerId(customer.id)}
                <JsonDiagnosticButton name={"customer"} value={customer} />
            </h2>

            {customer.deletionRequestedTime ? (
                <Alert severity="error" style={{ marginBottom: "20px" }}>
                    Kunden deaktiverte kontoen sin <TimestampText value={customer.deletionRequestedTime} />, og denne
                    siden vil derfor ha begrensede opplysninger om brukeren.
                </Alert>
            ) : null}

            <TicketPurchaseValidationAlerts validation={customer.ticketPurchaseValidation} />

            <Grid container spacing={2} direction={"row"}>
                <Grid item xs={6} md={6}>
                    <PropertyList>
                        <Property title={"Registrert"} value={<TimestampText value={customer.createdTime} />} />

                        <Property title={"Mobilnummer"} value={customer.phoneNumberFormatted} />
                        <Property
                            title={"HjemJobbHjem"}
                            value={customer.hasHJH ? "Ja" : customer.phoneNumber ? "Nei" : "-"}
                        />

                        <Property title={"Gruppe"} value={customer.researchGroup} />

                        <Property
                            title={"Telemetri-data"}
                            value={
                                customer.telemetryDataUploadConsentGiven
                                    ? "Bruker har samtykket til opplasting av telemetri-data"
                                    : "Mangler samtykke til opplasting av telemetri-data"
                            }
                        />
                    </PropertyList>
                </Grid>
                <Grid item xs={6} md={6}>
                    <PropertyList>
                        <Property title={"Rabatt"} value={customer.discount + "%"} />
                    </PropertyList>
                </Grid>
            </Grid>
        </div>
    );
}

function TicketPurchaseValidationAlerts(props: { validation: Customer["ticketPurchaseValidation"] }) {
    if (props.validation.cardMissing) {
        return (
            <Alert severity="error" style={{ marginBottom: "20px" }}>
                <b>Sperret!</b> Kunden må registrere en gyldig betalingsmåte.
            </Alert>
        );
    } else if (props.validation.unpaidInvoices.length > 0) {
        const unpaidAmount = sumBy(props.validation.unpaidInvoices, "totalAmount");
        let invoicesByTicket = Object.values(groupBy(props.validation.unpaidInvoices, (invoice) => invoice.ticket.id));
        invoicesByTicket = sortBy(invoicesByTicket, (ticketInvoices) => ticketInvoices[0].ticket.id);

        return (
            <Alert severity="error" style={{ marginBottom: "20px" }}>
                <b>Sperret!</b> Kunden må betale utestående beløp, {formatMoney(unpaidAmount)}, før de kan kjøpe
                billetter:
                <ul>
                    {invoicesByTicket.map((ticketInvoices) => {
                        const ticket = ticketInvoices[0].ticket;
                        return (
                            <li key={ticket.id}>
                                <Typography variant={"body2"}>
                                    Billett{" "}
                                    <MaterialLink component={Link} to={"/ticket/" + ticket.id}>
                                        {formatTicketId(ticket.id)}
                                    </MaterialLink>
                                    {", kjøpt "}
                                    <TimestampText value={ticket.createdTime} />
                                    {", utestående beløp = "}
                                    {formatMoney(sumBy(ticketInvoices, "totalAmount"))}
                                </Typography>
                                <ul>
                                    {ticketInvoices.map((invoice) => {
                                        return (
                                            <li>
                                                Invoice {invoice.invoiceNumber}.{" Beløp = "}
                                                {formatMoney(invoice.totalAmount)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </Alert>
        );
    } else {
        return null;
    }
}
