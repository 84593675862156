import React, { useState } from "react";
import { SendReceiptEmailDialog } from "./SendReceiptEmailDialog";
import { Button, ButtonGroup, Snackbar } from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { isLearningEnvironment } from "../../util/active_user_context";

export type SendReceiptEmailButtonProps = {
    ticketId?: number;
    transactionId?: number;
};

export function SendReceiptEmailButton(props: SendReceiptEmailButtonProps) {
    const [modal, setModal] = useState<React.ReactNode>(null);
    if (isLearningEnvironment()) return;

    const handleClick = () => {
        setModal(
            <SendReceiptEmailDialog
                {...props}
                onClose={() => setModal(null)}
                onSuccess={(email) => setModal(<Snackbar content={"E-post sendt til " + email} />)}
            />,
        );
    };

    return (
        <ButtonGroup variant="outlined">
            <Button startIcon={<EmailIcon />} onClick={handleClick}>
                Send kvittering på e-post
            </Button>

            <Button
                startIcon={<PictureAsPdfIcon />}
                href={
                    props.transactionId
                        ? "/api/admin/transaction/receipt?transactionId=" + props.transactionId
                        : "/api/admin/ticket/receipt?ticketId=" + props.ticketId
                }
            >
                Kvittering
            </Button>

            {modal}
        </ButtonGroup>
    );
}
