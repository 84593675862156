import React, { memo, useCallback, useEffect, useMemo } from "react";
import {
    Activity,
    Position_AndroidLocationProvider,
    TelemetryEventType,
    TicketBeaconScan,
    TicketRecordedPosition,
    TransportMode,
    VehicleTransportMode
} from "../../../generated/gql/graphql";
import { Ticket, TicketLegWithDescription, useTicketPageV2Context } from "../../ticket_v2/context";
import TreeMap from "ts-treemap";
import { capitalize, findIndex, isEqual, maxBy, sortBy, uniq } from "lodash";
import { getLegColor, TicketMapController } from "./map_controller";
import { parseTime, parseTimeOrNull } from "../../../util/date_time";
import { Alert, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { toLatLng } from "../../../util/coordinates";
import { TransportModeIcon } from "./vehicle_snapshot_layer";
import { DateTime } from "luxon";
import BluetoothDisabledIcon from "@mui/icons-material/BluetoothDisabled";
import { AlertColor } from "@mui/material/Alert/Alert";
import { isSuperUser } from "../../../util/active_user_context";
import { QrCodeVisibility, TicketTelemetryEvent } from "../../../generated_proto/no/kolumbus/sanntid_api/telemetry";
import { formatPercentage } from "../../../util/formatting";
import { getTimeGaps } from "../../../util/time_gaps";

export function PhonePositionLog(props: { controller: TicketMapController }) {
    return <PhonePositionLogMemoized
        controller={props.controller}
        selectedRecordedPosition={props.controller.state.selectedRecordedPosition}
        positionLog={props.controller.positionLog}
        legs={props.controller.legs}
        filterProvider={props.controller.state.filterProvider}
    />;
}

const PhonePositionLogMemoized = memo<{
    controller: TicketMapController,
    positionLog: ReadonlyArray<TicketRecordedPosition>,
    legs: ReadonlyArray<TicketLegWithDescription>,
    selectedRecordedPosition?: TicketRecordedPosition | null,
    filterProvider?: Position_AndroidLocationProvider | null,
}>(({ controller, positionLog, legs, selectedRecordedPosition, filterProvider }) => {
    const context = useTicketPageV2Context();
    const ticket = context.ticket;


    const events: ReadonlyArray<LogItem> = useMemo(() => {
        return getEventItems(ticket, legs, positionLog);
    }, [ticket, legs, positionLog]);

    const onSelect = useCallback((v: TicketRecordedPosition) => {
        const isSamePosition = v == controller.state.selectedRecordedPosition;
        controller.setState({
            currentTime: parseTime(v.recordedTime),
            selectedRecordedPosition: v,
            currentMapPosition: toLatLng(v)
        });
        const map = controller.map!;
        map.panTo(toLatLng(v));

        if (isSamePosition) {
            map.setZoom(map.getZoom()! + 1);
        } else if (map.getZoom()! < 13) {
            map.setZoom(15);
        }

    }, [controller]);

    const onGoToTime = useCallback((time: DateTime) => {
        controller.goToTime(time, false);
    }, [controller]);
    const onMouseEnter = useCallback((v: TicketRecordedPosition) => {
        controller.panToInclude(v);
        controller.setState({ phonePositionHovering: v });
        // controller.map!.setZoom(13);
    }, [controller]);
    const onMouseLeave = useCallback((v: TicketRecordedPosition) => {
        if (v == controller.state.phonePositionHovering)
            controller.setState({ phonePositionHovering: undefined });
    }, [controller]);
    useEffect(() => {
        if (controller.state.selectedRecordedPosition) {
            document.getElementById(controller.state.selectedRecordedPosition!.uuid)?.scrollIntoView();
        }
    }, [controller.map]);

    return <div style={{
        width: "400px",
        overflowY: "auto"
    }}>


        <FormControlLabel
            control={<Checkbox checked={filterProvider === "GPS_PROVIDER"} onChange={(_, checked) => {
                controller.setState({ filterProvider: checked ? Position_AndroidLocationProvider.GpsProvider : undefined });
            }} />} label="Filter GPS_PROVIDER"
            disabled={!ticket.positionLog.find((p) => p.androidLocationProvider === "GPS_PROVIDER")}
        />

        <table style={{ width: "100%" }} cellPadding={0} cellSpacing={0}>
            <thead>
            <tr>
                <td>Tidspunkt</td>
                <td>Aktivitet</td>
                <td>GPS nøyaktighet</td>
                <td />
                <td width={70}>Beacons</td>
            </tr>
            </thead>
            <tbody>
            {events.map(event => {
                return <RecordedPosition
                    key={event.id}
                    ticket={ticket}
                    event={event}
                    selected={event.position ? event.position === selectedRecordedPosition : false}
                    onSelectPosition={onSelect}
                    onGoToTime={onGoToTime}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />;
            })}
            </tbody>
        </table>
    </div>;
});

function beaconToVehicleId(beacon: TicketBeaconScan["beacons"][0]): string {
    if (beacon.vehicleId) return beacon.vehicleId.toString();
    if (beacon.transportMode == VehicleTransportMode.Rail) return "";
    return beacon.major + "+" + beacon.minor;
}

function BeaconScanColumn(props: { scan: TicketBeaconScan }) {
    function formatMode(mode: VehicleTransportMode | null) {
        const modeBeacons = props.scan.beacons.filter((e) => (e.transportMode || null) == mode);
        if (modeBeacons.length == 0) return null;
        const vehicleIds = uniq(modeBeacons
            .map(beaconToVehicleId)
            .filter((v) => !!v));

        return <div style={{ whiteSpace: "nowrap" }}>
            <TransportModeIcon transportMode={mode} size={"small"} />
            {vehicleIds.join(", ")}
        </div>;

    }

    if (props.scan.beacons.length == 0) {
        return <BluetoothDisabledIcon />;
    }

    return [
        formatMode(VehicleTransportMode.Bus),
        formatMode(VehicleTransportMode.Rail),
        formatMode(VehicleTransportMode.Water),
        formatMode(null)
    ];
}


const RecordedPosition = memo<{
    selected: boolean,
    ticket: Ticket,
    event: LogItem,
    onSelectPosition: (position: TicketRecordedPosition) => void,
    onGoToTime: (time: DateTime) => void,
    onMouseEnter: (position: TicketRecordedPosition) => void,
    onMouseLeave: (position: TicketRecordedPosition) => void,
}>(({ selected, ticket, event, onSelectPosition, onGoToTime, onMouseEnter, onMouseLeave }) => {
    const color = event.legIndex !== undefined ? getLegColor(event.legIndex)[500] : undefined;
    const duringTicket = event.recordedTime.toMillis() >= parseTime(ticket.createdTime).toMillis() && (!ticket.endedTime || event.recordedTime.toMillis() <= parseTime(ticket.endedTime!).toMillis());
    const recordedTime = event.recordedTime;
    const position = event.position;


    const onClick = position ? () => onSelectPosition(position) : () => onGoToTime(event.recordedTime);
    const style: React.CSSProperties = {
        cursor: "pointer",
        background: selected ? "#DDD" : color,
        color: color !== undefined ? "white" : undefined,
        opacity: duringTicket ? undefined : "0.5"
    };

    return [
        <tr
            key={event.id}
            id={event.id}
            onClick={onClick}
            onMouseEnter={position ? () => onMouseEnter(position) : undefined}
            onMouseLeave={position ? () => onMouseLeave(position) : undefined}
            style={style}
        >
            <td>{recordedTime.toFormat("HH:mm:ss")}</td>
            <td>{event.activity?.replace("ACTIVITY_", "")}</td>
            {position ? [
                <td align={"right"}>
                    {position.accuracy != null
                        ? position.accuracy.toFixed(0) + " m"
                        : null}
                </td>,
                <td>
                    {position.androidLocationProvider.replace("UNKNOWN_PROVIDER", "").replace("_PROVIDER", "")}
                </td>
            ] : <td colSpan={2}>
                {event.title}
            </td>
            }
            <td>
                {event.beaconScan ? <BeaconScanColumn scan={event.beaconScan} /> : null}
            </td>
        </tr>,
        event.node ? <tr
            onClick={onClick}
            style={style}>
            <td colSpan={5}>{event.node}</td>
        </tr> : undefined
    ];
});


function getEventItems(ticket: Ticket, legs: ReadonlyArray<TicketLegWithDescription>, positionLog: ReadonlyArray<TicketRecordedPosition>): LogItem[] {
    const activityMap = new TreeMap<String, Activity>();
    for (const activity of ticket.activityLog) {
        activityMap.set(activity.recordedTime, activity.activity);
    }

    const beaconScansMap = new TreeMap<string, TicketBeaconScan[]>();

    for (const scan of ticket.beaconScans) {
        const list = beaconScansMap.get(scan.recordedTime) || [];
        list.push(scan);
        beaconScansMap.set(scan.recordedTime, list);
    }

    function buildLogItem(event: LogItem): LogItem {
        const isoTimestamp = event.recordedTime.toUTC().toISO() as string;

        const activity = activityMap.floorEntry(isoTimestamp);
        event.activity = activity?.[1];

        if (event.beaconScan === undefined) {
            const beaconScan = beaconScanFor(event.recordedTime, beaconScansMap);
            if (beaconScan)
                event.beaconScan = beaconScan;
        }

        if (event.legIndex === undefined) {
            let legIndex: number | null = findIndex(legs, (leg) => {
                return parseTime(leg.startedTime).toMillis() <= event.recordedTime.toMillis() && event.recordedTime.toMillis() <= parseTime(leg.description!.endedTime).toMillis();
            });
            if (legIndex !== -1) {
                event.legIndex = legIndex;
                event.leg = legs[legIndex];
            }
        }

        return event;
    }

    const log: LogItem[] = [];

    if (isSuperUser()) {
        log.push(buildLogItem({
            id: "purchase",
            recordedTime: parseTime(ticket.createdTime),
            node: <Typography variant={"h6"}>
                Billett startet
            </Typography>
        }));


        if (ticket.endedTime) {
            log.push(buildLogItem({
                id: "ended",
                recordedTime: parseTime(ticket.endedTime),
                node: <div><Typography variant={"h6"}>
                    Billett avsluttet
                </Typography>
                    <Typography>
                        {ticket.endedReason}
                    </Typography>
                </div>
            }));
        }

        legs.forEach((leg, index) => {
            log.push(buildLogItem({
                id: leg.id + "started",
                recordedTime: parseTime(leg.startedTime),
                leg,
                legIndex: index,
                node: <TicketLegStartEnd legIndex={index} leg={leg} />
            }));
            log.push(buildLogItem({
                id: leg.id + "ended",
                recordedTime: parseTime(leg.description.endedTime),
                leg,
                legIndex: index,
                node: <TicketLegStartEnd ended legIndex={index} leg={leg}
                />
            }));
        });
    }


    positionLog.forEach((position) => {
        log.push(buildLogItem({
            id: position.uuid,
            recordedTime: parseTime(position.recordedTime),
            position: position
        }));
    });


    if (isSuperUser()) {
        const eventTimestamps = new TreeMap<string, string>();
        positionLog.forEach((position) => {
            eventTimestamps.set(position.recordedTime, "true");
        });

        const positionLogGaps = getTimeGaps(parseTime(ticket.createdTime), parseTimeOrNull(ticket.endedTime), positionLog.map((e) => parseTime(e.recordedTime)), 120_000);
        positionLogGaps.forEach(([startTime, endTime], index) => {
            log.push(buildLogItem({
                id: "time-gap-" + index,
                recordedTime: startTime,
                node: <Alert variant={"filled"} color={"warning"}>
                    {"Mangler posisjonsdata i " + endTime.diff(startTime).toFormat("mm:ss")}
                </Alert>
            }));
        });

        filterVisibleTicketEvents(ticket.ticketEvents || []).forEach((eventWindow: TicketEventWindow) => {
            const event = eventWindow[1];
            log.push(buildLogItem({
                id: event.uuid,
                recordedTime: parseTime(event.recordedTime),
                node: <TicketTelemetryEventRow ticket={ticket} eventWindow={eventWindow} />
            }));
        });

        let previous: TicketBeaconScan | null = null;
        ticket.beaconScans.forEach((beaconScan) => {
            // const time = parseTime(beaconScan.registeredTimeSystem || beaconScan.recordedTime);
            const time = parseTime(beaconScan.recordedTime);
            const closest = getClosestItem(time, eventTimestamps, 15000);
            const scanDifferent = isDifferent(previous, beaconScan);
            if (scanDifferent || !closest) {
                const item = buildLogItem({
                    id: beaconScan.uuid,
                    recordedTime: time,
                    beaconScan,
                    title: scanDifferent ? "Beacon scan changed" : "Beacon event"
                });
                eventTimestamps.set(time.toISO(), "true");
                log.push(item);
            }
            previous = beaconScan;
        });
    }

    return sortBy(log, (e) => e.recordedTime.toMillis());
}

type LogItem = {
    id: string;
    recordedTime: DateTime;

    legIndex?: number;
    leg?: TicketLegWithDescription;

    node?: React.ReactNode;
    title?: React.ReactNode;

    legStarted?: TicketLegWithDescription;
    legEnded?: TicketLegWithDescription;

    position?: TicketRecordedPosition;

    activity?: Activity;
    beaconScan?: TicketBeaconScan;
}

function TicketLegStartEnd(props: { legIndex: number, leg: TicketLegWithDescription, ended?: boolean }) {
    return <div style={{ padding: "20px" }}>
        <Typography variant={"h6"}>
            Strekkning {props.legIndex + 1}. Gikk {props.ended ? "av " : "ombord "}
            {formatTransportMode(props.leg.description.journey.transportMode).toLowerCase()}
        </Typography>
        {props.leg.description.vehicleId}
        <br />
        {props.leg.description.journey.line.publicCode} {props.leg.description.journey.destination}
        <br />
        {props.leg.description.fromStopTime.platform.name}
        <br />
        {props.leg.description.toStopTime.platform.name}
    </div>;
}

function formatTransportMode(transportMode: TransportMode): string {
    switch (transportMode) {
        case TransportMode.Bus:
            return "Buss";
        case TransportMode.Water:
            return "Båt";
        case TransportMode.Rail:
            return "Tog";
        case TransportMode.None:
            return "?";
    }
}

function beaconScanFor(recordedTime: DateTime, treeMap: TreeMap<string, TicketBeaconScan[]>): TicketBeaconScan | null {
    return maxBy(getClosestItem(recordedTime, treeMap) || [], (scan) => scan.beacons.length || 0) || null;
}

function getClosestItem<T>(recordedTime: DateTime, treeMap: TreeMap<string, T>, maxTimeDiffMillis: number = 5000): T | null {
    const timestamp = recordedTime.toUTC().toISO() as string;
    let floorEntry = treeMap.floorEntry(timestamp);
    let ceilEntry = treeMap.ceilingEntry(timestamp) || floorEntry;

    if (floorEntry && ceilEntry) {
        const floorDiff = Math.abs(recordedTime.diff(parseTime(floorEntry[0]!)).toMillis());
        const ceilDiff = Math.abs(recordedTime.diff(parseTime(ceilEntry[0]!)).toMillis());

        if (floorDiff > maxTimeDiffMillis) {
            floorEntry = undefined;
        }
        if (ceilDiff > maxTimeDiffMillis) {
            ceilEntry = undefined;
        }

        if (floorDiff < ceilDiff) {
            return floorEntry?.[1] || null;
        } else {
            return ceilEntry?.[1] || null;
        }
    } else {
        return null;
    }
}

function isDifferent(previous: TicketBeaconScan | null, beaconScan: TicketBeaconScan) {
    if (previous === null) return true;

    const previousVehicleIds = new Set(previous.beacons.map(e => e.vehicleId || e.transportMode));
    const vehicleIds = new Set(beaconScan.beacons.map(e => e.vehicleId || e.transportMode));

    return !isEqual(previousVehicleIds, vehicleIds);
}

type TicketEvent = NonNullable<Ticket["ticketEvents"]>[0];


function shouldShowActiveVehicleDetected(previous: TicketEvent | null, event: TicketEvent): boolean {
    if (previous === null) return true;
    const previousJson = parseTelemetryEvent(previous).activeVehicleDetected;
    const json = parseTelemetryEvent(event).activeVehicleDetected;

    return previousJson?.recognizedTransportMode !== json?.recognizedTransportMode
        || previousJson?.vehiclePosition?.vehicleId !== json?.vehiclePosition?.vehicleId;
}

type TicketEventWindow = [TicketEvent | null, TicketEvent, TicketEvent | null];

function window(ticketEvents: TicketEvent[]): TicketEventWindow[] {
    let previous: TicketEvent | null = null;
    let windows: TicketEventWindow[] = [];

    ticketEvents.forEach((event, index) => {
        windows.push([previous, event, ticketEvents[index + 1] || null]);
        previous = event;
    });
    return windows;
}

function filterVisibleTicketEvents(events: ReadonlyArray<TicketEvent>): TicketEventWindow[] {
    let output: TicketEventWindow[] = [];

    window(events.filter((e) => e.type == TelemetryEventType.QrCodeVisibilityChanged))
        .filter(([_, event]) => parseTelemetryEvent(event).qrCodeVisibility !== QrCodeVisibility.QR_INVISIBLE)
        .forEach((w) => output.push(w));

    window(events.filter((e) => e.type == TelemetryEventType.AndroidApplicationStarted))
        .forEach((w) => output.push(w));

    window(events.filter((e) => e.type == TelemetryEventType.ActiveVehicleDetected))
        .filter(([previous, event]) => shouldShowActiveVehicleDetected(previous, event))
        .forEach((w) => output.push(w));

    return sortBy(output, (e) => e[1].recordedTime);

}

function parseTelemetryEvent(previous: TicketEvent): TicketTelemetryEvent {
    return TicketTelemetryEvent.fromJSON(JSON.parse(previous.jsonSerialized)) as TicketTelemetryEvent;
}

function TicketTelemetryEventRow(props: { ticket: Ticket, eventWindow: TicketEventWindow }) {
    const event = props.eventWindow[1];
    const json = parseTelemetryEvent(event);

    switch (event.type) {
        case TelemetryEventType.ActiveVehicleDetected:
            const v = json.activeVehicleDetected?.vehiclePosition;
            return <Alert variant={"filled"} color={"info"}>
                <Typography variant={"subtitle1"}>Active vehicle detected
                    ({formatPercentage(json.activeVehicleDetected?.confidence)})</Typography>
                <Typography>
                    Transport mode: {json.activeVehicleDetected?.recognizedTransportMode}
                </Typography>
                {v && v.vehicleId ? <Typography>
                    Vehicle: {v.vehicleId}
                </Typography> : null}
                {v?.lineName ? <Typography>
                    Line: {v.lineName + " " + v.destination}
                </Typography> : null}
                {v?.tripId?.tripId ? <Typography>
                    TripId: {v.tripId?.tripId}
                </Typography> : null}
            </Alert>;
        case TelemetryEventType.AndroidApplicationStarted:
            let color: AlertColor = "info";
            const process = json.androidStartInfo?.activityManagerDump?.process as unknown as string || "main";

            const flags = [];

            if (json.androidStartInfo?.activityManagerDump?.isBackgroundRestricted) {
                flags.push(<Typography>isBackgroundRestricted = true</Typography>);
                color = "warning";
            }

            if (json.androidStartInfo?.powerManagerDump?.isPowerSaveMode) {
                color = "warning";
                flags.push(<Typography>isPowerSaveMode = true</Typography>);
            }

            const exitReason = json.androidStartInfo?.activityManagerDump?.ticketProcessExitInfo;
            if (exitReason) {
                const timestampMillis = DateTime.fromMillis((exitReason.timestamp?.seconds || 0) * 1000);
                if (timestampMillis > parseTime(props.ticket.createdTime)) {
                    flags.push(
                        <Typography>
                            <b>Ticket process exit reason: </b>{exitReason.reason}<br />
                            description={exitReason.description}. <br />
                            exitTime={timestampMillis.toFormat("HH:mm:ss")}
                        </Typography>
                    );
                }
            }

            return <Alert variant={"filled"} color={color}>
                <Typography variant={"subtitle1"}>
                    {capitalize(process.toLowerCase())} process restarted
                </Typography>
                {flags.map((f) => <Typography>{f}</Typography>)}
            </Alert>;

        case TelemetryEventType.QrCodeVisibilityChanged:
            console.log(event);
            console.log(json);
            let visibility = "";
            switch (json.qrCodeVisibility) {
                case QrCodeVisibility.QR_VISIBLE:
                    visibility = "synlig";
                    break;
                case QrCodeVisibility.QR_EXPANDED:
                    visibility = "utvidet";
                    break;
                case QrCodeVisibility.QR_INVISIBLE:
                    visibility = "skjult";
                    break;
            }
            const next = props.eventWindow[2] ? props.eventWindow[2] : null;
            return <Alert variant={"filled"} color={"success"}>
                <Typography variant={"h6"}>
                    QR kode {visibility}
                </Typography>
                {next && parseTelemetryEvent(next).qrCodeVisibility === QrCodeVisibility.QR_INVISIBLE ?
                    <Typography>Synlig {parseTime(next.recordedTime).diff(parseTime(event.recordedTime)).toFormat("mm:ss")}</Typography>
                    : null}
            </Alert>;
    }
}