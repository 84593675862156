import React, { memo, useEffect, useMemo, useRef } from "react";
import { minBy } from "lodash";
import { calculateDistance, createBounds, toLatLng } from "../../util/coordinates";
import { parseTime } from "../../util/date_time";
import { DateTime } from "luxon";
import { TimeField } from "@mui/x-date-pickers";
import { useTicketPageV2Context } from "../ticket_v2/context";
import { useLegsMapLayer } from "./map/legs_map_layer";
import { TicketMapControllerImpl } from "./map/map_controller";
import { useVehicleSnapshotLayer } from "./map/vehicle_snapshot_layer";
import { usePhonePositionPolylineLayer } from "./map/phone_polyline_layer";
import { useLegsVehiclePolylineLayer } from "./map/legs_vehicle_polyline_layer";
import { PhonePositionLog } from "./map/phone_position_log";
import { TicketMapFooter } from "./map/footer";
import { CircularProgress, Paper, Snackbar, Typography } from "@mui/material";
import { useKeyPressEvent } from "react-use";
import { usePhonePositionMarkerLayer } from "./map/phone_position_marker";


export const TicketDataView = memo(() => {
    const context = useTicketPageV2Context();
    const mapContainer = useRef<HTMLDivElement>(null);
    let mapRef = useRef<google.maps.Map | null>(null);
    let map = mapRef.current;

    const controller = useMemo(() => new TicketMapControllerImpl(context), []);
    controller.map = map;
    controller.useHook();
    useLoadMap(controller, mapContainer, mapRef);

    const {modal} = controller.state;

    usePhonePositionMarkerLayer(controller);
    usePhonePositionPolylineLayer(controller);

    useLegsMapLayer(controller);
    useLegsVehiclePolylineLayer(controller);
    useVehicleSnapshotLayer(controller);


    return <div style={{
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        height: "100%"
    }}>
        <PhonePositionLog
            controller={controller}
        />
        <div style={{

            flex: "1",
            display: "flex",
            flexFlow: "column",
            alignItems: "stretch"
        }}>
            <div style={{position: "absolute", right: "10px", top: "10px", zIndex: "10"}}>
                <TicketMapOverlay controller={controller} />
            </div>
            <div ref={mapContainer} style={{flex: "1"}} />
            <TicketMapFooter controller={controller} />
        </div>
        {modal}
    </div>;
});

function useLoadMap(controller: TicketMapControllerImpl, mapContainer: React.RefObject<HTMLDivElement | null>, mapRef: React.RefObject<google.maps.Map | null>) {

    useEffect(() => {
        const map = new google.maps.Map(mapContainer.current!, {
            center: controller.state.currentMapPosition,
            zoom: 11,
            mapId: "4292df02952fc03c"
        });
        mapRef.current = map;
        map.addListener("click", (event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
            const pos = event.latLng;
            if (!pos) return;
            const closest = minBy(controller.positionLog, (p) => calculateDistance(p, pos));
            if (!closest) return;

            controller.setState({
                currentTime: parseTime(closest.recordedTime),
                selectedRecordedPosition: closest,
                currentMapPosition: event.latLng!.toJSON()
            });
            map?.panTo(toLatLng(closest));
            document.getElementById(closest.uuid)!.scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
        });

        map.addListener("dragend", () => {
            controller.setState({
                currentMapPosition: map!.getCenter()!.toJSON()
            });
        });

        const bounds = createBounds(controller.positionLog);
        if (bounds) {
            map.fitBounds(bounds, 20);
        }

        /// Ensures that the React tree rebuilds
        controller.setState({});
    }, []);
}

function TicketMapOverlay({controller}: {controller: TicketMapControllerImpl}) {


    useKeyPressEvent("ArrowLeft", () => {
        controller.goToTime(controller.state.currentTime.minus({minutes: 1}));
        const position = controller.state.selectedRecordedPosition;
        if (position)
            controller.panToInclude(position);
    });
    useKeyPressEvent("ArrowRight", () => {
        controller.goToTime(controller.state.currentTime.plus({minutes: 1}));
        const position = controller.state.selectedRecordedPosition;
        if (position)
            controller.panToInclude(position);
    });

    return <div>
        <Paper>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                open={controller.vehicleSnapshotQuery.loading}
                message={<Typography variant={"body1"} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px"
                }}>
                    <CircularProgress size={16} color={"inherit"} />
                    Laster buss-possisjoner
                </Typography>}
            />
            <TimeField
                ampm={false}
                label={"Start"}
                format="HH:mm:ss"
                referenceDate={controller.state.currentTime.toLocal()}
                value={controller.state.currentTime.toLocal()}
                onChange={(e) => controller.goToTime(e as DateTime)}
            />

        </Paper>
    </div>;
}
