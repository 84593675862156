import React from "react";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Theme, useTheme } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

export interface MultiSelectFilterProps<T> {
    id: string;
    label: React.ReactNode;
    options: ReadonlyArray<T>;
    value: ReadonlyArray<T>;
    onChange: (newValue: ReadonlyArray<T>) => void;
}

function getStyles(option: string, value: ReadonlyArray<string>, theme: Theme) {
    return {
        fontWeight: value.includes(option) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
    };
}

export function MultiSelectFilter<T extends string>(props: MultiSelectFilterProps<T>) {
    const theme = useTheme();
    console.log("filter", props);

    function handleChange(event: SelectChangeEvent<ReadonlyArray<T>>) {
        const v = event.target.value;
        console.log("onChange", v);
        if (typeof v === "string") props.onChange(v.split(",") as unknown as ReadonlyArray<T>);
        else props.onChange(v);
    }

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id={props.id + "-label"}>{props.label}</InputLabel>
            <Select<ReadonlyArray<T>>
                labelId={props.id + "-label"}
                id={props.id}
                multiple
                value={props.value}
                onChange={handleChange}
                input={<OutlinedInput label={props.label} />}
                renderValue={(selected) => [...selected].join(", ")}
                MenuProps={MenuProps}
            >
                {props.options.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, props.value, theme)}>
                        {name}
                        {/*<Checkbox checked={props.value.includes(name)} />*/}
                        {/*<ListItemText primary={name} />*/}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
