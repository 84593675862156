import React from "react";
import {
    ActiveTicketStateLogTabFragment,
    Exact,
    LocalPriceFragment,
    StopTimeLiteFragment,
    TicketPageV2Query,
    TicketStatementHistoryTabFragment,
} from "../../generated/gql/graphql";
import { QueryResult } from "@apollo/react-hooks";

export const TicketPageV2Context = React.createContext<TicketPageV2ContextType | null>(null);

export interface TicketPageV2ContextType {
    query: QueryResult<TicketPageV2Query, Exact<{ id: number }>>;
    ticket: TicketPageV2Query["ticket"] & ActiveTicketStateLogTabFragment & TicketStatementHistoryTabFragment;
    handleRefreshData: () => Promise<unknown>;
}

export function useTicketPageV2ContextOrNull(): TicketPageV2ContextType | null {
    return React.useContext<TicketPageV2ContextType | null>(TicketPageV2Context);
}

export function useTicketPageV2Context(): TicketPageV2ContextType {
    return useTicketPageV2ContextOrNull()!;
}

export type Ticket = TicketPageV2ContextType["ticket"];
export type TicketPrice = Ticket["analysisLocalPrice"][0] & LocalPriceFragment;
export type TicketLeg = TicketPrice["legs"][0];
export type TicketLegWithDescription = TicketLeg & {
    description: {
        fromStopTime: StopTimeLiteFragment;
        toStopTime: StopTimeLiteFragment;
        journey: {
            stopTimes: StopTimeLiteFragment[];
        };
    };
};

export function getLegs(ticket: TicketPageV2ContextType["ticket"]): ReadonlyArray<TicketLeg> {
    const activePrice = ticket.analysisLocalPrice.find((l) => (l as TicketPrice).active) as unknown as TicketPrice;
    if (!activePrice) return [];
    return activePrice.legs as unknown as ReadonlyArray<TicketLeg>;
}

export function getLegsWithDescription(ticket: Ticket): ReadonlyArray<TicketLegWithDescription> {
    return getLegs(ticket).filter((l) => !!l.description) as TicketLegWithDescription[];
}
