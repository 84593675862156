// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: no/kolumbus/sanntid_api/active_ticket_state.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "./common";
import { VehiclePosition } from "./vehicle_data_bulky";

export const protobufPackage = "";

export enum TransportModeActivity {
  ACTIVITY_NONE = "ACTIVITY_NONE",
  ACTIVITY_BUS = "ACTIVITY_BUS",
  ACTIVITY_WATER = "ACTIVITY_WATER",
  ACTIVITY_RAIL = "ACTIVITY_RAIL",
  ACTIVITY_NOT_TRANSIT = "ACTIVITY_NOT_TRANSIT",
  ACTIVITY_UNRECOGNIZED = "ACTIVITY_UNRECOGNIZED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function transportModeActivityFromJSON(object: any): TransportModeActivity {
  switch (object) {
    case 0:
    case "ACTIVITY_NONE":
      return TransportModeActivity.ACTIVITY_NONE;
    case 2:
    case "ACTIVITY_BUS":
      return TransportModeActivity.ACTIVITY_BUS;
    case 4:
    case "ACTIVITY_WATER":
      return TransportModeActivity.ACTIVITY_WATER;
    case 7:
    case "ACTIVITY_RAIL":
      return TransportModeActivity.ACTIVITY_RAIL;
    case 98:
    case "ACTIVITY_NOT_TRANSIT":
      return TransportModeActivity.ACTIVITY_NOT_TRANSIT;
    case 99:
    case "ACTIVITY_UNRECOGNIZED":
      return TransportModeActivity.ACTIVITY_UNRECOGNIZED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TransportModeActivity.UNRECOGNIZED;
  }
}

export function transportModeActivityToJSON(object: TransportModeActivity): string {
  switch (object) {
    case TransportModeActivity.ACTIVITY_NONE:
      return "ACTIVITY_NONE";
    case TransportModeActivity.ACTIVITY_BUS:
      return "ACTIVITY_BUS";
    case TransportModeActivity.ACTIVITY_WATER:
      return "ACTIVITY_WATER";
    case TransportModeActivity.ACTIVITY_RAIL:
      return "ACTIVITY_RAIL";
    case TransportModeActivity.ACTIVITY_NOT_TRANSIT:
      return "ACTIVITY_NOT_TRANSIT";
    case TransportModeActivity.ACTIVITY_UNRECOGNIZED:
      return "ACTIVITY_UNRECOGNIZED";
    case TransportModeActivity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function transportModeActivityToNumber(object: TransportModeActivity): number {
  switch (object) {
    case TransportModeActivity.ACTIVITY_NONE:
      return 0;
    case TransportModeActivity.ACTIVITY_BUS:
      return 2;
    case TransportModeActivity.ACTIVITY_WATER:
      return 4;
    case TransportModeActivity.ACTIVITY_RAIL:
      return 7;
    case TransportModeActivity.ACTIVITY_NOT_TRANSIT:
      return 98;
    case TransportModeActivity.ACTIVITY_UNRECOGNIZED:
      return 99;
    case TransportModeActivity.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ActiveTicketState {
  mode: ActiveTicketState_Mode;
  timestamp: Timestamp | undefined;
  errorMessage: string;
  stackTrace: string;
  modelInputTensors: RealTimeModelInputTensorData | undefined;
}

export enum ActiveTicketState_Mode {
  /** INACTIVE - / The ticket has been started, but the user is yet to board a vehicle */
  INACTIVE = "INACTIVE",
  /** EMBARKED - / The user is currently on a vehicle */
  EMBARKED = "EMBARKED",
  /** DISEMBARKED - / The user has disembarked a vehicle. */
  DISEMBARKED = "DISEMBARKED",
  /** ENDED - / The ticket has ended. */
  ENDED = "ENDED",
  /** SYSTEM_INTERRUPTED - The ticket session is forcibly ended by the OS. This value is set when Service.onDestroy is called. */
  SYSTEM_INTERRUPTED = "SYSTEM_INTERRUPTED",
  /** RESTARTED - The ticket session has been restarted by START_REDELIVER_INTENT */
  RESTARTED = "RESTARTED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function activeTicketState_ModeFromJSON(object: any): ActiveTicketState_Mode {
  switch (object) {
    case 0:
    case "INACTIVE":
      return ActiveTicketState_Mode.INACTIVE;
    case 1:
    case "EMBARKED":
      return ActiveTicketState_Mode.EMBARKED;
    case 2:
    case "DISEMBARKED":
      return ActiveTicketState_Mode.DISEMBARKED;
    case 3:
    case "ENDED":
      return ActiveTicketState_Mode.ENDED;
    case 4:
    case "SYSTEM_INTERRUPTED":
      return ActiveTicketState_Mode.SYSTEM_INTERRUPTED;
    case 5:
    case "RESTARTED":
      return ActiveTicketState_Mode.RESTARTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ActiveTicketState_Mode.UNRECOGNIZED;
  }
}

export function activeTicketState_ModeToJSON(object: ActiveTicketState_Mode): string {
  switch (object) {
    case ActiveTicketState_Mode.INACTIVE:
      return "INACTIVE";
    case ActiveTicketState_Mode.EMBARKED:
      return "EMBARKED";
    case ActiveTicketState_Mode.DISEMBARKED:
      return "DISEMBARKED";
    case ActiveTicketState_Mode.ENDED:
      return "ENDED";
    case ActiveTicketState_Mode.SYSTEM_INTERRUPTED:
      return "SYSTEM_INTERRUPTED";
    case ActiveTicketState_Mode.RESTARTED:
      return "RESTARTED";
    case ActiveTicketState_Mode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function activeTicketState_ModeToNumber(object: ActiveTicketState_Mode): number {
  switch (object) {
    case ActiveTicketState_Mode.INACTIVE:
      return 0;
    case ActiveTicketState_Mode.EMBARKED:
      return 1;
    case ActiveTicketState_Mode.DISEMBARKED:
      return 2;
    case ActiveTicketState_Mode.ENDED:
      return 3;
    case ActiveTicketState_Mode.SYSTEM_INTERRUPTED:
      return 4;
    case ActiveTicketState_Mode.RESTARTED:
      return 5;
    case ActiveTicketState_Mode.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface RealTimeModelInputTensorData {
  xPurchaseTimeTensor: Uint8Array;
  xTransportModeTensor: Uint8Array;
  xVehicleTensor: Uint8Array;
  xPlatformTensor: Uint8Array;
}

export interface ActiveVehicleDetected {
  recognizedTransportMode: TransportModeActivity;
  vehiclePosition: VehiclePosition | undefined;
  confidence: number;
  endConfidence: number;
  rawEndConfidence: number;
  forbidEndTicketReason: string;
  purchasedTime:
    | Timestamp
    | undefined;
  /**
   * / https://kolumbus.atlassian.net/browse/SV-1799
   * / Hold billett aktiv ved guiding
   */
  navigationLockedUntil: Timestamp | undefined;
}

function createBaseActiveTicketState(): ActiveTicketState {
  return {
    mode: ActiveTicketState_Mode.INACTIVE,
    timestamp: undefined,
    errorMessage: "",
    stackTrace: "",
    modelInputTensors: undefined,
  };
}

export const ActiveTicketState: MessageFns<ActiveTicketState> = {
  encode(message: ActiveTicketState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.mode !== ActiveTicketState_Mode.INACTIVE) {
      writer.uint32(8).int32(activeTicketState_ModeToNumber(message.mode));
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(message.timestamp, writer.uint32(58).fork()).join();
    }
    if (message.errorMessage !== "") {
      writer.uint32(66).string(message.errorMessage);
    }
    if (message.stackTrace !== "") {
      writer.uint32(74).string(message.stackTrace);
    }
    if (message.modelInputTensors !== undefined) {
      RealTimeModelInputTensorData.encode(message.modelInputTensors, writer.uint32(90).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ActiveTicketState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActiveTicketState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.mode = activeTicketState_ModeFromJSON(reader.int32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.timestamp = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.stackTrace = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.modelInputTensors = RealTimeModelInputTensorData.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActiveTicketState {
    return {
      mode: isSet(object.mode) ? activeTicketState_ModeFromJSON(object.mode) : ActiveTicketState_Mode.INACTIVE,
      timestamp: isSet(object.timestamp) ? Timestamp.fromJSON(object.timestamp) : undefined,
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      stackTrace: isSet(object.stackTrace) ? globalThis.String(object.stackTrace) : "",
      modelInputTensors: isSet(object.modelInputTensors)
        ? RealTimeModelInputTensorData.fromJSON(object.modelInputTensors)
        : undefined,
    };
  },

  toJSON(message: ActiveTicketState): unknown {
    const obj: any = {};
    if (message.mode !== ActiveTicketState_Mode.INACTIVE) {
      obj.mode = activeTicketState_ModeToJSON(message.mode);
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = Timestamp.toJSON(message.timestamp);
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.stackTrace !== "") {
      obj.stackTrace = message.stackTrace;
    }
    if (message.modelInputTensors !== undefined) {
      obj.modelInputTensors = RealTimeModelInputTensorData.toJSON(message.modelInputTensors);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActiveTicketState>, I>>(base?: I): ActiveTicketState {
    return ActiveTicketState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActiveTicketState>, I>>(object: I): ActiveTicketState {
    const message = createBaseActiveTicketState();
    message.mode = object.mode ?? ActiveTicketState_Mode.INACTIVE;
    message.timestamp = (object.timestamp !== undefined && object.timestamp !== null)
      ? Timestamp.fromPartial(object.timestamp)
      : undefined;
    message.errorMessage = object.errorMessage ?? "";
    message.stackTrace = object.stackTrace ?? "";
    message.modelInputTensors = (object.modelInputTensors !== undefined && object.modelInputTensors !== null)
      ? RealTimeModelInputTensorData.fromPartial(object.modelInputTensors)
      : undefined;
    return message;
  },
};

function createBaseRealTimeModelInputTensorData(): RealTimeModelInputTensorData {
  return {
    xPurchaseTimeTensor: new Uint8Array(0),
    xTransportModeTensor: new Uint8Array(0),
    xVehicleTensor: new Uint8Array(0),
    xPlatformTensor: new Uint8Array(0),
  };
}

export const RealTimeModelInputTensorData: MessageFns<RealTimeModelInputTensorData> = {
  encode(message: RealTimeModelInputTensorData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.xPurchaseTimeTensor.length !== 0) {
      writer.uint32(10).bytes(message.xPurchaseTimeTensor);
    }
    if (message.xTransportModeTensor.length !== 0) {
      writer.uint32(18).bytes(message.xTransportModeTensor);
    }
    if (message.xVehicleTensor.length !== 0) {
      writer.uint32(26).bytes(message.xVehicleTensor);
    }
    if (message.xPlatformTensor.length !== 0) {
      writer.uint32(34).bytes(message.xPlatformTensor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RealTimeModelInputTensorData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRealTimeModelInputTensorData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.xPurchaseTimeTensor = reader.bytes();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.xTransportModeTensor = reader.bytes();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.xVehicleTensor = reader.bytes();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.xPlatformTensor = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RealTimeModelInputTensorData {
    return {
      xPurchaseTimeTensor: isSet(object.xPurchaseTimeTensor)
        ? bytesFromBase64(object.xPurchaseTimeTensor)
        : new Uint8Array(0),
      xTransportModeTensor: isSet(object.xTransportModeTensor)
        ? bytesFromBase64(object.xTransportModeTensor)
        : new Uint8Array(0),
      xVehicleTensor: isSet(object.xVehicleTensor) ? bytesFromBase64(object.xVehicleTensor) : new Uint8Array(0),
      xPlatformTensor: isSet(object.xPlatformTensor) ? bytesFromBase64(object.xPlatformTensor) : new Uint8Array(0),
    };
  },

  toJSON(message: RealTimeModelInputTensorData): unknown {
    const obj: any = {};
    if (message.xPurchaseTimeTensor.length !== 0) {
      obj.xPurchaseTimeTensor = base64FromBytes(message.xPurchaseTimeTensor);
    }
    if (message.xTransportModeTensor.length !== 0) {
      obj.xTransportModeTensor = base64FromBytes(message.xTransportModeTensor);
    }
    if (message.xVehicleTensor.length !== 0) {
      obj.xVehicleTensor = base64FromBytes(message.xVehicleTensor);
    }
    if (message.xPlatformTensor.length !== 0) {
      obj.xPlatformTensor = base64FromBytes(message.xPlatformTensor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RealTimeModelInputTensorData>, I>>(base?: I): RealTimeModelInputTensorData {
    return RealTimeModelInputTensorData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RealTimeModelInputTensorData>, I>>(object: I): RealTimeModelInputTensorData {
    const message = createBaseRealTimeModelInputTensorData();
    message.xPurchaseTimeTensor = object.xPurchaseTimeTensor ?? new Uint8Array(0);
    message.xTransportModeTensor = object.xTransportModeTensor ?? new Uint8Array(0);
    message.xVehicleTensor = object.xVehicleTensor ?? new Uint8Array(0);
    message.xPlatformTensor = object.xPlatformTensor ?? new Uint8Array(0);
    return message;
  },
};

function createBaseActiveVehicleDetected(): ActiveVehicleDetected {
  return {
    recognizedTransportMode: TransportModeActivity.ACTIVITY_NONE,
    vehiclePosition: undefined,
    confidence: 0,
    endConfidence: 0,
    rawEndConfidence: 0,
    forbidEndTicketReason: "",
    purchasedTime: undefined,
    navigationLockedUntil: undefined,
  };
}

export const ActiveVehicleDetected: MessageFns<ActiveVehicleDetected> = {
  encode(message: ActiveVehicleDetected, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.recognizedTransportMode !== TransportModeActivity.ACTIVITY_NONE) {
      writer.uint32(32).int32(transportModeActivityToNumber(message.recognizedTransportMode));
    }
    if (message.vehiclePosition !== undefined) {
      VehiclePosition.encode(message.vehiclePosition, writer.uint32(10).fork()).join();
    }
    if (message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    if (message.endConfidence !== 0) {
      writer.uint32(45).float(message.endConfidence);
    }
    if (message.rawEndConfidence !== 0) {
      writer.uint32(93).float(message.rawEndConfidence);
    }
    if (message.forbidEndTicketReason !== "") {
      writer.uint32(98).string(message.forbidEndTicketReason);
    }
    if (message.purchasedTime !== undefined) {
      Timestamp.encode(message.purchasedTime, writer.uint32(122).fork()).join();
    }
    if (message.navigationLockedUntil !== undefined) {
      Timestamp.encode(message.navigationLockedUntil, writer.uint32(242).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ActiveVehicleDetected {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActiveVehicleDetected();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.recognizedTransportMode = transportModeActivityFromJSON(reader.int32());
          continue;
        }
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vehiclePosition = VehiclePosition.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
        }
        case 5: {
          if (tag !== 45) {
            break;
          }

          message.endConfidence = reader.float();
          continue;
        }
        case 11: {
          if (tag !== 93) {
            break;
          }

          message.rawEndConfidence = reader.float();
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.forbidEndTicketReason = reader.string();
          continue;
        }
        case 15: {
          if (tag !== 122) {
            break;
          }

          message.purchasedTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 30: {
          if (tag !== 242) {
            break;
          }

          message.navigationLockedUntil = Timestamp.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActiveVehicleDetected {
    return {
      recognizedTransportMode: isSet(object.recognizedTransportMode)
        ? transportModeActivityFromJSON(object.recognizedTransportMode)
        : TransportModeActivity.ACTIVITY_NONE,
      vehiclePosition: isSet(object.vehiclePosition) ? VehiclePosition.fromJSON(object.vehiclePosition) : undefined,
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      endConfidence: isSet(object.endConfidence) ? globalThis.Number(object.endConfidence) : 0,
      rawEndConfidence: isSet(object.rawEndConfidence) ? globalThis.Number(object.rawEndConfidence) : 0,
      forbidEndTicketReason: isSet(object.forbidEndTicketReason) ? globalThis.String(object.forbidEndTicketReason) : "",
      purchasedTime: isSet(object.purchasedTime) ? Timestamp.fromJSON(object.purchasedTime) : undefined,
      navigationLockedUntil: isSet(object.navigationLockedUntil)
        ? Timestamp.fromJSON(object.navigationLockedUntil)
        : undefined,
    };
  },

  toJSON(message: ActiveVehicleDetected): unknown {
    const obj: any = {};
    if (message.recognizedTransportMode !== TransportModeActivity.ACTIVITY_NONE) {
      obj.recognizedTransportMode = transportModeActivityToJSON(message.recognizedTransportMode);
    }
    if (message.vehiclePosition !== undefined) {
      obj.vehiclePosition = VehiclePosition.toJSON(message.vehiclePosition);
    }
    if (message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.endConfidence !== 0) {
      obj.endConfidence = message.endConfidence;
    }
    if (message.rawEndConfidence !== 0) {
      obj.rawEndConfidence = message.rawEndConfidence;
    }
    if (message.forbidEndTicketReason !== "") {
      obj.forbidEndTicketReason = message.forbidEndTicketReason;
    }
    if (message.purchasedTime !== undefined) {
      obj.purchasedTime = Timestamp.toJSON(message.purchasedTime);
    }
    if (message.navigationLockedUntil !== undefined) {
      obj.navigationLockedUntil = Timestamp.toJSON(message.navigationLockedUntil);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActiveVehicleDetected>, I>>(base?: I): ActiveVehicleDetected {
    return ActiveVehicleDetected.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActiveVehicleDetected>, I>>(object: I): ActiveVehicleDetected {
    const message = createBaseActiveVehicleDetected();
    message.recognizedTransportMode = object.recognizedTransportMode ?? TransportModeActivity.ACTIVITY_NONE;
    message.vehiclePosition = (object.vehiclePosition !== undefined && object.vehiclePosition !== null)
      ? VehiclePosition.fromPartial(object.vehiclePosition)
      : undefined;
    message.confidence = object.confidence ?? 0;
    message.endConfidence = object.endConfidence ?? 0;
    message.rawEndConfidence = object.rawEndConfidence ?? 0;
    message.forbidEndTicketReason = object.forbidEndTicketReason ?? "";
    message.purchasedTime = (object.purchasedTime !== undefined && object.purchasedTime !== null)
      ? Timestamp.fromPartial(object.purchasedTime)
      : undefined;
    message.navigationLockedUntil =
      (object.navigationLockedUntil !== undefined && object.navigationLockedUntil !== null)
        ? Timestamp.fromPartial(object.navigationLockedUntil)
        : undefined;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
