import React, { useState } from "react";
import { DateTimeFormat, parseTime } from "../../util/date_time";
import { Link as MaterialLink } from "@mui/material";
import { RelativeTimeText } from "../../widgets/date_time";
import { DateTime } from "luxon";
import { useTicketPageV2Context } from "../ticket_v2/context";
import { sortBy } from "lodash";

export function TicketEventsListView() {
    const context = useTicketPageV2Context();
    const ticket = context.ticket;
    const list = sortBy(ticket.ticketEvents || [], "registeredTime");

    return <table width={"100%"}>
        <thead>
        <tr>
            <th style={{width: "230px"}}>Recorded time</th>
            <th style={{ width: "230px" }}>Registered time</th>
            <th>Type</th>
            <th>Json</th>
        </tr>
        </thead>
        <tbody>
        {list?.map((event) => {
            return <tr key={event.uuid}>
                <td style={{whiteSpace: "nowrap"}}>
                    <RelativeTimeText
                        includeSeconds
                        value={event.recordedTime}
                        relativeTo={ticket.createdTime}
                    />
                    {event.registeredTime ? formatTelemetryDelay(parseTime(event.recordedTime), parseTime(event.registeredTime)) : null}
                </td>
                <td>
                    <RelativeTimeText
                        includeSeconds
                        value={event.registeredTime}
                        relativeTo={ticket.createdTime}
                    />
                </td>
                <td>{event.type}</td>
                <td>{event.providerName}</td>
                <td><RawProtobufExpandable json={event.jsonSerialized} /></td>
            </tr>;
        })}
        </tbody>
    </table>;
}

export function RawProtobufExpandable(props: {json: string}): React.ReactElement {
    const [expanded, setExpanded] = useState(false);

    if (props.json) {
        return <MaterialLink onClick={() => setExpanded(!expanded)}>
            {expanded ? <pre>{props.json}</pre> : props.json}
        </MaterialLink>;
    } else {
        return <>null</>;
    }
}

export function formatTelemetryDelay(recordedTime: DateTime, registeredTime: DateTime) {
    const delaySeconds = Math.round(registeredTime.diff(recordedTime).as("seconds"));

    return <abbr
        style={Math.abs(delaySeconds) > 3 ? {fontWeight: "bold"} : {}}
        title={"RegisteredTime: " + registeredTime.toFormat(DateTimeFormat.TIME)}
    >
        {" (" + delaySeconds + "s)"}
    </abbr>;
}
