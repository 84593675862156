import { getLegColor, TicketMapController } from "./map_controller";
import { TransportModeIcon } from "./vehicle_snapshot_layer";
import React from "react";
import { VehiclePositionFragment } from "../../../generated/gql/graphql";
import { sortBy } from "lodash";
import { calculateDistance, LatLng, toLatLngObject } from "../../../util/coordinates";
import { Button, Typography } from "@mui/material";

export function TicketMapFooter({controller}: {controller: TicketMapController}) {
    return <div style={{padding: "8px 24px"}}>
        <Typography variant={"h6"}>
            Opprett legg fra reisen:
        </Typography>

        <Button onClick={() => controller.onJourneyCatalogButtonClick({
            position: toLatLngObject(controller.state.currentMapPosition),
            timestamp: controller.state.currentTime.toISO() as string
        })}>
            Journey catalog
        </Button>
        <NearbyVehiclesList
            controller={controller}
        />
    </div>;
}

function NearbyVehiclesList({controller}: {controller: TicketMapController,}) {
    const position = controller.state.currentMapPosition;
    const vehicleSnapshot = controller.state.vehicleSnapshot;
    if (!vehicleSnapshot) return;

    let positions: ReadonlyArray<VehiclePositionFragment> = vehicleSnapshot?.vehicles as unknown as ReadonlyArray<VehiclePositionFragment>;
    const nearbyVehicles = sortBy(positions, vehicle => calculateDistance(vehicle, position!));

    const {vehicleId} = controller.state;

    return nearbyVehicles
        .filter((v, index) => v.vehicleId === vehicleId || index < 20)
        .map(vehicle => {

            const selected = controller.state.vehicleId === vehicle.vehicleId;
            const legIndex = controller.getLegMatching(vehicle)?.["1"] ?? -1;

            return <NearbyVehicleButton
                key={vehicle.vehicleId}
                vehiclePosition={vehicle}
                controller={controller}
                selected={selected} legIndex={legIndex}
                currentMapPosition={controller.state.currentMapPosition}
            />;
        });
}

const NearbyVehicleButton = React.memo<{
    controller: TicketMapController
    vehiclePosition: VehiclePositionFragment,
    selected: boolean,
    legIndex: number,
    currentMapPosition: LatLng
}>(({controller, vehiclePosition: v, selected, legIndex, currentMapPosition}) => {
    return <Button
        variant={selected || legIndex !== -1 ? "contained" : "outlined"}
        style={{
            marginRight: "20px",
            textTransform: "none",
            background: legIndex !== -1 ? getLegColor(legIndex)[700] : undefined
        }}
        onClick={() => controller.onNewLegButtonClick(v)}
        onMouseOver={() => {
            controller.setState({
                vehicleIdFocused: v.vehicleId
            });
        }}
        onMouseOut={() => {
            if (v.vehicleId === controller.state.vehicleIdFocused) {
                controller.setState({vehicleIdFocused: undefined});
            }
        }}
        startIcon={<TransportModeIcon transportMode={v.transportMode} />}
    >
        {v.linePublicCode || v.tripId ?
            v.linePublicCode + " (" + v.tripId + ", " + v.vehicleId + ")" : v.vehicleId}

        <span style={{marginLeft: "5px"}}>
            {calculateDistance(v, currentMapPosition).toFixed(0) + "m"}
        </span>
    </Button>;
});

