// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: no/kolumbus/sanntid_api/telemetry_apple.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "./common";

export const protobufPackage = "";

export interface AppleLocationVisit {
  arrivalTime: Timestamp | undefined;
  departureTime: Timestamp | undefined;
  latitude: number;
  longitude: number;
  accuracy: number;
}

function createBaseAppleLocationVisit(): AppleLocationVisit {
  return { arrivalTime: undefined, departureTime: undefined, latitude: 0, longitude: 0, accuracy: 0 };
}

export const AppleLocationVisit: MessageFns<AppleLocationVisit> = {
  encode(message: AppleLocationVisit, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.arrivalTime !== undefined) {
      Timestamp.encode(message.arrivalTime, writer.uint32(10).fork()).join();
    }
    if (message.departureTime !== undefined) {
      Timestamp.encode(message.departureTime, writer.uint32(18).fork()).join();
    }
    if (message.latitude !== 0) {
      writer.uint32(29).float(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(37).float(message.longitude);
    }
    if (message.accuracy !== 0) {
      writer.uint32(45).float(message.accuracy);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AppleLocationVisit {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAppleLocationVisit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.arrivalTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.departureTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 29) {
            break;
          }

          message.latitude = reader.float();
          continue;
        }
        case 4: {
          if (tag !== 37) {
            break;
          }

          message.longitude = reader.float();
          continue;
        }
        case 5: {
          if (tag !== 45) {
            break;
          }

          message.accuracy = reader.float();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AppleLocationVisit {
    return {
      arrivalTime: isSet(object.arrivalTime) ? Timestamp.fromJSON(object.arrivalTime) : undefined,
      departureTime: isSet(object.departureTime) ? Timestamp.fromJSON(object.departureTime) : undefined,
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      accuracy: isSet(object.accuracy) ? globalThis.Number(object.accuracy) : 0,
    };
  },

  toJSON(message: AppleLocationVisit): unknown {
    const obj: any = {};
    if (message.arrivalTime !== undefined) {
      obj.arrivalTime = Timestamp.toJSON(message.arrivalTime);
    }
    if (message.departureTime !== undefined) {
      obj.departureTime = Timestamp.toJSON(message.departureTime);
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.accuracy !== 0) {
      obj.accuracy = message.accuracy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AppleLocationVisit>, I>>(base?: I): AppleLocationVisit {
    return AppleLocationVisit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AppleLocationVisit>, I>>(object: I): AppleLocationVisit {
    const message = createBaseAppleLocationVisit();
    message.arrivalTime = (object.arrivalTime !== undefined && object.arrivalTime !== null)
      ? Timestamp.fromPartial(object.arrivalTime)
      : undefined;
    message.departureTime = (object.departureTime !== undefined && object.departureTime !== null)
      ? Timestamp.fromPartial(object.departureTime)
      : undefined;
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.accuracy = object.accuracy ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
