// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v3.12.4
// source: no/kolumbus/sanntid_api/telemetry.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { ActivityManagerDump } from "../../../reisefrihet/android/activity_manager_dump";
import { PowerManagerDump } from "../../../reisefrihet/android/power_manager_dump";
import { ActiveTicketState, ActiveVehicleDetected } from "./active_ticket_state";
import {
  TicketEndedReason,
  ticketEndedReasonFromJSON,
  ticketEndedReasonToJSON,
  ticketEndedReasonToNumber,
  Timestamp,
} from "./common";
import { AndroidLocationProviderState } from "./telemetry_android";
import { AppleLocationVisit } from "./telemetry_apple";

export const protobufPackage = "";

export enum TicketTelemetryEventProvider {
  UNSPECIFIED = "UNSPECIFIED",
  ANDROID_CTX_ACTIVE_TICKET = "ANDROID_CTX_ACTIVE_TICKET",
  ANDROID_LOCATION_UPLOADER_WORKER = "ANDROID_LOCATION_UPLOADER_WORKER",
  ANDROID_TICKET_LOCATION_RECEIVER = "ANDROID_TICKET_LOCATION_RECEIVER",
  ANDROID_BEACON_SCAN_RECEIVER = "ANDROID_BEACON_SCAN_RECEIVER",
  ANDROID_TICKET_ACTIVITY_RECOGNITION_RECEIVER = "ANDROID_TICKET_ACTIVITY_RECOGNITION_RECEIVER",
  ANDROID_TICKET_TELEMETRY_RECEIVER = "ANDROID_TICKET_TELEMETRY_RECEIVER",
  ANDROID_END_TICKET_BUTTON_RECEIVER = "ANDROID_END_TICKET_BUTTON_RECEIVER",
  ANDROID_TICKET_TELEMETRY_SERVICE = "ANDROID_TICKET_TELEMETRY_SERVICE",
  ANDROID_TICKET_TELEMETRY_MANAGER = "ANDROID_TICKET_TELEMETRY_MANAGER",
  ANDROID_TICKET_ENDING_SEQUENCE = "ANDROID_TICKET_ENDING_SEQUENCE",
  ANDROID_TICKET_FOREGROUND_BUFFER_SERVICE = "ANDROID_TICKET_FOREGROUND_BUFFER_SERVICE",
  ANDROID_MY_TRIPS_LOGGER_SERVICE = "ANDROID_MY_TRIPS_LOGGER_SERVICE",
  ANDROID_APPLICATION_START_EVENT_HANDLER = "ANDROID_APPLICATION_START_EVENT_HANDLER",
  IOS_SENSOR_FALLBACK = "IOS_SENSOR_FALLBACK",
  IOS_FLUTTER_ACTIVE_VEHICLE = "IOS_FLUTTER_ACTIVE_VEHICLE",
  IOS_TICKET_SESSION = "IOS_TICKET_SESSION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function ticketTelemetryEventProviderFromJSON(object: any): TicketTelemetryEventProvider {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return TicketTelemetryEventProvider.UNSPECIFIED;
    case 1:
    case "ANDROID_CTX_ACTIVE_TICKET":
      return TicketTelemetryEventProvider.ANDROID_CTX_ACTIVE_TICKET;
    case 2:
    case "ANDROID_LOCATION_UPLOADER_WORKER":
      return TicketTelemetryEventProvider.ANDROID_LOCATION_UPLOADER_WORKER;
    case 3:
    case "ANDROID_TICKET_LOCATION_RECEIVER":
      return TicketTelemetryEventProvider.ANDROID_TICKET_LOCATION_RECEIVER;
    case 7:
    case "ANDROID_BEACON_SCAN_RECEIVER":
      return TicketTelemetryEventProvider.ANDROID_BEACON_SCAN_RECEIVER;
    case 12:
    case "ANDROID_TICKET_ACTIVITY_RECOGNITION_RECEIVER":
      return TicketTelemetryEventProvider.ANDROID_TICKET_ACTIVITY_RECOGNITION_RECEIVER;
    case 9:
    case "ANDROID_TICKET_TELEMETRY_RECEIVER":
      return TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_RECEIVER;
    case 11:
    case "ANDROID_END_TICKET_BUTTON_RECEIVER":
      return TicketTelemetryEventProvider.ANDROID_END_TICKET_BUTTON_RECEIVER;
    case 6:
    case "ANDROID_TICKET_TELEMETRY_SERVICE":
      return TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_SERVICE;
    case 4:
    case "ANDROID_TICKET_TELEMETRY_MANAGER":
      return TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_MANAGER;
    case 8:
    case "ANDROID_TICKET_ENDING_SEQUENCE":
      return TicketTelemetryEventProvider.ANDROID_TICKET_ENDING_SEQUENCE;
    case 10:
    case "ANDROID_TICKET_FOREGROUND_BUFFER_SERVICE":
      return TicketTelemetryEventProvider.ANDROID_TICKET_FOREGROUND_BUFFER_SERVICE;
    case 5:
    case "ANDROID_MY_TRIPS_LOGGER_SERVICE":
      return TicketTelemetryEventProvider.ANDROID_MY_TRIPS_LOGGER_SERVICE;
    case 24:
    case "ANDROID_APPLICATION_START_EVENT_HANDLER":
      return TicketTelemetryEventProvider.ANDROID_APPLICATION_START_EVENT_HANDLER;
    case 20:
    case "IOS_SENSOR_FALLBACK":
      return TicketTelemetryEventProvider.IOS_SENSOR_FALLBACK;
    case 21:
    case "IOS_FLUTTER_ACTIVE_VEHICLE":
      return TicketTelemetryEventProvider.IOS_FLUTTER_ACTIVE_VEHICLE;
    case 22:
    case "IOS_TICKET_SESSION":
      return TicketTelemetryEventProvider.IOS_TICKET_SESSION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TicketTelemetryEventProvider.UNRECOGNIZED;
  }
}

export function ticketTelemetryEventProviderToJSON(object: TicketTelemetryEventProvider): string {
  switch (object) {
    case TicketTelemetryEventProvider.UNSPECIFIED:
      return "UNSPECIFIED";
    case TicketTelemetryEventProvider.ANDROID_CTX_ACTIVE_TICKET:
      return "ANDROID_CTX_ACTIVE_TICKET";
    case TicketTelemetryEventProvider.ANDROID_LOCATION_UPLOADER_WORKER:
      return "ANDROID_LOCATION_UPLOADER_WORKER";
    case TicketTelemetryEventProvider.ANDROID_TICKET_LOCATION_RECEIVER:
      return "ANDROID_TICKET_LOCATION_RECEIVER";
    case TicketTelemetryEventProvider.ANDROID_BEACON_SCAN_RECEIVER:
      return "ANDROID_BEACON_SCAN_RECEIVER";
    case TicketTelemetryEventProvider.ANDROID_TICKET_ACTIVITY_RECOGNITION_RECEIVER:
      return "ANDROID_TICKET_ACTIVITY_RECOGNITION_RECEIVER";
    case TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_RECEIVER:
      return "ANDROID_TICKET_TELEMETRY_RECEIVER";
    case TicketTelemetryEventProvider.ANDROID_END_TICKET_BUTTON_RECEIVER:
      return "ANDROID_END_TICKET_BUTTON_RECEIVER";
    case TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_SERVICE:
      return "ANDROID_TICKET_TELEMETRY_SERVICE";
    case TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_MANAGER:
      return "ANDROID_TICKET_TELEMETRY_MANAGER";
    case TicketTelemetryEventProvider.ANDROID_TICKET_ENDING_SEQUENCE:
      return "ANDROID_TICKET_ENDING_SEQUENCE";
    case TicketTelemetryEventProvider.ANDROID_TICKET_FOREGROUND_BUFFER_SERVICE:
      return "ANDROID_TICKET_FOREGROUND_BUFFER_SERVICE";
    case TicketTelemetryEventProvider.ANDROID_MY_TRIPS_LOGGER_SERVICE:
      return "ANDROID_MY_TRIPS_LOGGER_SERVICE";
    case TicketTelemetryEventProvider.ANDROID_APPLICATION_START_EVENT_HANDLER:
      return "ANDROID_APPLICATION_START_EVENT_HANDLER";
    case TicketTelemetryEventProvider.IOS_SENSOR_FALLBACK:
      return "IOS_SENSOR_FALLBACK";
    case TicketTelemetryEventProvider.IOS_FLUTTER_ACTIVE_VEHICLE:
      return "IOS_FLUTTER_ACTIVE_VEHICLE";
    case TicketTelemetryEventProvider.IOS_TICKET_SESSION:
      return "IOS_TICKET_SESSION";
    case TicketTelemetryEventProvider.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function ticketTelemetryEventProviderToNumber(object: TicketTelemetryEventProvider): number {
  switch (object) {
    case TicketTelemetryEventProvider.UNSPECIFIED:
      return 0;
    case TicketTelemetryEventProvider.ANDROID_CTX_ACTIVE_TICKET:
      return 1;
    case TicketTelemetryEventProvider.ANDROID_LOCATION_UPLOADER_WORKER:
      return 2;
    case TicketTelemetryEventProvider.ANDROID_TICKET_LOCATION_RECEIVER:
      return 3;
    case TicketTelemetryEventProvider.ANDROID_BEACON_SCAN_RECEIVER:
      return 7;
    case TicketTelemetryEventProvider.ANDROID_TICKET_ACTIVITY_RECOGNITION_RECEIVER:
      return 12;
    case TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_RECEIVER:
      return 9;
    case TicketTelemetryEventProvider.ANDROID_END_TICKET_BUTTON_RECEIVER:
      return 11;
    case TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_SERVICE:
      return 6;
    case TicketTelemetryEventProvider.ANDROID_TICKET_TELEMETRY_MANAGER:
      return 4;
    case TicketTelemetryEventProvider.ANDROID_TICKET_ENDING_SEQUENCE:
      return 8;
    case TicketTelemetryEventProvider.ANDROID_TICKET_FOREGROUND_BUFFER_SERVICE:
      return 10;
    case TicketTelemetryEventProvider.ANDROID_MY_TRIPS_LOGGER_SERVICE:
      return 5;
    case TicketTelemetryEventProvider.ANDROID_APPLICATION_START_EVENT_HANDLER:
      return 24;
    case TicketTelemetryEventProvider.IOS_SENSOR_FALLBACK:
      return 20;
    case TicketTelemetryEventProvider.IOS_FLUTTER_ACTIVE_VEHICLE:
      return 21;
    case TicketTelemetryEventProvider.IOS_TICKET_SESSION:
      return 22;
    case TicketTelemetryEventProvider.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum TelemetryEventType {
  POSITION_RECORDED = "POSITION_RECORDED",
  /** @deprecated */
  BATTERY_LEVEL_RECORDED = "BATTERY_LEVEL_RECORDED",
  /** @deprecated */
  CONNECTIVITY_CHANGED = "CONNECTIVITY_CHANGED",
  TICKET_ENDED = "TICKET_ENDED",
  ACTIVITY_RECOGNIZED = "ACTIVITY_RECOGNIZED",
  ACTIVE_VEHICLE_DETECTED = "ACTIVE_VEHICLE_DETECTED",
  /** @deprecated */
  DEVICE_METADATA = "DEVICE_METADATA",
  BEACON_SCAN = "BEACON_SCAN",
  ACTIVE_TICKET_STATE = "ACTIVE_TICKET_STATE",
  /** @deprecated */
  CELL_INFO_RECORDED = "CELL_INFO_RECORDED",
  LOCATION_VISIT = "LOCATION_VISIT",
  BEACON_MONITOR = "BEACON_MONITOR",
  /** @deprecated */
  ANALYSIS_QUESTION_ANSWERED = "ANALYSIS_QUESTION_ANSWERED",
  QR_CODE_VISIBILITY_CHANGED = "QR_CODE_VISIBILITY_CHANGED",
  ANDROID_LOCATION_PROVIDER_STATE = "ANDROID_LOCATION_PROVIDER_STATE",
  ANDROID_APPLICATION_STARTED = "ANDROID_APPLICATION_STARTED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function telemetryEventTypeFromJSON(object: any): TelemetryEventType {
  switch (object) {
    case 0:
    case "POSITION_RECORDED":
      return TelemetryEventType.POSITION_RECORDED;
    case 1:
    case "BATTERY_LEVEL_RECORDED":
      return TelemetryEventType.BATTERY_LEVEL_RECORDED;
    case 2:
    case "CONNECTIVITY_CHANGED":
      return TelemetryEventType.CONNECTIVITY_CHANGED;
    case 3:
    case "TICKET_ENDED":
      return TelemetryEventType.TICKET_ENDED;
    case 4:
    case "ACTIVITY_RECOGNIZED":
      return TelemetryEventType.ACTIVITY_RECOGNIZED;
    case 7:
    case "ACTIVE_VEHICLE_DETECTED":
      return TelemetryEventType.ACTIVE_VEHICLE_DETECTED;
    case 8:
    case "DEVICE_METADATA":
      return TelemetryEventType.DEVICE_METADATA;
    case 9:
    case "BEACON_SCAN":
      return TelemetryEventType.BEACON_SCAN;
    case 10:
    case "ACTIVE_TICKET_STATE":
      return TelemetryEventType.ACTIVE_TICKET_STATE;
    case 11:
    case "CELL_INFO_RECORDED":
      return TelemetryEventType.CELL_INFO_RECORDED;
    case 13:
    case "LOCATION_VISIT":
      return TelemetryEventType.LOCATION_VISIT;
    case 14:
    case "BEACON_MONITOR":
      return TelemetryEventType.BEACON_MONITOR;
    case 18:
    case "ANALYSIS_QUESTION_ANSWERED":
      return TelemetryEventType.ANALYSIS_QUESTION_ANSWERED;
    case 19:
    case "QR_CODE_VISIBILITY_CHANGED":
      return TelemetryEventType.QR_CODE_VISIBILITY_CHANGED;
    case 20:
    case "ANDROID_LOCATION_PROVIDER_STATE":
      return TelemetryEventType.ANDROID_LOCATION_PROVIDER_STATE;
    case 21:
    case "ANDROID_APPLICATION_STARTED":
      return TelemetryEventType.ANDROID_APPLICATION_STARTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TelemetryEventType.UNRECOGNIZED;
  }
}

export function telemetryEventTypeToJSON(object: TelemetryEventType): string {
  switch (object) {
    case TelemetryEventType.POSITION_RECORDED:
      return "POSITION_RECORDED";
    case TelemetryEventType.BATTERY_LEVEL_RECORDED:
      return "BATTERY_LEVEL_RECORDED";
    case TelemetryEventType.CONNECTIVITY_CHANGED:
      return "CONNECTIVITY_CHANGED";
    case TelemetryEventType.TICKET_ENDED:
      return "TICKET_ENDED";
    case TelemetryEventType.ACTIVITY_RECOGNIZED:
      return "ACTIVITY_RECOGNIZED";
    case TelemetryEventType.ACTIVE_VEHICLE_DETECTED:
      return "ACTIVE_VEHICLE_DETECTED";
    case TelemetryEventType.DEVICE_METADATA:
      return "DEVICE_METADATA";
    case TelemetryEventType.BEACON_SCAN:
      return "BEACON_SCAN";
    case TelemetryEventType.ACTIVE_TICKET_STATE:
      return "ACTIVE_TICKET_STATE";
    case TelemetryEventType.CELL_INFO_RECORDED:
      return "CELL_INFO_RECORDED";
    case TelemetryEventType.LOCATION_VISIT:
      return "LOCATION_VISIT";
    case TelemetryEventType.BEACON_MONITOR:
      return "BEACON_MONITOR";
    case TelemetryEventType.ANALYSIS_QUESTION_ANSWERED:
      return "ANALYSIS_QUESTION_ANSWERED";
    case TelemetryEventType.QR_CODE_VISIBILITY_CHANGED:
      return "QR_CODE_VISIBILITY_CHANGED";
    case TelemetryEventType.ANDROID_LOCATION_PROVIDER_STATE:
      return "ANDROID_LOCATION_PROVIDER_STATE";
    case TelemetryEventType.ANDROID_APPLICATION_STARTED:
      return "ANDROID_APPLICATION_STARTED";
    case TelemetryEventType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function telemetryEventTypeToNumber(object: TelemetryEventType): number {
  switch (object) {
    case TelemetryEventType.POSITION_RECORDED:
      return 0;
    case TelemetryEventType.BATTERY_LEVEL_RECORDED:
      return 1;
    case TelemetryEventType.CONNECTIVITY_CHANGED:
      return 2;
    case TelemetryEventType.TICKET_ENDED:
      return 3;
    case TelemetryEventType.ACTIVITY_RECOGNIZED:
      return 4;
    case TelemetryEventType.ACTIVE_VEHICLE_DETECTED:
      return 7;
    case TelemetryEventType.DEVICE_METADATA:
      return 8;
    case TelemetryEventType.BEACON_SCAN:
      return 9;
    case TelemetryEventType.ACTIVE_TICKET_STATE:
      return 10;
    case TelemetryEventType.CELL_INFO_RECORDED:
      return 11;
    case TelemetryEventType.LOCATION_VISIT:
      return 13;
    case TelemetryEventType.BEACON_MONITOR:
      return 14;
    case TelemetryEventType.ANALYSIS_QUESTION_ANSWERED:
      return 18;
    case TelemetryEventType.QR_CODE_VISIBILITY_CHANGED:
      return 19;
    case TelemetryEventType.ANDROID_LOCATION_PROVIDER_STATE:
      return 20;
    case TelemetryEventType.ANDROID_APPLICATION_STARTED:
      return 21;
    case TelemetryEventType.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum QrCodeVisibility {
  QR_INVISIBLE = "QR_INVISIBLE",
  QR_VISIBLE = "QR_VISIBLE",
  QR_EXPANDED = "QR_EXPANDED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function qrCodeVisibilityFromJSON(object: any): QrCodeVisibility {
  switch (object) {
    case 0:
    case "QR_INVISIBLE":
      return QrCodeVisibility.QR_INVISIBLE;
    case 1:
    case "QR_VISIBLE":
      return QrCodeVisibility.QR_VISIBLE;
    case 2:
    case "QR_EXPANDED":
      return QrCodeVisibility.QR_EXPANDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QrCodeVisibility.UNRECOGNIZED;
  }
}

export function qrCodeVisibilityToJSON(object: QrCodeVisibility): string {
  switch (object) {
    case QrCodeVisibility.QR_INVISIBLE:
      return "QR_INVISIBLE";
    case QrCodeVisibility.QR_VISIBLE:
      return "QR_VISIBLE";
    case QrCodeVisibility.QR_EXPANDED:
      return "QR_EXPANDED";
    case QrCodeVisibility.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function qrCodeVisibilityToNumber(object: QrCodeVisibility): number {
  switch (object) {
    case QrCodeVisibility.QR_INVISIBLE:
      return 0;
    case QrCodeVisibility.QR_VISIBLE:
      return 1;
    case QrCodeVisibility.QR_EXPANDED:
      return 2;
    case QrCodeVisibility.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum Activity {
  IN_VEHICLE = "IN_VEHICLE",
  ON_BICYCLE = "ON_BICYCLE",
  ON_FOOT = "ON_FOOT",
  RUNNING = "RUNNING",
  STILL = "STILL",
  TILTING = "TILTING",
  WALKING = "WALKING",
  ACTIVITY_UNKNOWN = "ACTIVITY_UNKNOWN",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function activityFromJSON(object: any): Activity {
  switch (object) {
    case 0:
    case "IN_VEHICLE":
      return Activity.IN_VEHICLE;
    case 1:
    case "ON_BICYCLE":
      return Activity.ON_BICYCLE;
    case 2:
    case "ON_FOOT":
      return Activity.ON_FOOT;
    case 3:
    case "RUNNING":
      return Activity.RUNNING;
    case 4:
    case "STILL":
      return Activity.STILL;
    case 5:
    case "TILTING":
      return Activity.TILTING;
    case 6:
    case "WALKING":
      return Activity.WALKING;
    case 7:
    case "ACTIVITY_UNKNOWN":
      return Activity.ACTIVITY_UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Activity.UNRECOGNIZED;
  }
}

export function activityToJSON(object: Activity): string {
  switch (object) {
    case Activity.IN_VEHICLE:
      return "IN_VEHICLE";
    case Activity.ON_BICYCLE:
      return "ON_BICYCLE";
    case Activity.ON_FOOT:
      return "ON_FOOT";
    case Activity.RUNNING:
      return "RUNNING";
    case Activity.STILL:
      return "STILL";
    case Activity.TILTING:
      return "TILTING";
    case Activity.WALKING:
      return "WALKING";
    case Activity.ACTIVITY_UNKNOWN:
      return "ACTIVITY_UNKNOWN";
    case Activity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function activityToNumber(object: Activity): number {
  switch (object) {
    case Activity.IN_VEHICLE:
      return 0;
    case Activity.ON_BICYCLE:
      return 1;
    case Activity.ON_FOOT:
      return 2;
    case Activity.RUNNING:
      return 3;
    case Activity.STILL:
      return 4;
    case Activity.TILTING:
      return 5;
    case Activity.WALKING:
      return 6;
    case Activity.ACTIVITY_UNKNOWN:
      return 7;
    case Activity.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface TicketTelemetryEvent {
  uuid: string;
  ticketId: string;
  type: TelemetryEventType;
  recordedTime:
    | Timestamp
    | undefined;
  /** / This is the system-reported UNIX timestamp millis. */
  registeredTimeSystemMillis: number;
  provider: TicketTelemetryEventProvider;
  position: Position | undefined;
  endedReason: TicketEndedReason;
  /** Required for types TICKET_ENDED, and ACTIVE_TICKET_STATE. Introduced in 4.22 */
  batteryLevelPercentage: number;
  activity: Activity;
  beaconScan: BeaconScan | undefined;
  activeVehicleDetected: ActiveVehicleDetected | undefined;
  activeTicketState: ActiveTicketState | undefined;
  locationVisit: AppleLocationVisit | undefined;
  beaconMonitorState: BeaconMonitorState | undefined;
  qrCodeVisibility: QrCodeVisibility;
  androidLocationProviderState: AndroidLocationProviderState | undefined;
  androidStartInfo: AndroidApplicationStartInfo | undefined;
  debugMessage: string;
}

export interface BeaconMonitorState {
  major: number;
  minor: number;
  state: BeaconMonitorState_CLRegionState;
  uuid: Uint8Array;
}

export enum BeaconMonitorState_CLRegionState {
  CLRS_UNKNOWN = "CLRS_UNKNOWN",
  CLRS_INSIDE = "CLRS_INSIDE",
  CLRS_OUTSIDE = "CLRS_OUTSIDE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function beaconMonitorState_CLRegionStateFromJSON(object: any): BeaconMonitorState_CLRegionState {
  switch (object) {
    case 0:
    case "CLRS_UNKNOWN":
      return BeaconMonitorState_CLRegionState.CLRS_UNKNOWN;
    case 1:
    case "CLRS_INSIDE":
      return BeaconMonitorState_CLRegionState.CLRS_INSIDE;
    case 2:
    case "CLRS_OUTSIDE":
      return BeaconMonitorState_CLRegionState.CLRS_OUTSIDE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BeaconMonitorState_CLRegionState.UNRECOGNIZED;
  }
}

export function beaconMonitorState_CLRegionStateToJSON(object: BeaconMonitorState_CLRegionState): string {
  switch (object) {
    case BeaconMonitorState_CLRegionState.CLRS_UNKNOWN:
      return "CLRS_UNKNOWN";
    case BeaconMonitorState_CLRegionState.CLRS_INSIDE:
      return "CLRS_INSIDE";
    case BeaconMonitorState_CLRegionState.CLRS_OUTSIDE:
      return "CLRS_OUTSIDE";
    case BeaconMonitorState_CLRegionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function beaconMonitorState_CLRegionStateToNumber(object: BeaconMonitorState_CLRegionState): number {
  switch (object) {
    case BeaconMonitorState_CLRegionState.CLRS_UNKNOWN:
      return 0;
    case BeaconMonitorState_CLRegionState.CLRS_INSIDE:
      return 1;
    case BeaconMonitorState_CLRegionState.CLRS_OUTSIDE:
      return 2;
    case BeaconMonitorState_CLRegionState.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface BeaconScan {
  beacons: BluetoothBeacon[];
}

export interface BluetoothBeacon {
  id2: Uint8Array;
  id3: Uint8Array;
}

export interface Position {
  latitude: number;
  longitude: number;
  accuracy: number;
  /** / iOS only: */
  speed: number;
  speedAccuracy: number;
  altitude: number;
  heading: number;
  /** / Android only: */
  isMock: boolean;
  provider: Position_AndroidLocationProvider;
  providerFallback: string;
}

export enum Position_AndroidLocationProvider {
  UNKNOWN_PROVIDER = "UNKNOWN_PROVIDER",
  GPS_PROVIDER = "GPS_PROVIDER",
  FUSED_PROVIDER = "FUSED_PROVIDER",
  NETWORK_PROVIDER = "NETWORK_PROVIDER",
  PASSIVE_PROVIDER = "PASSIVE_PROVIDER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function position_AndroidLocationProviderFromJSON(object: any): Position_AndroidLocationProvider {
  switch (object) {
    case 0:
    case "UNKNOWN_PROVIDER":
      return Position_AndroidLocationProvider.UNKNOWN_PROVIDER;
    case 1:
    case "GPS_PROVIDER":
      return Position_AndroidLocationProvider.GPS_PROVIDER;
    case 2:
    case "FUSED_PROVIDER":
      return Position_AndroidLocationProvider.FUSED_PROVIDER;
    case 3:
    case "NETWORK_PROVIDER":
      return Position_AndroidLocationProvider.NETWORK_PROVIDER;
    case 4:
    case "PASSIVE_PROVIDER":
      return Position_AndroidLocationProvider.PASSIVE_PROVIDER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Position_AndroidLocationProvider.UNRECOGNIZED;
  }
}

export function position_AndroidLocationProviderToJSON(object: Position_AndroidLocationProvider): string {
  switch (object) {
    case Position_AndroidLocationProvider.UNKNOWN_PROVIDER:
      return "UNKNOWN_PROVIDER";
    case Position_AndroidLocationProvider.GPS_PROVIDER:
      return "GPS_PROVIDER";
    case Position_AndroidLocationProvider.FUSED_PROVIDER:
      return "FUSED_PROVIDER";
    case Position_AndroidLocationProvider.NETWORK_PROVIDER:
      return "NETWORK_PROVIDER";
    case Position_AndroidLocationProvider.PASSIVE_PROVIDER:
      return "PASSIVE_PROVIDER";
    case Position_AndroidLocationProvider.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function position_AndroidLocationProviderToNumber(object: Position_AndroidLocationProvider): number {
  switch (object) {
    case Position_AndroidLocationProvider.UNKNOWN_PROVIDER:
      return 0;
    case Position_AndroidLocationProvider.GPS_PROVIDER:
      return 1;
    case Position_AndroidLocationProvider.FUSED_PROVIDER:
      return 2;
    case Position_AndroidLocationProvider.NETWORK_PROVIDER:
      return 3;
    case Position_AndroidLocationProvider.PASSIVE_PROVIDER:
      return 4;
    case Position_AndroidLocationProvider.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface AndroidApplicationStartInfo {
  activityManagerDump: ActivityManagerDump | undefined;
  powerManagerDump: PowerManagerDump | undefined;
}

function createBaseTicketTelemetryEvent(): TicketTelemetryEvent {
  return {
    uuid: "",
    ticketId: "",
    type: TelemetryEventType.POSITION_RECORDED,
    recordedTime: undefined,
    registeredTimeSystemMillis: 0,
    provider: TicketTelemetryEventProvider.UNSPECIFIED,
    position: undefined,
    endedReason: TicketEndedReason.NOT_ENDED,
    batteryLevelPercentage: 0,
    activity: Activity.IN_VEHICLE,
    beaconScan: undefined,
    activeVehicleDetected: undefined,
    activeTicketState: undefined,
    locationVisit: undefined,
    beaconMonitorState: undefined,
    qrCodeVisibility: QrCodeVisibility.QR_INVISIBLE,
    androidLocationProviderState: undefined,
    androidStartInfo: undefined,
    debugMessage: "",
  };
}

export const TicketTelemetryEvent: MessageFns<TicketTelemetryEvent> = {
  encode(message: TicketTelemetryEvent, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.uuid !== "") {
      writer.uint32(10).string(message.uuid);
    }
    if (message.ticketId !== "") {
      writer.uint32(106).string(message.ticketId);
    }
    if (message.type !== TelemetryEventType.POSITION_RECORDED) {
      writer.uint32(24).int32(telemetryEventTypeToNumber(message.type));
    }
    if (message.recordedTime !== undefined) {
      Timestamp.encode(message.recordedTime, writer.uint32(34).fork()).join();
    }
    if (message.registeredTimeSystemMillis !== 0) {
      writer.uint32(200).uint64(message.registeredTimeSystemMillis);
    }
    if (message.provider !== TicketTelemetryEventProvider.UNSPECIFIED) {
      writer.uint32(208).int32(ticketTelemetryEventProviderToNumber(message.provider));
    }
    if (message.position !== undefined) {
      Position.encode(message.position, writer.uint32(50).fork()).join();
    }
    if (message.endedReason !== TicketEndedReason.NOT_ENDED) {
      writer.uint32(64).int32(ticketEndedReasonToNumber(message.endedReason));
    }
    if (message.batteryLevelPercentage !== 0) {
      writer.uint32(224).uint32(message.batteryLevelPercentage);
    }
    if (message.activity !== Activity.IN_VEHICLE) {
      writer.uint32(72).int32(activityToNumber(message.activity));
    }
    if (message.beaconScan !== undefined) {
      BeaconScan.encode(message.beaconScan, writer.uint32(138).fork()).join();
    }
    if (message.activeVehicleDetected !== undefined) {
      ActiveVehicleDetected.encode(message.activeVehicleDetected, writer.uint32(130).fork()).join();
    }
    if (message.activeTicketState !== undefined) {
      ActiveTicketState.encode(message.activeTicketState, writer.uint32(146).fork()).join();
    }
    if (message.locationVisit !== undefined) {
      AppleLocationVisit.encode(message.locationVisit, writer.uint32(162).fork()).join();
    }
    if (message.beaconMonitorState !== undefined) {
      BeaconMonitorState.encode(message.beaconMonitorState, writer.uint32(170).fork()).join();
    }
    if (message.qrCodeVisibility !== QrCodeVisibility.QR_INVISIBLE) {
      writer.uint32(184).int32(qrCodeVisibilityToNumber(message.qrCodeVisibility));
    }
    if (message.androidLocationProviderState !== undefined) {
      AndroidLocationProviderState.encode(message.androidLocationProviderState, writer.uint32(194).fork()).join();
    }
    if (message.androidStartInfo !== undefined) {
      AndroidApplicationStartInfo.encode(message.androidStartInfo, writer.uint32(234).fork()).join();
    }
    if (message.debugMessage !== "") {
      writer.uint32(218).string(message.debugMessage);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TicketTelemetryEvent {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTicketTelemetryEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.ticketId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.type = telemetryEventTypeFromJSON(reader.int32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.recordedTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 25: {
          if (tag !== 200) {
            break;
          }

          message.registeredTimeSystemMillis = longToNumber(reader.uint64());
          continue;
        }
        case 26: {
          if (tag !== 208) {
            break;
          }

          message.provider = ticketTelemetryEventProviderFromJSON(reader.int32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.position = Position.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.endedReason = ticketEndedReasonFromJSON(reader.int32());
          continue;
        }
        case 28: {
          if (tag !== 224) {
            break;
          }

          message.batteryLevelPercentage = reader.uint32();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.activity = activityFromJSON(reader.int32());
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.beaconScan = BeaconScan.decode(reader, reader.uint32());
          continue;
        }
        case 16: {
          if (tag !== 130) {
            break;
          }

          message.activeVehicleDetected = ActiveVehicleDetected.decode(reader, reader.uint32());
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.activeTicketState = ActiveTicketState.decode(reader, reader.uint32());
          continue;
        }
        case 20: {
          if (tag !== 162) {
            break;
          }

          message.locationVisit = AppleLocationVisit.decode(reader, reader.uint32());
          continue;
        }
        case 21: {
          if (tag !== 170) {
            break;
          }

          message.beaconMonitorState = BeaconMonitorState.decode(reader, reader.uint32());
          continue;
        }
        case 23: {
          if (tag !== 184) {
            break;
          }

          message.qrCodeVisibility = qrCodeVisibilityFromJSON(reader.int32());
          continue;
        }
        case 24: {
          if (tag !== 194) {
            break;
          }

          message.androidLocationProviderState = AndroidLocationProviderState.decode(reader, reader.uint32());
          continue;
        }
        case 29: {
          if (tag !== 234) {
            break;
          }

          message.androidStartInfo = AndroidApplicationStartInfo.decode(reader, reader.uint32());
          continue;
        }
        case 27: {
          if (tag !== 218) {
            break;
          }

          message.debugMessage = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TicketTelemetryEvent {
    return {
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
      ticketId: isSet(object.ticketId) ? globalThis.String(object.ticketId) : "",
      type: isSet(object.type) ? telemetryEventTypeFromJSON(object.type) : TelemetryEventType.POSITION_RECORDED,
      recordedTime: isSet(object.recordedTime) ? Timestamp.fromJSON(object.recordedTime) : undefined,
      registeredTimeSystemMillis: isSet(object.registeredTimeSystemMillis)
        ? globalThis.Number(object.registeredTimeSystemMillis)
        : 0,
      provider: isSet(object.provider)
        ? ticketTelemetryEventProviderFromJSON(object.provider)
        : TicketTelemetryEventProvider.UNSPECIFIED,
      position: isSet(object.position) ? Position.fromJSON(object.position) : undefined,
      endedReason: isSet(object.endedReason)
        ? ticketEndedReasonFromJSON(object.endedReason)
        : TicketEndedReason.NOT_ENDED,
      batteryLevelPercentage: isSet(object.batteryLevelPercentage)
        ? globalThis.Number(object.batteryLevelPercentage)
        : 0,
      activity: isSet(object.activity) ? activityFromJSON(object.activity) : Activity.IN_VEHICLE,
      beaconScan: isSet(object.beaconScan) ? BeaconScan.fromJSON(object.beaconScan) : undefined,
      activeVehicleDetected: isSet(object.activeVehicleDetected)
        ? ActiveVehicleDetected.fromJSON(object.activeVehicleDetected)
        : undefined,
      activeTicketState: isSet(object.activeTicketState)
        ? ActiveTicketState.fromJSON(object.activeTicketState)
        : undefined,
      locationVisit: isSet(object.locationVisit) ? AppleLocationVisit.fromJSON(object.locationVisit) : undefined,
      beaconMonitorState: isSet(object.beaconMonitorState)
        ? BeaconMonitorState.fromJSON(object.beaconMonitorState)
        : undefined,
      qrCodeVisibility: isSet(object.qrCodeVisibility)
        ? qrCodeVisibilityFromJSON(object.qrCodeVisibility)
        : QrCodeVisibility.QR_INVISIBLE,
      androidLocationProviderState: isSet(object.androidLocationProviderState)
        ? AndroidLocationProviderState.fromJSON(object.androidLocationProviderState)
        : undefined,
      androidStartInfo: isSet(object.androidStartInfo)
        ? AndroidApplicationStartInfo.fromJSON(object.androidStartInfo)
        : undefined,
      debugMessage: isSet(object.debugMessage) ? globalThis.String(object.debugMessage) : "",
    };
  },

  toJSON(message: TicketTelemetryEvent): unknown {
    const obj: any = {};
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.ticketId !== "") {
      obj.ticketId = message.ticketId;
    }
    if (message.type !== TelemetryEventType.POSITION_RECORDED) {
      obj.type = telemetryEventTypeToJSON(message.type);
    }
    if (message.recordedTime !== undefined) {
      obj.recordedTime = Timestamp.toJSON(message.recordedTime);
    }
    if (message.registeredTimeSystemMillis !== 0) {
      obj.registeredTimeSystemMillis = Math.round(message.registeredTimeSystemMillis);
    }
    if (message.provider !== TicketTelemetryEventProvider.UNSPECIFIED) {
      obj.provider = ticketTelemetryEventProviderToJSON(message.provider);
    }
    if (message.position !== undefined) {
      obj.position = Position.toJSON(message.position);
    }
    if (message.endedReason !== TicketEndedReason.NOT_ENDED) {
      obj.endedReason = ticketEndedReasonToJSON(message.endedReason);
    }
    if (message.batteryLevelPercentage !== 0) {
      obj.batteryLevelPercentage = Math.round(message.batteryLevelPercentage);
    }
    if (message.activity !== Activity.IN_VEHICLE) {
      obj.activity = activityToJSON(message.activity);
    }
    if (message.beaconScan !== undefined) {
      obj.beaconScan = BeaconScan.toJSON(message.beaconScan);
    }
    if (message.activeVehicleDetected !== undefined) {
      obj.activeVehicleDetected = ActiveVehicleDetected.toJSON(message.activeVehicleDetected);
    }
    if (message.activeTicketState !== undefined) {
      obj.activeTicketState = ActiveTicketState.toJSON(message.activeTicketState);
    }
    if (message.locationVisit !== undefined) {
      obj.locationVisit = AppleLocationVisit.toJSON(message.locationVisit);
    }
    if (message.beaconMonitorState !== undefined) {
      obj.beaconMonitorState = BeaconMonitorState.toJSON(message.beaconMonitorState);
    }
    if (message.qrCodeVisibility !== QrCodeVisibility.QR_INVISIBLE) {
      obj.qrCodeVisibility = qrCodeVisibilityToJSON(message.qrCodeVisibility);
    }
    if (message.androidLocationProviderState !== undefined) {
      obj.androidLocationProviderState = AndroidLocationProviderState.toJSON(message.androidLocationProviderState);
    }
    if (message.androidStartInfo !== undefined) {
      obj.androidStartInfo = AndroidApplicationStartInfo.toJSON(message.androidStartInfo);
    }
    if (message.debugMessage !== "") {
      obj.debugMessage = message.debugMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TicketTelemetryEvent>, I>>(base?: I): TicketTelemetryEvent {
    return TicketTelemetryEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TicketTelemetryEvent>, I>>(object: I): TicketTelemetryEvent {
    const message = createBaseTicketTelemetryEvent();
    message.uuid = object.uuid ?? "";
    message.ticketId = object.ticketId ?? "";
    message.type = object.type ?? TelemetryEventType.POSITION_RECORDED;
    message.recordedTime = (object.recordedTime !== undefined && object.recordedTime !== null)
      ? Timestamp.fromPartial(object.recordedTime)
      : undefined;
    message.registeredTimeSystemMillis = object.registeredTimeSystemMillis ?? 0;
    message.provider = object.provider ?? TicketTelemetryEventProvider.UNSPECIFIED;
    message.position = (object.position !== undefined && object.position !== null)
      ? Position.fromPartial(object.position)
      : undefined;
    message.endedReason = object.endedReason ?? TicketEndedReason.NOT_ENDED;
    message.batteryLevelPercentage = object.batteryLevelPercentage ?? 0;
    message.activity = object.activity ?? Activity.IN_VEHICLE;
    message.beaconScan = (object.beaconScan !== undefined && object.beaconScan !== null)
      ? BeaconScan.fromPartial(object.beaconScan)
      : undefined;
    message.activeVehicleDetected =
      (object.activeVehicleDetected !== undefined && object.activeVehicleDetected !== null)
        ? ActiveVehicleDetected.fromPartial(object.activeVehicleDetected)
        : undefined;
    message.activeTicketState = (object.activeTicketState !== undefined && object.activeTicketState !== null)
      ? ActiveTicketState.fromPartial(object.activeTicketState)
      : undefined;
    message.locationVisit = (object.locationVisit !== undefined && object.locationVisit !== null)
      ? AppleLocationVisit.fromPartial(object.locationVisit)
      : undefined;
    message.beaconMonitorState = (object.beaconMonitorState !== undefined && object.beaconMonitorState !== null)
      ? BeaconMonitorState.fromPartial(object.beaconMonitorState)
      : undefined;
    message.qrCodeVisibility = object.qrCodeVisibility ?? QrCodeVisibility.QR_INVISIBLE;
    message.androidLocationProviderState =
      (object.androidLocationProviderState !== undefined && object.androidLocationProviderState !== null)
        ? AndroidLocationProviderState.fromPartial(object.androidLocationProviderState)
        : undefined;
    message.androidStartInfo = (object.androidStartInfo !== undefined && object.androidStartInfo !== null)
      ? AndroidApplicationStartInfo.fromPartial(object.androidStartInfo)
      : undefined;
    message.debugMessage = object.debugMessage ?? "";
    return message;
  },
};

function createBaseBeaconMonitorState(): BeaconMonitorState {
  return { major: 0, minor: 0, state: BeaconMonitorState_CLRegionState.CLRS_UNKNOWN, uuid: new Uint8Array(0) };
}

export const BeaconMonitorState: MessageFns<BeaconMonitorState> = {
  encode(message: BeaconMonitorState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.major !== 0) {
      writer.uint32(8).uint32(message.major);
    }
    if (message.minor !== 0) {
      writer.uint32(16).uint32(message.minor);
    }
    if (message.state !== BeaconMonitorState_CLRegionState.CLRS_UNKNOWN) {
      writer.uint32(32).int32(beaconMonitorState_CLRegionStateToNumber(message.state));
    }
    if (message.uuid.length !== 0) {
      writer.uint32(42).bytes(message.uuid);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BeaconMonitorState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBeaconMonitorState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.major = reader.uint32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.minor = reader.uint32();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.state = beaconMonitorState_CLRegionStateFromJSON(reader.int32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.uuid = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BeaconMonitorState {
    return {
      major: isSet(object.major) ? globalThis.Number(object.major) : 0,
      minor: isSet(object.minor) ? globalThis.Number(object.minor) : 0,
      state: isSet(object.state)
        ? beaconMonitorState_CLRegionStateFromJSON(object.state)
        : BeaconMonitorState_CLRegionState.CLRS_UNKNOWN,
      uuid: isSet(object.uuid) ? bytesFromBase64(object.uuid) : new Uint8Array(0),
    };
  },

  toJSON(message: BeaconMonitorState): unknown {
    const obj: any = {};
    if (message.major !== 0) {
      obj.major = Math.round(message.major);
    }
    if (message.minor !== 0) {
      obj.minor = Math.round(message.minor);
    }
    if (message.state !== BeaconMonitorState_CLRegionState.CLRS_UNKNOWN) {
      obj.state = beaconMonitorState_CLRegionStateToJSON(message.state);
    }
    if (message.uuid.length !== 0) {
      obj.uuid = base64FromBytes(message.uuid);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BeaconMonitorState>, I>>(base?: I): BeaconMonitorState {
    return BeaconMonitorState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BeaconMonitorState>, I>>(object: I): BeaconMonitorState {
    const message = createBaseBeaconMonitorState();
    message.major = object.major ?? 0;
    message.minor = object.minor ?? 0;
    message.state = object.state ?? BeaconMonitorState_CLRegionState.CLRS_UNKNOWN;
    message.uuid = object.uuid ?? new Uint8Array(0);
    return message;
  },
};

function createBaseBeaconScan(): BeaconScan {
  return { beacons: [] };
}

export const BeaconScan: MessageFns<BeaconScan> = {
  encode(message: BeaconScan, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.beacons) {
      BluetoothBeacon.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BeaconScan {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBeaconScan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.beacons.push(BluetoothBeacon.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BeaconScan {
    return {
      beacons: globalThis.Array.isArray(object?.beacons)
        ? object.beacons.map((e: any) => BluetoothBeacon.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BeaconScan): unknown {
    const obj: any = {};
    if (message.beacons?.length) {
      obj.beacons = message.beacons.map((e) => BluetoothBeacon.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BeaconScan>, I>>(base?: I): BeaconScan {
    return BeaconScan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BeaconScan>, I>>(object: I): BeaconScan {
    const message = createBaseBeaconScan();
    message.beacons = object.beacons?.map((e) => BluetoothBeacon.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBluetoothBeacon(): BluetoothBeacon {
  return { id2: new Uint8Array(0), id3: new Uint8Array(0) };
}

export const BluetoothBeacon: MessageFns<BluetoothBeacon> = {
  encode(message: BluetoothBeacon, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id2.length !== 0) {
      writer.uint32(18).bytes(message.id2);
    }
    if (message.id3.length !== 0) {
      writer.uint32(26).bytes(message.id3);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BluetoothBeacon {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBluetoothBeacon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.id2 = reader.bytes();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.id3 = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BluetoothBeacon {
    return {
      id2: isSet(object.id2) ? bytesFromBase64(object.id2) : new Uint8Array(0),
      id3: isSet(object.id3) ? bytesFromBase64(object.id3) : new Uint8Array(0),
    };
  },

  toJSON(message: BluetoothBeacon): unknown {
    const obj: any = {};
    if (message.id2.length !== 0) {
      obj.id2 = base64FromBytes(message.id2);
    }
    if (message.id3.length !== 0) {
      obj.id3 = base64FromBytes(message.id3);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BluetoothBeacon>, I>>(base?: I): BluetoothBeacon {
    return BluetoothBeacon.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BluetoothBeacon>, I>>(object: I): BluetoothBeacon {
    const message = createBaseBluetoothBeacon();
    message.id2 = object.id2 ?? new Uint8Array(0);
    message.id3 = object.id3 ?? new Uint8Array(0);
    return message;
  },
};

function createBasePosition(): Position {
  return {
    latitude: 0,
    longitude: 0,
    accuracy: 0,
    speed: 0,
    speedAccuracy: 0,
    altitude: 0,
    heading: 0,
    isMock: false,
    provider: Position_AndroidLocationProvider.UNKNOWN_PROVIDER,
    providerFallback: "",
  };
}

export const Position: MessageFns<Position> = {
  encode(message: Position, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.latitude !== 0) {
      writer.uint32(9).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(17).double(message.longitude);
    }
    if (message.accuracy !== 0) {
      writer.uint32(25).double(message.accuracy);
    }
    if (message.speed !== 0) {
      writer.uint32(33).double(message.speed);
    }
    if (message.speedAccuracy !== 0) {
      writer.uint32(41).double(message.speedAccuracy);
    }
    if (message.altitude !== 0) {
      writer.uint32(49).double(message.altitude);
    }
    if (message.heading !== 0) {
      writer.uint32(56).uint32(message.heading);
    }
    if (message.isMock !== false) {
      writer.uint32(64).bool(message.isMock);
    }
    if (message.provider !== Position_AndroidLocationProvider.UNKNOWN_PROVIDER) {
      writer.uint32(72).int32(position_AndroidLocationProviderToNumber(message.provider));
    }
    if (message.providerFallback !== "") {
      writer.uint32(82).string(message.providerFallback);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Position {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 9) {
            break;
          }

          message.latitude = reader.double();
          continue;
        }
        case 2: {
          if (tag !== 17) {
            break;
          }

          message.longitude = reader.double();
          continue;
        }
        case 3: {
          if (tag !== 25) {
            break;
          }

          message.accuracy = reader.double();
          continue;
        }
        case 4: {
          if (tag !== 33) {
            break;
          }

          message.speed = reader.double();
          continue;
        }
        case 5: {
          if (tag !== 41) {
            break;
          }

          message.speedAccuracy = reader.double();
          continue;
        }
        case 6: {
          if (tag !== 49) {
            break;
          }

          message.altitude = reader.double();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.heading = reader.uint32();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.isMock = reader.bool();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.provider = position_AndroidLocationProviderFromJSON(reader.int32());
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.providerFallback = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Position {
    return {
      latitude: isSet(object.latitude) ? globalThis.Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? globalThis.Number(object.longitude) : 0,
      accuracy: isSet(object.accuracy) ? globalThis.Number(object.accuracy) : 0,
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      speedAccuracy: isSet(object.speedAccuracy) ? globalThis.Number(object.speedAccuracy) : 0,
      altitude: isSet(object.altitude) ? globalThis.Number(object.altitude) : 0,
      heading: isSet(object.heading) ? globalThis.Number(object.heading) : 0,
      isMock: isSet(object.isMock) ? globalThis.Boolean(object.isMock) : false,
      provider: isSet(object.provider)
        ? position_AndroidLocationProviderFromJSON(object.provider)
        : Position_AndroidLocationProvider.UNKNOWN_PROVIDER,
      providerFallback: isSet(object.providerFallback) ? globalThis.String(object.providerFallback) : "",
    };
  },

  toJSON(message: Position): unknown {
    const obj: any = {};
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.accuracy !== 0) {
      obj.accuracy = message.accuracy;
    }
    if (message.speed !== 0) {
      obj.speed = message.speed;
    }
    if (message.speedAccuracy !== 0) {
      obj.speedAccuracy = message.speedAccuracy;
    }
    if (message.altitude !== 0) {
      obj.altitude = message.altitude;
    }
    if (message.heading !== 0) {
      obj.heading = Math.round(message.heading);
    }
    if (message.isMock !== false) {
      obj.isMock = message.isMock;
    }
    if (message.provider !== Position_AndroidLocationProvider.UNKNOWN_PROVIDER) {
      obj.provider = position_AndroidLocationProviderToJSON(message.provider);
    }
    if (message.providerFallback !== "") {
      obj.providerFallback = message.providerFallback;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Position>, I>>(base?: I): Position {
    return Position.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Position>, I>>(object: I): Position {
    const message = createBasePosition();
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.accuracy = object.accuracy ?? 0;
    message.speed = object.speed ?? 0;
    message.speedAccuracy = object.speedAccuracy ?? 0;
    message.altitude = object.altitude ?? 0;
    message.heading = object.heading ?? 0;
    message.isMock = object.isMock ?? false;
    message.provider = object.provider ?? Position_AndroidLocationProvider.UNKNOWN_PROVIDER;
    message.providerFallback = object.providerFallback ?? "";
    return message;
  },
};

function createBaseAndroidApplicationStartInfo(): AndroidApplicationStartInfo {
  return { activityManagerDump: undefined, powerManagerDump: undefined };
}

export const AndroidApplicationStartInfo: MessageFns<AndroidApplicationStartInfo> = {
  encode(message: AndroidApplicationStartInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.activityManagerDump !== undefined) {
      ActivityManagerDump.encode(message.activityManagerDump, writer.uint32(10).fork()).join();
    }
    if (message.powerManagerDump !== undefined) {
      PowerManagerDump.encode(message.powerManagerDump, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AndroidApplicationStartInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAndroidApplicationStartInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.activityManagerDump = ActivityManagerDump.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.powerManagerDump = PowerManagerDump.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AndroidApplicationStartInfo {
    return {
      activityManagerDump: isSet(object.activityManagerDump)
        ? ActivityManagerDump.fromJSON(object.activityManagerDump)
        : undefined,
      powerManagerDump: isSet(object.powerManagerDump) ? PowerManagerDump.fromJSON(object.powerManagerDump) : undefined,
    };
  },

  toJSON(message: AndroidApplicationStartInfo): unknown {
    const obj: any = {};
    if (message.activityManagerDump !== undefined) {
      obj.activityManagerDump = ActivityManagerDump.toJSON(message.activityManagerDump);
    }
    if (message.powerManagerDump !== undefined) {
      obj.powerManagerDump = PowerManagerDump.toJSON(message.powerManagerDump);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AndroidApplicationStartInfo>, I>>(base?: I): AndroidApplicationStartInfo {
    return AndroidApplicationStartInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AndroidApplicationStartInfo>, I>>(object: I): AndroidApplicationStartInfo {
    const message = createBaseAndroidApplicationStartInfo();
    message.activityManagerDump = (object.activityManagerDump !== undefined && object.activityManagerDump !== null)
      ? ActivityManagerDump.fromPartial(object.activityManagerDump)
      : undefined;
    message.powerManagerDump = (object.powerManagerDump !== undefined && object.powerManagerDump !== null)
      ? PowerManagerDump.fromPartial(object.powerManagerDump)
      : undefined;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
