import React, {useState} from "react";
import {DateTimeFormat} from "../../util/date_time";
import {ACTIVE_TICKET_STATE_DETAILS_FRAGMENT, formatVehiclePosition} from "../../util/active_ticket_state";
import {ActiveTicketStateDetailsFragment, ActiveTicketStateLogTabFragment} from "../../generated/gql/graphql";
import {formatPercentage} from "../../util/formatting";
import {Divider, Link as MaterialLink, Typography} from "@mui/material";
import {TimestampText} from "../../widgets/date_time";
import {gql} from "@apollo/client";
import {useTicketPageV2Context} from "../ticket_v2/context";

export const ACTIVE_TICKET_STATE_LOG_TAB_FRAGMENT = gql`
    fragment ActiveTicketStateLogTab on Ticket {
        activeTicketStateLog {
            ...ActiveTicketStateDetails
        }
        activeVehicleDetectedLog {
            recordedTime
            vehiclePosition {
                vehicleId
                linePublicCode
                destination
                transportMode
                tripId
            }
            endConfidence
            confidence
            recognizedTransportMode
            rawProtobufJson
        }
    }
    ${ACTIVE_TICKET_STATE_DETAILS_FRAGMENT}
`;

export function TicketActiveTicketStateLogView() {
    const context = useTicketPageV2Context();
    const ticket = context.ticket as unknown as ActiveTicketStateLogTabFragment;
    const list = ticket.activeTicketStateLog;

    return <div style={{display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: "20px"}}>
        <div style={{flex: "1"}}>
            <Typography variant={"h6"}>
                Active ticket state
            </Typography>

            <table>
                <thead>
                <tr>
                    <th>Timestamp</th>
                    <th>Description</th>
                    <th>Error</th>
                    <th>Raw</th>
                </tr>
                </thead>
                <tbody>
                {list.map((s, index) => {
                    const state = s as ActiveTicketStateDetailsFragment;
                    return <tr key={index}>
                        <td><TimestampText format={DateTimeFormat.TIME} value={state.timestamp} /></td>
                        <td>{state.mode}</td>
                        <td>{state.errorMessage + "\n" + state.stackTrace}</td>
                        <td><RawProtobufExpandable json={state.rawProtobufJson} /></td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
        <Divider flexItem orientation={"vertical"} variant={"fullWidth"} />
        <div style={{flex: "1"}}>
            <Typography variant={"h6"}>
                Active vehicle detected
            </Typography>

            <table>
                <thead>
                <tr>
                    <th>Timestamp</th>
                    <th>Transport mode</th>
                    <th>Vehicle</th>
                    <th>Confidence</th>
                    <th>End confidence</th>
                    <th>Json</th>
                </tr>
                </thead>
                <tbody>
                {ticket.activeVehicleDetectedLog.map((s) => {
                    return <tr>
                        <td><TimestampText format={DateTimeFormat.TIME} value={s.recordedTime} /></td>
                        <td>{s.recognizedTransportMode}</td>
                        <td>{formatVehiclePosition(s.vehiclePosition)}</td>
                        <td align={"right"}>{formatPercentage(s.confidence) + ")"}</td>
                        <td align={"right"}>{formatPercentage(s.endConfidence)}</td>
                        <td><RawProtobufExpandable json={s.rawProtobufJson} /></td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
    </div>
        ;
}

function RawProtobufExpandable(props: {json: string}): React.ReactElement {
    const [expanded, setExpanded] = useState(false);

    if (props.json) {
        return <MaterialLink onClick={() => setExpanded(!expanded)}>
            {expanded ? <pre>{props.json}</pre> : "Expand"}
        </MaterialLink>;
    } else {
        return <>null</>;
    }
}