import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
    QuestionnaireFragment,
    TicketPageHeaderFragment,
    TicketPageV2Query,
    TicketPageV2QueryVariables,
    TicketTelemetryAvailability,
} from "../generated/gql/graphql";
import { Badge, Box, Popover, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TICKET_PAGE_HEADER_FRAGMENT, TicketPageHeader } from "./ticket_v2/TicketPageHeader";
import { Link, useParams } from "react-router-dom";
import { DEVICE_INFO_FRAGMENT, TicketAppTab } from "./ticket_v2/TicketAppTab";
import { TicketInfoTab } from "./ticket_v2/TicketInfoTab";
import { isSuperUser } from "../util/active_user_context";
import { usePageTitle } from "../util/page_title";
import TicketComplaintsTab from "./ticket_v2/TicketComplaintsTab";
import { formatTicketId } from "../util/formatting";
import TicketQuestionnaireTab, { QUESTIONNAIRE_FRAGMENT } from "./ticket_v2/TicketQuestionnaireTab";
import { TicketCrashlyticsTab } from "./ticket_v2/TicketCrashlyticsTab";
import { TicketPageV2Context, TicketPageV2ContextType, useTicketPageV2Context } from "./ticket_v2/context";
import {
    ACTIVE_TICKET_STATE_LOG_TAB_FRAGMENT,
    TicketActiveTicketStateLogView,
} from "./ticket/TicketActiveTicketStateLogView";
import {
    TICKET_STATEMENT_HISTORY_TAB_FRAGMENT,
    TicketStatementHistoryTable,
} from "./ticket/TicketStatementHistoryTable";
import { TicketBeaconSessionsTable } from "./ticket/TicketBeaconSessionsTable";
import { blue } from "@mui/material/colors";
import { TicketEventsListView } from "./ticket/TicketEventsListView";
import { TicketDataView } from "./ticket/TicketDataView";
import {
    BluetoothConnectedIcon,
    BugReportIcon,
    ErrorIcon,
    MapIcon,
    PollIcon,
    ReceiptLongIcon,
    TableChartIcon,
    TimelineIcon,
} from "../widgets/icons";
import LoadingScreen from "../widgets/LoadingScreen";

export const LOCAL_PRICE_FRAGMENT = gql`
    fragment LocalPrice on TicketAnalysisLocalPrice {
        id
        createdTime
        source

        discountedPriceExcludingAddonsIncludingVat
        undiscountedPriceIncludingVat
        discountedPriceIncludingVat

        debugOrderSummaryJsonString
        debugTicketAnalysisResultJsonString
        analysisConfidence

        active
        legs {
            id
            analysisConfidence
            discountedPriceIncludingVat
            discountedPriceExcludingAddonsIncludingVat
            undiscountedPriceIncludingVat
            zoneSpecString
            startedTime

            priceGuaranteeProduct
            priceGuaranteeProductName
            billingTransportMode
            billingTransportModeName
            isTransfer
            distance

            description {
                endedTime
                vehicleId

                journey {
                    id
                    tripId
                    serviceJourneyId
                    operatingDate

                    destination
                    transportMode
                    vehicleTransportMode

                    polyline {
                        coordinates {
                            latitude
                            longitude
                        }
                        stopTimeIndices
                    }

                    stopTimes {
                        ...StopTimeLite
                    }

                    line {
                        id
                        publicCode
                    }
                }
                toStopTime {
                    ...StopTimeLite
                }
                fromStopTime {
                    ...StopTimeLite
                }
            }
        }
    }

    fragment StopTimeLite on StopTime {
        stopTimeIndex
        platform {
            name
        }
        latitude
        longitude
        scheduledArrivalTime
        scheduledDepartureTime
        alighting
        boarding
    }
`;
const QUERY = gql`
    query TicketPageV2($id: Int!) {
        ticket(id: $id) {
            id

            createdTime
            verified
            price
            basePrice

            ...TicketPageHeader

            startBatteryLevel
            endedBatteryLevel

            telemetryAvailability
            purchaseRequestPhoneTime
            phoneClockErrorMillis
            processedTime
            invoicedTime
            endedTime
            endedReason

            customer {
                id
                createdTime

                phoneNumber
                phoneNumberFormatted

                researchGroup
                hasHJH
            }

            deviceInfo {
                ...DeviceInfo
            }

            analysisLocalPrice {
                ...TPLocalPrice
            }

            complaints {
                id
                customerEmail
                description
                supportTicketUrl
                status
                statusChangedTime
                statusChangedByEmail
                originalPrice
                correctPrice
                internalComment
                customerMessage
                createdTime
                createdByEmail
                localPrice {
                    ...LocalPrice
                }
            }

            invoices {
                invoiceNumber
                descriptionNorwegian
                totalAmount
                createdTime

                delegatedTransactions {
                    id
                    createdTime

                    amount
                    amountCaptured

                    invoices {
                        invoiceNumber
                        ticket {
                            id
                        }
                        totalAmount
                    }

                    invoicesDelegated {
                        invoiceNumber
                        ticket {
                            id
                        }
                        totalAmount
                    }

                    savedCard {
                        id
                        maskedPan
                        issuer
                    }

                    netsCaptureParsedResponseCode
                    netsCaptureResponseCode
                    netsCaptureResponseText
                    netsCaptureResponseSource
                    processedSuccessfullyTime
                }

                transaction {
                    id
                }

                transactionCredit {
                    id
                    amount
                    createdTime
                    processedTime
                    processedSuccessfullyTime
                    netsProcessParsedResponseCode
                    netsProcessResponseCode
                    netsProcessResponseText
                    netsProcessResponseSource
                    transaction {
                        id
                        savedCard {
                            id
                            maskedPan
                            issuer
                        }
                    }
                }
            }
            passengerTypeInfo {
                passengerTypeAddons
                addonBicycle
            }

            questionnaire {
                ...Questionnaire
            }

            activityLog {
                recordedTime
                activity
            }

            positionLog {
                uuid
                latitude
                longitude
                accuracy
                altitude
                speed
                speedAccuracy
                heading
                recordedTime
                registeredTimeSystem
                androidLocationProvider
            }
            beaconScans {
                uuid
                recordedTime
                registeredTimeSystem
                beacons {
                    major
                    minor
                    transportMode
                    vehicleId
                }
            }
            beaconSessions {
                beacon {
                    major
                    minor
                    transportMode
                    vehicleId
                }
                startTime
                endTime
            }

            ticketEvents {
                uuid
                type
                recordedTime
                registeredTime
                providerName
                jsonSerialized
            }

            ...ActiveTicketStateLogTab
            ...TicketStatementHistoryTab
        }
    }

    fragment TPLocalPrice on TicketAnalysisLocalPrice {
        legs {
            vehiclePositionLog {
                vehicleId
                latitude
                longitude
                recordedTime
            }
        }
        ...LocalPrice
    }

    ${TICKET_PAGE_HEADER_FRAGMENT}
    ${DEVICE_INFO_FRAGMENT}
    ${QUESTIONNAIRE_FRAGMENT}
    ${LOCAL_PRICE_FRAGMENT}
    ${ACTIVE_TICKET_STATE_LOG_TAB_FRAGMENT}
    ${TICKET_STATEMENT_HISTORY_TAB_FRAGMENT}
`;

export function TicketPageV2() {
    const { ticketId } = useParams<{ ticketId: string }>();
    const query = useQuery<TicketPageV2Query, TicketPageV2QueryVariables>(QUERY, {
        variables: { id: parseInt(ticketId!) },
    });
    const basePath = "/ticket/" + ticketId;
    usePageTitle("Billett " + formatTicketId(ticketId));

    if (query.loading) return <LoadingScreen />;

    const page = window.location.hash.replace("#/ticket/" + ticketId, "").replace("/", "");
    const ticket = query.data!.ticket as TicketPageV2ContextType["ticket"];
    const questionnaire = ticket.questionnaire as QuestionnaireFragment | undefined;

    function handleRefreshData(): Promise<unknown> {
        return query.refetch();
    }

    const isMapPage = page.indexOf("map") !== -1;
    return (
        <TicketPageV2Context.Provider value={{ query, ticket, handleRefreshData }}>
            <div style={{ width: "100%", height: "100%", overflowY: isMapPage ? "hidden" : "scroll" }}>
                <div
                    style={
                        isMapPage
                            ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%",
                                  width: "100%",
                                  padding: "0 20px",
                              }
                            : {
                                  width: "100%",
                                  padding: "20px",
                                  maxWidth: "1900px",
                                  margin: "0 auto",
                              }
                    }
                >
                    <TicketPageHeader
                        ticket={ticket as unknown as TicketPageHeaderFragment}
                        hideProperties={isMapPage}
                    />

                    <Tabs value={page}>
                        <Tab value="" label={"Info"} component={Link} to={basePath} />
                        <Tab value="app" label={"App"} component={Link} to={basePath + "/app"} />
                        <Tab
                            value="complaints"
                            label={"Klager"}
                            component={Link}
                            to={basePath + "/complaints"}
                            icon={ticket.complaints.length > 0 ? <ErrorIcon color={"error"} /> : undefined}
                            iconPosition={"end"}
                        />
                        {ticket.questionnaire ? (
                            <Tab
                                value="questionnaire"
                                iconPosition={"start"}
                                icon={
                                    questionnaire?.answered ? (
                                        <Badge color="secondary" variant="dot">
                                            <PollIcon color={"success"} />
                                        </Badge>
                                    ) : (
                                        <PollIcon />
                                    )
                                }
                                label={"Spørreskjema"}
                                component={Link}
                                to={basePath + "/questionnaire"}
                            />
                        ) : null}
                        <Tab
                            value="map"
                            iconPosition={"start"}
                            icon={<MapIcon />}
                            label={"Kart"}
                            component={Link}
                            to={basePath + "/map"}
                            disabled={ticket.telemetryAvailability != TicketTelemetryAvailability.Available}
                        />
                        <BeaconSessionsTab />
                        {isSuperUser()
                            ? [
                                  <Tab
                                      key={"active-ticket-state-log"}
                                      value={"active-ticket-state-log"}
                                      iconPosition={"start"}
                                      icon={
                                          <Badge
                                              badgeContent={
                                                  ticket.activeTicketStateLog.length +
                                                  ticket.activeVehicleDetectedLog.length
                                              }
                                          >
                                              <TimelineIcon />
                                          </Badge>
                                      }
                                      label={"State log"}
                                      component={Link}
                                      to={basePath + "/active-ticket-state-log"}
                                      style={{ marginLeft: "100px" }}
                                      disabled={
                                          ticket.activeTicketStateLog.length === 0 &&
                                          ticket.activeVehicleDetectedLog.length === 0
                                      }
                                  />,
                                  ticket.statementHistory.length ? (
                                      <Tab
                                          key={"ticket-statement-history"}
                                          value={"ticket-statement-history"}
                                          iconPosition={"start"}
                                          icon={
                                              <Badge badgeContent={ticket.statementHistory.length}>
                                                  <ReceiptLongIcon />
                                              </Badge>
                                          }
                                          label={"Ticket Statements"}
                                          component={Link}
                                          to={basePath + "/ticket-statement-history"}
                                      />
                                  ) : null,
                                  ticket.ticketEvents?.length ? (
                                      <Tab
                                          key={"events"}
                                          value={"events"}
                                          iconPosition={"start"}
                                          icon={<TableChartIcon />}
                                          label={
                                              <Badge badgeContent={ticket.ticketEvents!.length}>
                                                  <span style={{ marginRight: "10px" }}>Events</span>
                                              </Badge>
                                          }
                                          component={Link}
                                          to={basePath + "/events"}
                                      />
                                  ) : null,
                                  <Tab
                                      key={"crashlytics"}
                                      value={"crashlytics"}
                                      iconPosition={"start"}
                                      icon={<BugReportIcon />}
                                      label={"Crashlytics"}
                                      component={Link}
                                      to={basePath + "/crashlytics"}
                                  />,
                              ]
                            : null}
                    </Tabs>

                    <div
                        style={{
                            border: "1px solid #DDD",
                            ...(isMapPage
                                ? {
                                      flex: "1",
                                      overflowY: "auto",
                                      position: "relative",
                                  }
                                : {}),
                        }}
                    >
                        <CustomTabPanel page={""} selectedPage={page || ""}>
                            <TicketInfoTab ticket={ticket} />
                        </CustomTabPanel>

                        <CustomTabPanel page={"app"} selectedPage={page || ""}>
                            <TicketAppTab ticket={ticket} />
                        </CustomTabPanel>

                        <CustomTabPanel page={"complaints"} selectedPage={page || ""}>
                            <TicketComplaintsTab ticket={ticket} onRefreshData={handleRefreshData} />
                        </CustomTabPanel>

                        <CustomTabPanel page={"questionnaire"} selectedPage={page || ""}>
                            <TicketQuestionnaireTab ticket={ticket} />
                        </CustomTabPanel>

                        <CustomTabPanel page={"crashlytics"} selectedPage={page || ""}>
                            <TicketCrashlyticsTab ticket={ticket} />
                        </CustomTabPanel>

                        <CustomTabPanel page={"active-ticket-state-log"} selectedPage={page || ""}>
                            <TicketActiveTicketStateLogView />
                        </CustomTabPanel>

                        <CustomTabPanel page={"ticket-statement-history"} selectedPage={page || ""}>
                            <TicketStatementHistoryTable />
                        </CustomTabPanel>

                        <CustomTabPanel page={"beacon-sessions"} selectedPage={page || ""}>
                            <TicketBeaconSessionsTable />
                        </CustomTabPanel>

                        <CustomTabPanel page={"events"} selectedPage={page || ""}>
                            <TicketEventsListView />
                        </CustomTabPanel>

                        <TabVisibility visible={page === "map"}>
                            <TicketDataView />
                        </TabVisibility>
                    </div>
                </div>
            </div>
        </TicketPageV2Context.Provider>
    );
}

export interface TabPanelProps {
    children?: React.ReactNode;
    page: string;
    selectedPage: string;
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, page, selectedPage, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={page !== selectedPage}
            id={`simple-tabpanel-${page}`}
            aria-labelledby={`simple-tab-${page}`}
            {...other}
        >
            {page === selectedPage && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function TabVisibility(props: { visible: boolean; children: React.ReactNode }) {
    const [loaded, setLoaded] = React.useState(props.visible);

    useEffect(() => {
        if (props.visible) setLoaded(true);
    }, [props.visible]);

    if (!loaded) return null;
    return (
        <div
            style={{
                display: props.visible ? undefined : "none",
                visibility: props.visible ? "visible" : "hidden",
                height: "100%",
                width: "100%",
            }}
        >
            {props.children}
        </div>
    );
}

function BeaconSessionsTab() {
    const [showBeaconSessions, setShowBeaconSessions] = useState<HTMLElement | null>(null);
    const ticket = useTicketPageV2Context().ticket;
    if (!isSuperUser() || !ticket.beaconSessions.length) return null;

    return [
        <Tab
            value="beacon-sessions"
            iconPosition={"start"}
            icon={<BluetoothConnectedIcon style={{ color: showBeaconSessions ? blue[700] : undefined }} />}
            label={
                <Badge badgeContent={ticket.beaconSessions.length} color="secondary">
                    <span style={{ marginRight: "10px" }}>Beacons</span>
                </Badge>
            }
            onClick={(event) => setShowBeaconSessions(showBeaconSessions ? null : event.currentTarget)}
            // component={Link}
            // to={basePath + "/beacon-sessions"}
        />,
        <Popover
            open={!!showBeaconSessions}
            anchorEl={showBeaconSessions}
            onClose={() => setShowBeaconSessions(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            <TicketBeaconSessionsTable />
        </Popover>,
    ];
}
